<template>
    <section v-if="isAdmin || billbooks.canManage">
        <div>
            <section>
                <form class="uk-card  uk-card-body uk-card-default" @submit.prevent="search()">
                    <div class="uk-grid uk-width-1-1">
                        <div class="uk-width-1-2@m">
                            <div class="uk-margin">
                                <select @change="changePaymentStatus" class="uk-select" placeholder="Stato pagamento"
                                    v-model="Search.PaymentStatus">
                                    <option v-for="payment in PaymentStatus" :value="payment.value" :key="payment.value">{{
                                        payment.label }}</option>
                                </select>
                            </div>
                            <div class="uk-margin">
                                <input class="uk-input" placeholder="Nome / Ragione sociale" v-model="Search.CustomerName">
                            </div>
                            <div class="uk-margin">
                                <input class="uk-input" placeholder="Customer code" v-model="Search.CustomerCode">
                            </div>
                            <div class="uk-margin">
                                <input class="uk-input" placeholder="Partita iva" v-model="Search.Vat">
                            </div>
                            <div class="uk-margin">
                                <input class="uk-input" placeholder="Codice fiscale" v-model="Search.FiscalCode">
                            </div>
                            <div class="uk-margin">
                                <input class="uk-input" placeholder="Numero fattura" v-model="Search.InvoiceNumber">
                            </div>
                            <br class="uk-visible@s" />
                        </div>

                        <div class="uk-width-1-2@m">

                            <div class="uk-margin">
                                <datepicker :enable-time-picker="false" v-model="Search.ExpirationFrom"
                                    placeholder="Scadenza fattura (da)" format="dd/MM/yyyy" cancel-text="Annulla"
                                    select-text="OK" />
                            </div>
                            <div class="uk-margin">
                                <datepicker :enable-time-picker="false" v-model="Search.ExpirationTo"
                                    placeholder="Scadenza fattura (a)" format="dd/MM/yyyy" cancel-text="Annulla"
                                    select-text="OK" />
                            </div>
                            <div class="uk-margin">
                                <datepicker :enable-time-picker="false" v-model="Search.InvoiceDateFrom"
                                    placeholder="Data fattura (da)" format="dd/MM/yyyy" cancel-text="Annulla"
                                    select-text="OK" />
                            </div>
                            <div class="uk-margin">
                                <datepicker :enable-time-picker="false" v-model="Search.InvoiceDateTo"
                                    placeholder="Data fattura (a)" format="dd/MM/yyyy" cancel-text="Annulla"
                                    select-text="OK" />
                            </div>
                            <div class="uk-margin">
                                <label><input class="uk-checkbox" type="checkbox" v-model="Search.IsForced"
                                        @change="changeIsForced" /><span class="uk-margin-small-left">Stato forzato
                                        manualmente</span><br></label>
                            </div>
                            <div class="uk-margin">
                                <label><input @change="changeIsStatusNotResolved" class="uk-checkbox" type="checkbox"
                                        v-model="Search.IsStatusNotResolved" /><span class="uk-margin-small-left">Senza
                                        stato di
                                        pagamento</span><br></label>
                            </div>
                            <div class="uk-margin">
                                <label><input class="uk-checkbox" type="checkbox"
                                        v-model="Search.DiscardSingleNegativeOpenRows" /><span class="uk-margin-small-left">Scarta singole righe negative aperte</span><br></label>
                            </div>
                        </div>


                    </div>
                    <div class="uk-column-1-2@m uk-margin-top">
                        <button :disabled="Search.Submitting" class="uk-button uk-button-primary uk-margin-right"
                            >
                            <span v-if="!Search.Submitting">Cerca fatture</span><span v-if="Search.Submitting"
                                uk-spinner="ratio: 0.7"></span>
                        </button>
                        <button class="uk-button uk-button-default" @click="clearSearch()">
                            Cancella filtri
                        </button>
                        <button class="uk-align-right@m uk-button uk-button-default"
                            @click.prevent="modalPaymentStatusMulti">Modifica stati pag. (multi)</button>
                        <button class="uk-align-right@m uk-button uk-button-primary"
                            :disabled="Invoices.List.length == 0 || !Invoices.Loaded"
                            @click.prevent="billbooksExport">Esporta
                            risultati</button>
                        
                    </div>
                </form>

                <div class="uk-margin-top" v-if="Invoices.List.length == 0 && Invoices.Loaded">
                    Nessuna fattura presente.
                </div>
                <div v-if="Invoices.List.length > 0" class="uk-margin-top">

                    <div v-if="Invoices.List.length > 0" class="uk-margin-top uk-margin-bottom">
                        <span class="uk-margin-right">{{ Invoices.List[0]["Total"] }} fatture presenti.</span>
                        <span class="uk-margin-right">Pagina: {{ Invoices.CurrentPage }} di {{ totalPages }}</span>
                        <button class="uk-button uk-button-default" @click.prevent="invoicesList(1)"
                            v-if="Invoices.CurrentPage > 1"><span uk-icon="icon: chevron-double-left;"></span></button>
                        <button class="uk-button uk-button-default" @click.prevent="invoicesList(Invoices.CurrentPage - 1)"
                            v-if="Invoices.CurrentPage > 1"><span uk-icon="icon: chevron-left;"></span></button>
                        <button class="uk-button uk-button-default" @click.prevent="invoicesList(Invoices.CurrentPage + 1)"
                            v-if="Invoices.CurrentPage < totalPages"><span uk-icon="icon: chevron-right;"></span></button>
                        <button class="uk-button uk-button-default" @click.prevent="invoicesList(totalPages)"
                            v-if="Invoices.CurrentPage < totalPages"><span
                                uk-icon="icon: chevron-double-right;"></span></button>



                    </div>
                    <div v-for="(invoice, index) in Invoices.List" :key="invoice['invoiceId']"
                        class="uk-margin-top uk-padding-small uk-card  uk-card-default">
                        <div class="uk-card-body">
                            <div class="uk-grid">
                                <div class="uk-width-1-3@m">
                                    <div class="uk-text">Cliente: {{ invoice['CustomerName'] }} </div>
                                    <div class="uk-text">Cod. cliente: {{ invoice['CustomerCode'] }} </div>
                                    <div class="uk-text" v-if="invoice['FiscalCode'] != ''">Cod. fiscale: {{
                                        invoice['FiscalCode'] }} </div>
                                    <div class="uk-text" v-if="invoice['Vat'] != ''">P. Iva: {{ invoice['Vat'] }} </div>

                                </div>
                                <div class="uk-width-1-3@m">
                                    <div class="uk-text">Numero fattura: {{ invoice['InvoiceNumber'] }} ({{
                                        invoice['SupplyTypeCode'] }})</div>
                                    <div class="uk-text">Mod. pag.: {{ invoice['MasterContractPaymentTypeCode'] }}
                                    </div>
                                    <div class="uk-text">Stato pag.:
                                        <span v-if="invoice['PaymentStatus'] == ''">Non impostato</span>
                                        <span v-if="invoice['PaymentStatus'] == 'PAID'">Pagato</span>
                                        <span v-if="invoice['PaymentStatus'] == 'NOTPAID'">Non pagato</span>
                                        <span v-if="invoice['PaymentStatus'] == 'PENDING'">Pending</span>
                                        <span v-if="invoice['PaymentStatus'] == 'COMPENS'">Compensazione</span>
                                        <span v-if="invoice['PaymentStatus'] == 'PARTIAL'">Pagamento parziale</span>
                                        <a class="uk-margin-left" href="#modal-update-payment-status"
                                            @click="modalPaymentStatus(index)" uk-toggle>Modifica</a>
                                    </div>
                                    <div class="uk-text" v-if="invoice['IsForced'] == true">Stato forzato manualmente</div>
                                    <div class="uk-text" v-if="invoice['Annotations'] > 0">Note pubbliche <a
                                            href="#modal-get-public-annotations" uk-toggle
                                            @click="getPublicAnnotations(index)">({{ invoice['Annotations'] }})</a> </div>
                                </div>
                                <div class="uk-width-1-3@m">
                                    <div class="uk-text">Totale fattura: {{ invoice['TotalValue'] }} </div>
                                    <div
                                        v-if="invoice['PaymentStatus'] == 'PARTIAL' && invoice['TotalPartialPaid'] != null">
                                        Importo pagato: {{ invoice['TotalPartialPaid'] }}</div>
                                    <div class="uk-text">Data fattura: {{ invoice['InvoiceDate'] }} </div>
                                    <div class="uk-text">Scadenza fattura: {{ invoice['ExpirationDate'] }} </div>
                                </div>
                            </div>
                        </div>
                        <div class="uk-card-footer">
                            <span class="uk-text-meta" v-if="invoice['LastUpdate'] != null">Ultimo aggiornamento: {{
                                invoice['LastUpdate'] }}
                            </span>
                            <div class="uk-align-right@m">
                                <a class="uk-margin-right" href="#modal-add-public-annotation" uk-toggle
                                    @click="modalAddPublicAnnotation(index)">Aggiungi nota
                                    pubblica</a>
                                <br class="uk-hidden@m" />
                                <a href="javascript:;" @click="modalSetInternalNotes(index)">Note
                                    interne</a>
                            </div>
                        </div>

                    </div>
                </div>
                <div v-if="Invoices.List.length > 0" class="uk-margin-top uk-margin-bottom">
                    <span class="uk-margin-right">Pagina: {{ Invoices.CurrentPage }} di {{ totalPages }}</span>
                    <button class="uk-button uk-button-default" @click.prevent="invoicesList(1)"
                        v-if="Invoices.CurrentPage > 1"><span uk-icon="icon: chevron-double-left;"></span></button>
                    <button class="uk-button uk-button-default" @click.prevent="invoicesList(Invoices.CurrentPage - 1)"
                        v-if="Invoices.CurrentPage > 1"><span uk-icon="icon: chevron-left;"></span></button>
                    <button class="uk-button uk-button-default" @click.prevent="invoicesList(Invoices.CurrentPage + 1)"
                        v-if="Invoices.CurrentPage < totalPages"><span uk-icon="icon: chevron-right;"></span></button>
                    <button class="uk-button uk-button-default" @click.prevent="invoicesList(totalPages)"
                        v-if="Invoices.CurrentPage < totalPages"><span
                            uk-icon="icon: chevron-double-right;"></span></button>

                </div>
            </section>
        </div>
    </section>
    <section>
        <!--modals-->
        <div id="modal-update-payment-status" uk-modal>
            <div class="uk-modal-dialog uk-modal-body">
                <h4 class="">Modifica stato pagamento</h4>
                <p>Cliente: {{ Modals.UpdatePaymentStatusCustomerName }}</p>
                <p>Fattura: {{ Modals.UpdatePaymentStatusInvoiceNumber }}</p>
                <select class="uk-select" placeholder="Stato pagamento" v-model="Modals.UpdatePaymentStatusPaymentStatus">
                    <option v-for="payment in PaymentStatusSelect" :value="payment.value" :key="payment.value">{{
                        payment.label }}</option>
                </select>
                <p class="uk-text-right">
                    <button class="uk-button uk-button-default uk-modal-close" type="button">Annulla</button>
                    <button :disabled="Modals.UpdatePaymentStatusPaymentStatus == ''" class="uk-button uk-button-primary"
                        type="button" @click.prevent="updatePaymentStatus">Modifica</button>
                </p>
            </div>
        </div>

        <div id="modal-update-payment-status-multi" uk-modal>
            <div class="uk-modal-dialog uk-modal-body">
                <h4 class="">Modifica stati pagamento (multi)</h4>
                <select class="uk-select" placeholder="Stato pagamento" v-model="Modals.UpdatePaymentStatusPaymentStatusMulti">
                    <option v-for="payment in PaymentStatusSelect" :value="payment.value" :key="payment.value">{{
                        payment.label }}</option>
                </select>
                <textarea class="uk-textarea" rows="10" 
                v-model="Modals.UpdatePaymentStatusInvoiceIdsMulti"
                placeholder="Inserisci un numero di fattura per riga."></textarea>
                <p class="uk-text-meta">Inserisci un numero di fattura per riga. <br>
                    Puoi inserire sia numeri nel formato a 12 cifre che nel formato nnnn/sez.
                    Se inserisci numeri con il formato nnnn/sez, specifica l'anno di riferimento nel campo Anno.</p>
                <input type="text" class="uk-input" 
                v-model="Modals.UpdatePaymentStatusYearMulti"
                placeholder="Anno di riferimento (yyyy). (Solo per numerazione nnnn/sez). " />
                <p class="uk-text-right">
                    <button class="uk-button uk-button-default uk-modal-close" type="button">Annulla</button>
                    <button 
                    :disabled="
                        Modals.UpdatePaymentStatusPaymentStatusMulti == '' 
                        || Modals.UpdatePaymentStatusInvoiceIdsMulti.trim() == ''
                        || (Modals.UpdatePaymentStatusInvoiceIdsMulti.indexOf('/')>-1 && Modals.UpdatePaymentStatusYearMulti.length != 4)
                        " 
                    class="uk-button uk-button-primary"
                    @click.prevent="updatePaymentStatusMulti"
                    type="button" >Modifica</button>
                </p>
            </div>
        </div>

        <div id="modal-add-public-annotation" uk-modal>
            <div class="uk-modal-dialog uk-modal-body">
                <h4 class="">Aggiungi nota pubblica</h4>
                <p>Cliente: {{ Modals.AddPublicAnnotationCustomerName }}</p>
                <p>Fattura: {{ Modals.AddPublicAnnotationInvoiceNumber }}</p>
                <select class="uk-select" placeholder="Stato pagamento" v-model="Modals.AddPublicAnnotationAnnotationCode">
                    <option v-for="annotation in Annotations" :value="annotation.value" :key="annotation.value">{{
                        annotation.label }}</option>
                </select>
                <div class="uk-margin-top">
                    <datepicker :enable-time-picker="false" v-model="Modals.AddPublicAnnotationAnnotationPostponedDate"
                        :disabled="Modals.AddPublicAnnotationAnnotationCode != 'POSTPON'" placeholder="Scadenza posticipata"
                        format="dd/MM/yyyy" cancel-text="Annulla" select-text="OK" />
                </div>
                <p class="uk-text-right">
                    <button class="uk-button uk-button-default uk-modal-close" type="button">Annulla</button>
                    <button
                        :disabled="Modals.AddPublicAnnotationAnnotationCode == '' || (Modals.AddPublicAnnotationAnnotationCode == 'POSTPON' && Modals.AddPublicAnnotationAnnotationPostponedDate == '')"
                        class="uk-button uk-button-primary" type="button"
                        @click.prevent="addPublicAnnotation">Aggiungi</button>
                </p>
            </div>
        </div>

        <div id="modal-get-public-annotations" uk-modal>
            <div class="uk-modal-dialog uk-modal-body">
                <h4 class="">Note pubbliche</h4>
                <div class="public-annotations-list">
                    <div class="uk-margin-top uk-margin-bottom" v-if="Modals.GetPublicAnnotationsList.length == 0">
                        Nessuna nota pubblica presente.
                    </div>
                    <table class="uk-table uk-table-striped" v-if="Modals.GetPublicAnnotationsList.length > 0">
                        <tbody>
                            <tr v-for="(annotation) in Modals.GetPublicAnnotationsList" :key="annotation['insertDate']">
                                <td><a @click.prevent="deletePublicAnnotation(annotation['id'])" href="javascript:;">Elimina</a></td>
                                <td class="uk-text-meta">{{ annotation['insertDate'] }}</td>
                                <td>
                                    <span v-if="annotation['annotationCode'] == 'RETURN'">Fattura oggetto di piano di
                                        rientro</span>
                                    <span v-if="annotation['annotationCode'] == 'COMPENS'">Fattura oggetto di
                                        compensazione</span>
                                    <span v-if="annotation['annotationCode'] == 'POSTPON'">La scadenza è stata posticipata
                                        al
                                        {{ annotation['annotationPostponedDate'] }}</span>
                                    <span v-if="annotation['annotationCode'] == 'REFUND'">Fattura oggetto di rimborso</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <p class="uk-text-right">
                    <button class="uk-button uk-button-primary uk-modal-close" type="button">Chiudi</button>
                </p>
            </div>
        </div>

        <div id="modal-set-internal-notes" uk-modal>
            <div class="uk-modal-dialog uk-modal-body">
                <h4 class="">Note interne</h4>
                <p>Cliente: {{ Modals.SetInternalNotesCustomerName }}</p>
                <p>Fattura: {{ Modals.SetInternalNotesInvoiceNumber }}</p>
                <textarea class="uk-textarea" rows="8" v-model="Modals.SetInternalNotesAnnotation"></textarea>
                <p class="uk-text-right">
                    <button class="uk-button uk-button-default uk-modal-close" type="button">Annulla</button>
                    <button class="uk-button uk-button-primary" type="button"
                        @click.prevent="setInternalNotes">Modifica</button>
                </p>
            </div>
        </div>
    </section>
</template>
  
<style scoped>
.bg-box {
    border: solid 1px #cccccc;
}

.public-annotations-list {
    max-height: 300px;
    overflow-y: scroll;
}
</style>
  
<script lang="ts">
const axios = require("axios").default;
import commonFunctions from "../../functions/common.js"; // eslint-disable-line no-unused-vars
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import UIkit from 'uikit'

export default {
    name: "BillbooksList",
    components: { Datepicker },
    computed: {
        token() {
            return this.$store.state.token;
        },
        email() {
            return this.$store.state.email;
        },
        isAdmin() {
            return this.$store.state.admin;
        },
        grants() {
            return this.$store.state.grants;
        },
        billbooks() {
            return this.getGrants("billbooks")
        },
        totalPages() {
            var tot = Math.floor(this.Invoices.Total / 25);
            if (tot * 25 < this.Invoices.Total) tot += 1
            return tot
        }
    },

    data() {
        return {

            APIHost: process.env.VUE_APP_APIHOST,
            Invoices: {
                List: [],
                CurrentPage: 0,
                Loaded: false,
                Total: 0
            },
            Search: {
                CustomerCode: "",
                CustomerName: "",
                InvoiceNumber: "",
                Vat: "",
                FiscalCode: "",
                ExpirationFrom: "",
                ExpirationTo: "",
                InvoiceDateFrom: "",
                InvoiceDateTo: "",
                PaymentStatus: "",
                IsForced: false,
                IsStatusNotResolved: false,
                DiscardSingleNegativeOpenRows: false,
                Submitting: false
            },
            PaymentStatus: [
                { value: '', label: 'Tutti gli stati di pagamento' },
                { value: 'PAID', label: 'Pagato' },
                { value: 'PENDING', label: 'Pending' },
                { value: 'NOTPAID', label: 'Non pagato' },
                { value: 'COMPENS', label: 'Compensazione' },
                { value: 'PARTIAL', label: 'Pag. parziale' },
            ],
            PaymentStatusSelect: [
                { value: '', label: 'Seleziona...' },
                { value: 'PAID', label: 'Pagato' },
                { value: 'PENDING', label: 'Pending' },
                { value: 'NOTPAID', label: 'Non pagato' },
                { value: 'COMPENS', label: 'Compensazione' },
                { value: 'PARTIAL', label: 'Pag. parziale' },
            ],
            Annotations: [
                { value: '', label: 'Seleziona nota' },
                { value: 'RETURN', label: 'Fattura oggetto di piano di rientro' },
                { value: 'COMPENS', label: 'Fattura oggetto di compensazione' },
                { value: 'POSTPON', label: 'La scadenza è stata posticipata al...' },
                { value: 'REFUND', label: 'Fattura oggetto di rimborso' }
            ],
            Modals: {
                UpdatePaymentStatusInvoiceId: 0,
                UpdatePaymentStatusSupplyTypeCode: "",
                UpdatePaymentStatusPaymentStatus: "",
                UpdatePaymentStatusIndex: -1,
                UpdatePaymentStatusCustomerName: "",
                UpdatePaymentStatusInvoiceNumber: "",

                AddPublicAnnotationInvoiceId: 0,
                AddPublicAnnotationSupplyTypeCode: "",
                AddPublicAnnotationAnnotationCode: "",
                AddPublicAnnotationAnnotationPostponedDate: "",
                AddPublicAnnotationIndex: -1,
                AddPublicAnnotationCustomerName: "",
                AddPublicAnnotationInvoiceNumber: "",

                GetPublicAnnotationsList: [],

                SetInternalNotesInvoiceId: 0,
                SetInternalNotesSupplyTypeCode: "",
                SetInternalNotesAnnotation: "",
                SetInternalNotesCustomerName: "",
                SetInternalNotesInvoiceNumber: "",

                UpdatePaymentStatusPaymentStatusMulti: "",
                UpdatePaymentStatusInvoiceIdsMulti: "",
                UpdatePaymentStatusYearMulti: "",

                GetPublicAnnotationsIndex: 0
            }
        };
    },
    methods: {
        getGrants(k) {
            var r = {
                canManage: false,
                label: ""
            }
            for (var x = 0; x < this.grants.length; x++) {
                if (this.grants[x].ModuleKeyID == k) {
                    r.canManage = this.grants[x].CanManage;
                    r.label = this.grants[x].ModuleLabel;
                }
            }
            return r;
        },
        errorNotification(msg) {
            UIkit.notification({// eslint-disable-line no-undef
                message: msg == null ? "Si è verificato un errore." : msg,
                status: "danger",
                pos: "top-center",
                timeout: 4000,
            });
        },
        successNotification(msg) {
            UIkit.notification({// eslint-disable-line no-undef
                message: msg == null ? "Operazione eseguita con successo." : msg,
                status: "success",
                pos: "top-center",
                timeout: 4000,
            });
        },
        search() {
            this.Search.Submitting = true
            this.invoicesList(1);
        },
        clearSearch() {
            this.Search.CustomerCode = ""
            this.Search.CustomerName = ""
            this.Search.InvoiceNumber = ""
            this.Search.Vat = ""
            this.Search.FiscalCode = ""
            this.Search.ExpirationFrom = ""
            this.Search.ExpirationTo = ""
            this.Search.InvoiceDateFrom = ""
            this.Search.InvoiceDateTo = ""
            this.Search.PaymentStatus = ""
            this.Search.IsForced = false
            this.Search.IsStatusNotResolved = false
            this.Search.DiscardSingleNegativeOpenRows = false
        },
        changeIsStatusNotResolved() {
            if (this.Search.IsStatusNotResolved == true) {
                this.Search.PaymentStatus = ""
            }
        },
        changePaymentStatus() {
            if (this.Search.PaymentStatus != '') {
                this.Search.IsStatusNotResolved = false
            }
        },
        changeIsForced() {
            if (this.Search.IsForced == true) {
                this.Search.IsStatusNotResolved = false
            }
        },
        modalPaymentStatusMulti(){
            this.Modals.UpdatePaymentStatusPaymentStatusMulti = ""
            this.Modals.UpdatePaymentStatusInvoiceIdsMulti = ""
            this.Modals.UpdatePaymentStatusYearMulti = ""
            UIkit.modal("#modal-update-payment-status-multi").show()
        },
        modalPaymentStatus(index) {
            this.Modals.UpdatePaymentStatusIndex = index
            this.Modals.UpdatePaymentStatusInvoiceId = this.Invoices.List[index]["InvoiceId"]
            this.Modals.UpdatePaymentStatusSupplyTypeCode = this.Invoices.List[index]["SupplyTypeCode"]
            this.Modals.UpdatePaymentStatusPaymentStatus = this.Invoices.List[index]["PaymentStatus"]
            this.Modals.UpdatePaymentStatusCustomerName = this.Invoices.List[index]["CustomerName"]
            this.Modals.UpdatePaymentStatusInvoiceNumber = this.Invoices.List[index]["InvoiceNumber"]
        },
        modalAddPublicAnnotation(index) {
            this.Modals.AddPublicAnnotationIndex = index
            this.Modals.AddPublicAnnotationInvoiceId = this.Invoices.List[index]["InvoiceId"]
            this.Modals.AddPublicAnnotationSupplyTypeCode = this.Invoices.List[index]["SupplyTypeCode"]
            this.Modals.AddPublicAnnotationAnnotationCode = ""
            this.Modals.AddPublicAnnotationAnnotationPostponedDate = ""
            this.Modals.AddPublicAnnotationCustomerName = this.Invoices.List[index]["CustomerName"]
            this.Modals.AddPublicAnnotationInvoiceNumber = this.Invoices.List[index]["InvoiceNumber"]
        },
        updatePaymentStatusMulti() {
            axios({
                method: "patch",
                url: this.APIHost + "/api/v1/billbooks-multi",
                data: {
                    PaymentStatus: this.Modals.UpdatePaymentStatusPaymentStatusMulti,
                    InvoiceIds: this.Modals.UpdatePaymentStatusInvoiceIdsMulti,
                    Year: this.Modals.UpdatePaymentStatusYearMulti
                },
                headers: {
                    Token: this.token
                }
            })
                .then(
                    function (response) {// eslint-disable-line no-unused-vars
                        if (response) {
                            UIkit.modal("#modal-update-payment-status-multi").hide();
                            UIkit.modal("#modal-update-payment-status-multi").hide();
                            UIkit.modal("#modal-update-payment-status-multi").hide();
                            if (response.data.indexOf("0/")==0){
                                this.errorNotification("Stati di pagamento aggiornati: " + response.data);
                            }else{
                                this.successNotification("Stati di pagamento aggiornati: " + response.data);
                            }
                        } else {
                            this.errorNotification();
                        }
                    }.bind(this)
                )
                .catch(function (error) {// eslint-disable-line no-unused-vars
                    console.log(error)
                    this.errorNotification();
                }.bind(this));
        },
        modalSetInternalNotes(index) {
            this.getInternalNotes(index)
        },
        deletePublicAnnotation(id){
            axios({
                method: "delete",
                url: this.APIHost + "/api/v1/billbooks-annotations",
                params: {
                    id: id
                },
                headers: {
                    Token: this.token
                }
            })
                .then(
                    function (response) {// eslint-disable-line no-unused-vars
                        console.log(response)
                        console.log(response.data == 1)
                        if (response && response.data == 1) {
                            this.Invoices.List[this.GetPublicAnnotationsIndex]["Annotations"] -= 1
                            this.getPublicAnnotations(this.GetPublicAnnotationsIndex)

                        } else {
                            this.errorNotification();
                        }
                    }.bind(this)
                )
                .catch(function (error) {// eslint-disable-line no-unused-vars
                    console.log(error)
                    this.errorNotification();
                }.bind(this));
        },
        addPublicAnnotation() {
            axios({
                method: "post",
                url: this.APIHost + "/api/v1/billbooks-annotations",
                params: {
                    InvoiceId: this.Modals.AddPublicAnnotationInvoiceId,
                    SupplyTypeCode: this.Modals.AddPublicAnnotationSupplyTypeCode,
                    AnnotationCode: this.Modals.AddPublicAnnotationAnnotationCode,
                    AnnotationPostponedDate: this.Modals.AddPublicAnnotationAnnotationPostponedDate
                },
                headers: {
                    Token: this.token
                }
            })
                .then(
                    function (response) {// eslint-disable-line no-unused-vars
                        if (response && response.data == true) {
                            this.Invoices.List[this.Modals.AddPublicAnnotationIndex]["Annotations"] += 1
                            UIkit.modal("#modal-add-public-annotation").hide();
                            UIkit.modal("#modal-add-public-annotation").hide();
                            UIkit.modal("#modal-add-public-annotation").hide();
                            this.successNotification();

                        } else {
                            this.errorNotification();
                        }
                    }.bind(this)
                )
                .catch(function (error) {// eslint-disable-line no-unused-vars
                    console.log(error)
                    this.errorNotification();
                }.bind(this));
        },
        getPublicAnnotations(index) {
            this.Modals.GetPublicAnnotationsList = []
            axios({
                method: "get",
                url: this.APIHost + "/api/v1/billbooks-annotations",
                params: {
                    InvoiceId: this.Invoices.List[index]["InvoiceId"],
                    SupplyTypeCode: this.Invoices.List[index]["SupplyTypeCode"]
                },
                headers: {
                    Token: this.token
                }
            })
                .then(
                    function (response) {// eslint-disable-line no-unused-vars
                        if (response && response.data != null) {
                            
                            var x;
                            for (x = 0; x < response.data.length; x++) {
                                this.Modals.GetPublicAnnotationsList.push(response.data[x]);
                            }
                            this.GetPublicAnnotationsIndex = index
                        } else {
                            this.errorNotification();
                        }
                    }.bind(this)
                )
                .catch(function (error) {// eslint-disable-line no-unused-vars
                    console.log(error)
                    this.errorNotification();
                }.bind(this));
        },
        updatePaymentStatus() {
            axios({
                method: "patch",
                url: this.APIHost + "/api/v1/billbooks",
                params: {
                    InvoiceId: this.Modals.UpdatePaymentStatusInvoiceId,
                    SupplyTypeCode: this.Modals.UpdatePaymentStatusSupplyTypeCode,
                    PaymentStatus: this.Modals.UpdatePaymentStatusPaymentStatus
                },
                headers: {
                    Token: this.token
                }
            })
                .then(
                    function (response) {// eslint-disable-line no-unused-vars
                        if (response && response.data == true) {
                            const d = new Date()
                            const lastUpdate = d.toLocaleDateString() + ' ' + d.toLocaleTimeString()
                            this.Invoices.List[this.Modals.UpdatePaymentStatusIndex]["PaymentStatus"] = this.Modals.UpdatePaymentStatusPaymentStatus
                            this.Invoices.List[this.Modals.UpdatePaymentStatusIndex]["IsForced"] = true
                            this.Invoices.List[this.Modals.UpdatePaymentStatusIndex]["LastUpdate"] = lastUpdate
                            UIkit.modal("#modal-update-payment-status").hide();
                            UIkit.modal("#modal-update-payment-status").hide();
                            UIkit.modal("#modal-update-payment-status").hide();
                            this.successNotification();

                        } else {
                            this.errorNotification();
                        }
                    }.bind(this)
                )
                .catch(function (error) {// eslint-disable-line no-unused-vars
                    console.log(error)
                    this.errorNotification();
                }.bind(this));
        },
        setInternalNotes() {
            axios({
                method: "post",
                url: this.APIHost + "/api/v1/internal-notes",
                data: {
                    InvoiceId: this.Modals.SetInternalNotesInvoiceId,
                    SupplyTypeCode: this.Modals.SetInternalNotesSupplyTypeCode,
                    Annotation: this.Modals.SetInternalNotesAnnotation
                },
                headers: {
                    Token: this.token
                }
            })
                .then(
                    function (response) {// eslint-disable-line no-unused-vars
                        if (response && response.data == true) {
                            UIkit.modal("#modal-set-internal-notes").hide();
                            UIkit.modal("#modal-set-internal-notes").hide();
                            UIkit.modal("#modal-set-internal-notes").hide();
                            this.successNotification();

                        } else {
                            this.errorNotification();
                        }
                    }.bind(this)
                )
                .catch(function (error) {// eslint-disable-line no-unused-vars
                    console.log(error)
                    this.errorNotification();
                }.bind(this));
        },
        getInternalNotes(index) {
            axios({
                method: "get",
                url: this.APIHost + "/api/v1/internal-notes",
                params: {
                    InvoiceId: this.Invoices.List[index]["InvoiceId"],
                    SupplyTypeCode: this.Invoices.List[index]["SupplyTypeCode"]
                },
                headers: {
                    Token: this.token
                }
            })
                .then(
                    function (response) {// eslint-disable-line no-unused-vars
                        if (response && response.data != null) {
                            this.Modals.SetInternalNotesInvoiceId = this.Invoices.List[index]["InvoiceId"]
                            this.Modals.SetInternalNotesSupplyTypeCode = this.Invoices.List[index]["SupplyTypeCode"]
                            this.Modals.SetInternalNotesCustomerName = this.Invoices.List[index]["CustomerName"]
                            this.Modals.SetInternalNotesInvoiceNumber = this.Invoices.List[index]["InvoiceNumber"]
                            this.Modals.SetInternalNotesAnnotation = response.data
                            UIkit.modal("#modal-set-internal-notes").show()
                        } else {
                            this.errorNotification();
                        }
                    }.bind(this)
                )
                .catch(function (error) {// eslint-disable-line no-unused-vars
                    console.log(error)
                    this.errorNotification();
                }.bind(this));
        },
        invoicesList(pageNumber) {
            axios({
                method: "get",
                url: this.APIHost + "/api/v1/billbooks",
                params: {
                    PageNumber: pageNumber,
                    PaymentStatus: this.Search.PaymentStatus,
                    CustomerCode: this.Search.CustomerCode,
                    CustomerName: this.Search.CustomerName,
                    Vat: this.Search.Vat,
                    FiscalCode: this.Search.FiscalCode,
                    InvoiceNumber: this.Search.InvoiceNumber,
                    ExpirationFrom: this.Search.ExpirationFrom,
                    ExpirationTo: this.Search.ExpirationTo,
                    InvoiceDateFrom: this.Search.InvoiceDateFrom,
                    InvoiceDateTo: this.Search.InvoiceDateTo,
                    IsForced: this.Search.IsForced,
                    IsStatusNotResolved: this.Search.IsStatusNotResolved,
                    DiscardSingleNegativeOpenRows: this.Search.DiscardSingleNegativeOpenRows
                },
                headers: {
                    Token: this.token
                }
            })
                .then(
                    function (response) {// eslint-disable-line no-unused-vars
                        this.Invoices.List = [];
                        var x;
                        for (x = 0; x < response.data.length; x++) {
                            this.Invoices.List.push(response.data[x]);
                        }
                        this.Invoices.CurrentPage = pageNumber;
                        this.Invoices.Total = this.Invoices.List.length > 0 ? parseInt(this.Invoices.List[0]["Total"]) : 0;
                        this.Invoices.Loaded = true;
                        this.Search.Submitting = false
                        window.scrollTo({ top: 300, left: 0, behavior: 'smooth' })
                    }.bind(this)
                )
                .catch(function (error) {// eslint-disable-line no-unused-vars
                    this.errorNotification();
                    this.Search.Submitting = false
                }.bind(this));
        },


        billbooksExport() {
            axios({
                method: "get",
                url: this.APIHost + "/api/v1/billbooks-export",
                params: {
                    Email: this.email,
                    PaymentStatus: this.Search.PaymentStatus,
                    CustomerCode: this.Search.CustomerCode,
                    CustomerName: this.Search.CustomerName,
                    Vat: this.Search.Vat,
                    FiscalCode: this.Search.FiscalCode,
                    InvoiceNumber: this.Search.InvoiceNumber,
                    ExpirationFrom: this.Search.ExpirationFrom,
                    ExpirationTo: this.Search.ExpirationTo,
                    InvoiceDateFrom: this.Search.InvoiceDateFrom,
                    InvoiceDateTo: this.Search.InvoiceDateTo,
                    IsForced: this.Search.IsForced,
                    IsStatusNotResolved: this.Search.IsStatusNotResolved,
                    DiscardSingleNegativeOpenRows: this.Search.DiscardSingleNegativeOpenRows
                },
                headers: {
                    Token: this.token
                }
            })
                .then(
                    function (response) {// eslint-disable-line no-unused-vars
                        if (response.data == "started") {
                            this.successNotification("Procedura di export avviata. Attendere notifica via email.")
                        }
                        if (response.data == "busy") {
                            this.errorNotification("E' già in corso un altro processo di export. Attendere la notifica di completamento prima di procedere.");
                        }
                        if (response.data == "error") {
                            this.errorNotification()
                        }
                    }.bind(this)
                )
                .catch(function (error) {// eslint-disable-line no-unused-vars
                    this.errorNotification();
                }.bind(this));
        },
    },
    mounted() {
        
    },
    updated() {

    }
};
</script>
  
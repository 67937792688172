<template>
  <h4>Gestione autoletture</h4>
    <ul class="uk-list">
        <li v-if="isAdmin || selfReadings.canManage || selfReadingsAdmin.canManage">
          <router-link to="/self-readings-insert">Inserimento autoletture</router-link>
        </li>
        <li v-if="isAdmin || selfReadings.canManage || selfReadingsAdmin.canManage">
          <router-link to="/self-readings-export">Export autoletture</router-link>
        </li>
        <li v-if="isAdmin || selfReadingsAdmin.canManage">
          <router-link to="/self-readings-time-windows">Impostazione finestre temporali</router-link>
        </li>
        <li v-if="isAdmin || selfReadingsAdmin.canManage">
          <router-link to="/self-readings-settings">Altre impostazioni</router-link>
        </li>
    </ul>
</template>

<script type="typescript">
export default {
  name: "SelfReadings_Menu",
  computed: {
    isLoggedIn() {
      return this.$store.state.isLoggedIn;
    },
    isAdmin() {
      return this.$store.state.admin;
    },
    grants() {
      return this.$store.state.grants;
    },
    selfReadings() {
      return this.getGrants("selfreadings")
    },
    selfReadingsAdmin() {
      return this.getGrants("selfreadingsadmin")
    }
  },
  methods: {
    getGrants(k) {
      var r = {
        canManage: false,
        label: ""
      }
      for (var x = 0; x < this.grants.length; x++) {
        if (this.grants[x].ModuleKeyID == k) {
          r.canManage = this.grants[x].CanManage;
          r.label = this.grants[x].ModuleLabel;
        }
      }
      return r;
    },
    
  },
};
</script>
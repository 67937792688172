<template>
    <div class="uk-text-meta uk-margin-bottom">
    <router-link to="/self-readings">Gestione autoletture</router-link><span uk-icon="chevron-right"></span><span>Export excel autoletture</span>
  </div>
    <div>
        
        <div class="uk-card uk-card-body uk-card-default uk-width-1-1">
            <h4>Export excel autoletture</h4>
            <table class="uk-table uk-width-1-2@s">
                <tr>
                    <td>
                        Inizio export:
                    </td>
                    <td>
                        <select class="uk-select uk-form-width-xsmall" v-model="startDay" @change="autoCorrectDay">
                            <option v-for="index in 31" :key="index">
                                {{ index }}
                            </option>
                        </select>
                    </td>
                    <td>
                        <select class="uk-select uk-form-width-small" v-model="startMonth" @change="autoCorrectDay">
                            <option v-for="month in months" :value="month.value" :key="month.value">
                                {{ month.label }}
                            </option>
                        </select>
                    </td>
                    <td>
                        <select class="uk-select uk-form-width-small" v-model="startYear" @change="autoCorrectDay">
                            <option v-for="year in years" :key="year">
                                {{ year }}
                            </option>
                        </select>
                    </td>
                </tr>
                <tr>
                    <td>
                        Fine export:
                    </td>
                    <td>
                        <select class="uk-select uk-form-width-xsmall" v-model="endDay" @change="autoCorrectDay">
                            <option v-for="index in 31" :key="index">
                                {{ index }}
                            </option>
                        </select>
                    </td>
                    <td>
                        <select class="uk-select uk-form-width-small" v-model="endMonth" @change="autoCorrectDay">
                            <option v-for="month in months" :value="month.value" :key="month.value">
                                {{ month.label }}
                            </option>
                        </select>
                    </td>
                    <td>
                        <select class="uk-select uk-form-width-small" v-model="endYear" @change="autoCorrectDay">
                            <option v-for="year in years" :key="year">
                                {{ year }}
                            </option>
                        </select>
                    </td>
                </tr>
                <tr>
                    <td>Codice cliente:</td>
                    <td colspan="2">
                        <input class="uk-input uk-form-width-medium" v-model="customerCode" placeholder="Codice cliente" />
                    </td>
                </tr>
                <tr>
                    <td>POD/PDR:</td>
                    <td colspan="2">
                        <input class="uk-input uk-form-width-medium" v-model="supplyPoint" placeholder="POD/PDR" />
                    </td>
                </tr>
                <tr>
                    <td>Indirizzo ricezione export:</td>
                    <td colspan="2">
                        <input class="uk-input uk-form-width-medium" v-model="exportEmail" placeholder="Email"
                            :class="{ 'uk-form-danger': !formValidation.exportEmail, 'uk-form-success': formValidation.exportEmail }"
                            @keyup="setFormValidationAttributes" />
                    </td>
                </tr>
            </table>
            <div class="uk-margin-top">
                <button class="uk-button uk-button-primary" :disabled="!validateForm"
                @click.prevent="submit"
                >Avvia esportazione</button>
            </div>
        </div>

    </div>
</template>
<style scoped></style>
<script>
const axios = require("axios").default; // eslint-disable-line no-unused-vars
import commonFunctions from "../../functions/common.js"; // eslint-disable-line no-unused-vars
import UIkit from 'uikit' // eslint-disable-line no-unused-vars

export default {
    name: "SelfReadings_Export",
    computed: {
        token() {
        return this.$store.state.token;
        },
        email() {
            return this.$store.state.email
        }
    },
    data() {
        return {
            years: [],
            startDay: 0,
            endDay: 0,
            startMonth: 0,
            startYear: 0,
            endMonth: 0,
            endYear: 0,
            exportEmail: "",
            customerCode: "",
            supplyPoint: "",
            formValidation: {
                exportEmail: false
            },
            months: [
                {value: 1, label: 'Gennaio'},
                {value: 2, label: 'Febbraio'},
                {value: 3, label: 'Marzo'},
                {value: 4, label: 'Aprile'},
                {value: 5, label: 'Maggio'},
                {value: 6, label: 'Giugno'},
                {value: 7, label: 'Luglio'},
                {value: 8, label: 'Agosto'},
                {value: 9, label: 'Settembre'},
                {value: 10, label: 'Ottobre'},
                {value: 11, label: 'Novembre'},
                {value: 12, label: 'Dicembre'},
            ]
        }

    },
    methods: {
        errorNotification(msg) {
            UIkit.notification({// eslint-disable-line no-undef
                message: msg == null ? "Si è verificato un errore." : msg,
                status: "danger",
                pos: "top-center",
                timeout: 4000,
            });
        },
        successNotification(msg) {
            UIkit.notification({// eslint-disable-line no-undef
                message: msg == null ? "Operazione eseguita con successo." : msg,
                status: "success",
                pos: "top-center",
                timeout: 4000,
            });
        },
        setFormValidationAttributes() {
            this.formValidation.exportEmail = commonFunctions.isEmail(this.exportEmail)
        },
        validateForm() {
            return commonFunctions.isEmail(this.exportEmail)
        },
        autoCorrectDay(){
            // 30
            if (this.startDay == 31 && 
            (this.startMonth == 4 || this.startMonth == 6 || this.startMonth == 9 || this.startMonth == 11)
            ) {
                this.startDay = 30
            }
            if (this.endDay == 31 && 
            (this.endMonth == 4 || this.endMonth == 6 || this.endMonth == 9 || this.endMonth == 11)
            ) {
                this.endDay = 30
            }
            // 28
            if (this.startDay > 28 && this.startMonth == 2 &&
                (this.startYear != 2024 && this.startYear != 2028 && this.startYear != 2032 && this.startYear != 2036)
            ) {
                this.startDay = 28
            }
            if (this.endDay > 28 && this.endMonth == 2 &&
            (this.endYear != 2024 && this.endYear != 2028 && this.endYear != 2032 && this.endYear != 2036)
            ) {
                this.endDay = 28
            }
            //29
            if (this.startDay > 29 && this.startMonth == 2 &&
                (this.startYear == 2024 || this.startYear == 2028 || this.startYear == 2032 || this.startYear == 2036)
            ) {
                this.startDay = 29
            }
            if (this.endDay > 29 && this.endMonth == 2 &&
                (this.endYear == 2024 || this.endYear == 2028 || this.endYear == 2032 || this.endYear == 2036)
            ) {
                this.endDay = 29
            }
            console.log(this.startDay)
        },
        getGrants(k) {
            var r = {
                canManage: false,
                label: ""
            }
            for (var x = 0; x < this.grants.length; x++) {
                if (this.grants[x].ModuleKeyID == k) {
                    r.canManage = this.grants[x].CanManage;
                    r.label = this.grants[x].ModuleLabel;
                }
            }
            return r;
        },
        setYears() {
            var startYear = 2023;
            var endYear = new Date().getFullYear();
            for (var x = startYear; x <= endYear; x++) {
                this.years.push(x);
            }
        },
        submit() {

            axios({
                method: "get",
                url: process.env.VUE_APP_APIHOST + "/api/v1/self-readings",
                params: {
                    startDay: this.startDay,
                    endDay: this.endDay,
                    startMonth: this.startMonth,
                    endMonth: this.endMonth,
                    startYear: this.startYear,
                    endYear: this.endYear,
                    customerCode: this.customerCode,
                    supplyPoint: this.supplyPoint,
                    exportEmail: this.exportEmail
                },
                headers: {
                    Token: this.token
                }
            })
                .then(
                    function (response) {// eslint-disable-line no-unused-vars
                        if (response.data){
                            this.successNotification("Export in fase di elaborazione. Il file verrà inviato all'indirizzo " + this.exportEmail)
                        }
                    }.bind(this)
                )
                .catch(function (error) {// eslint-disable-line no-unused-vars
                    this.errorNotification();
                }.bind(this));

        }
    },
    mounted() {
        this.setYears()
        var d = new Date()
        this.startDay = d.getDate()
        this.endDay = d.getDate()
        this.startMonth = d.getMonth() + 1
        this.endMonth = d.getMonth() + 1
        this.startYear = d.getFullYear()
        this.endYear = d.getFullYear()
        this.exportEmail = this.email
        this.setFormValidationAttributes()
    },
    updated() {

    }
}
</script>

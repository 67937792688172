<template>
  <div class="uk-width-1-1 uk-background-muted uk-padding-small">
    <div class="uk-container">
      <header-section />
    </div>
  </div>
  <div
    class="uk-container uk-margin-large-top"
    uk-height-viewport="expand: true"
  >
    <section>
      <div class="uk-grid">
        <div class="uk-width-large uk-align-center">
          <form v-on:submit.prevent="userFirstActivation()">
            <div class="uk-card uk-card-default uk-card-body">
              <div class="uk-card-title">
                <h3>Attivazione utente</h3>
              </div>
              <div class="uk-form">
                <div class="uk-form-row uk-margin-top">
                  Per completare l'attivazione inserisci una nuova password. <br>
                  La password deve avere una lunghezza da 8 a 20 caratteri,
                  contenere numeri e lettere, sia maiuscole che minuscole, ed almeno un carattere speciale.
                </div>
                <div class="uk-form-row uk-margin-top">
                  <input
                    class="uk-input"
                    placeholder="Password"
                    type="password"
                    v-model="userFirstActivationPassword"
                    v-on:keyup="userFirstActivationValidate()"
                  />
                </div>
                <div class="uk-form-row uk-margin-top">
                  <input
                    class="uk-input"
                    placeholder="Ripeti password"
                    type="password"
                    v-model="userFirstActivationPassword2"
                    v-on:keyup="userFirstActivationValidate()"
                  />
                </div>

                <div class="uk-form-row uk-margin-top">
                  <button
                    class="uk-button uk-button-primary"
                    type="submit"
                    v-bind:disabled="!userFirstActivationValidate()"
                  >
                    Completa attivazione
                  </button>
                </div>
              </div>              
            </div>
          </form>
        </div>
      </div>
    </section>
  </div>
  <footer-section />
</template>
<script>
import HeaderSection from "../components/Header/HeaderSection.vue";
import FooterSection from "../components/Footer/FooterSection.vue";
import commonFunctions from "../functions/common.js"; // eslint-disable-line no-unused-vars
import UIkit from 'uikit'
const axios = require("axios").default; // eslint-disable-line no-unused-vars
export default {
    name: "UserActivation",
    data() {
      return {
      userFirstActivationPassword: "",
      userFirstActivationPassword2: "",
      APIHost: process.env.VUE_APP_APIHOST
      }
    },
    components: {
      HeaderSection,
      FooterSection
    },
    computed: {
      token() {
        return this.$route.params.token
      }
    },
    methods: {
      userFirstActivationValidate() {
        if (
          commonFunctions.isNullOrWhiteSpace(this.userFirstActivationPassword) ||
          commonFunctions.isNullOrWhiteSpace(this.userFirstActivationPassword2) ||
          !commonFunctions.isValidPassword(this.userFirstActivationPassword) ||
          !commonFunctions.isValidPassword(this.userFirstActivationPassword2) ||
          this.userFirstActivationPassword != this.userFirstActivationPassword2
        ) {
          return false;
        } else {
          return true;
        }
      },
      userFirstActivation() {
        axios({
        method: "post",
        url: this.APIHost + "/api/v1/user-first-activation",
        data: {
          Password: this.userFirstActivationPassword,
          InsertToken: this.token,
        },
      })
        .then(
          function (response) {
            if (response.data && response.data.result && response.data.result == "ok") {
                this.$store.commit("logout");
              axios({
                method: "get",
                url: this.APIHost + "/api/v1/delete-token"
                }).then(
                function () {
                  var payload = {
                  section: "login",
                  subsection: "",
                };
                this.$store.commit("changeSection", payload);
                this.$router.push({ name: 'Home' });
              }.bind(this));
              
            }else{
              UIkit.notification({// eslint-disable-line no-undef
                message:
                  "Si è verificato un errore. Attivazione non completata.",
                status: "danger",
                pos: "top-center",
                timeout: 4000,
              });
            }
            
          }.bind(this)
        )
        .catch(function (error) {// eslint-disable-line no-unused-vars
          // handle error
          //console.log(error);
          UIkit.notification({// eslint-disable-line no-undef
            message:
              "Si è verificato un errore. Attivazione non completata.",
            status: "danger",
            pos: "top-center",
            timeout: 4000,
          });
        })
        .then(function () {
          // always executed
        });
      }
    },
    mounted(){
      //console.log("mounted " + this.token)
    }
  
}
</script>

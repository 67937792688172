<template>
    <div>
        <h4>Trasformazione scadenzario</h4>
        <p class="uk-text">Questa procedura ti consente di trasformare lo scadenzario dal nuovo formato E-Solver al vecchio formato.
            <br>
            Il file da trasformare deve essere in formato <b>.xlsx</b>
        </p>
        
        <div class="js-upload" uk-form-custom>
            <input type="file" @change="handleFileUpload($event)">
            <button class="uk-button uk-button-default" type="button" tabindex="-1">Seleziona file (formato .XLSX)</button>
            <div v-if="file != null"><br>{{ file.name }}</div>
        </div>
        <br>
        <button :disabled="!fileSelected || submitting" class="uk-button uk-button-primary uk-margin-top" v-on:click="submitFile()"><span v-if="!submitting">Trasforma scadenzario</span><span v-if="submitting" uk-spinner="ratio: 0.7"></span></button>
    </div>
</template>
<style scoped>
.selectFile {
    border: none;
}
</style>
<script>
const axios = require("axios").default; // eslint-disable-line no-unused-vars
import commonFunctions from "../../functions/common.js"; // eslint-disable-line no-unused-vars
import UIkit from 'uikit' // eslint-disable-line no-unused-vars

export default {
    name: "SelfReadings_Insert",
    computed: {
        token() {
            return this.$store.state.token;
        },
        email() {
            return this.$store.state.email
        }
    },
    data() {
        return {
            file: null,
            fileSelected: false,
            submitting: false
        }

    },
    methods: {
        errorNotification(msg) {
            UIkit.notification({// eslint-disable-line no-undef
                message: msg == null ? "Si è verificato un errore." : msg,
                status: "danger",
                pos: "top-center",
                timeout: 4000,
            });
        },
        successNotification(msg) {
            UIkit.notification({// eslint-disable-line no-undef
                message: msg == null ? "Operazione eseguita con successo." : msg,
                status: "success",
                pos: "top-center",
                timeout: 4000,
            });
        },

        getGrants(k) {
            var r = {
                canManage: false,
                label: ""
            }
            for (var x = 0; x < this.grants.length; x++) {
                if (this.grants[x].ModuleKeyID == k) {
                    r.canManage = this.grants[x].CanManage;
                    r.label = this.grants[x].ModuleLabel;
                }
            }
            return r;
        },
        handleFileUpload(event) {
            if (event.target.files[0].name.toLowerCase().indexOf(".xlsx") == -1) {
                this.file = null;
                this.fileSelected = false;
                this.errorNotification("Il file deve essere nel formato .xlsx")
                return false;
            }
            this.file = event.target.files[0];
            this.fileSelected = true;
        },
        submitFile() {
            this.submitting=true
            axios({
                method: "post",
                url: process.env.VUE_APP_APIHOST + "/api/v1/billbook-transform?email=" + this.email,
                data: this.file,
                headers: { 
                    'Token': this.token,
                    'Content-Type': 'application/octet-stream',
                    'FileName': this.file.name
                }
            })
                .then(
                    function (response) {// eslint-disable-line no-unused-vars
                        if (response.data=="started"){
                            this.successNotification("Procedura di caricamento avviata. Attendere notifica via email.")
                        }
                        if (response.data=="busy"){
                            this.errorNotification("E' già in corso un altro processo di caricamento. Attendere la notifica di completamento prima di procedere.");
                        }
                        if (response.data=="error"){
                            this.errorNotification()
                        }
                        this.submitting=false
                    }.bind(this)
                )
                .catch(function (error) {// eslint-disable-line no-unused-vars
                    this.errorNotification();
                    this.submitting=false
                }.bind(this));
        },
        
    },
    mounted() {

    },
    updated() {

    }
}
</script>
<template>
    <section v-if="isAdmin || notifications.canManage">
        <div>
            <section>
                <p class="uk-text-lead">Gestione notifiche</p>
                <div class="uk-card  uk-card-body uk-card-default">
                    <div class="uk-grid uk-width-1-1">
                        <div class="uk-width-1-2@m">
                            <div class="uk-margin">
                                <span class="uk-text-bolder">Utenti:</span>
                            </div>
                            <div class="uk-margin">
                                <input class="uk-input" placeholder="Email utente" v-model="Search.Email">
                            </div>
                            <div class="uk-margin-top">&nbsp;</div>
                            <div class="uk-margin">
                                <span class="uk-text-bolder">Clienti:</span>
                            </div>
                            <div class="uk-margin">
                                <input class="uk-input" placeholder="Codice cliente" v-model="Search.CustomerCode">
                            </div>
                            <div class="uk-margin uk-grid-small uk-child-width-auto uk-grid">
                                <label><input class="uk-radio" type="radio" name="supplyTypeRadio" value=""
                                        v-model="Search.SupplyTypeCode"> EE+GAS</label>
                                <label><input class="uk-radio" type="radio" name="supplyTypeRadio" value="EE"
                                        v-model="Search.SupplyTypeCode"> EE</label>
                                <label><input class="uk-radio" type="radio" name="supplyTypeRadio" value="GAS"
                                        v-model="Search.SupplyTypeCode"> GAS</label>
                            </div>
                            <div class="uk-margin">
                                <select class="uk-select" placeholder="Tipologia cliente" v-model="Search.CustomerTypeCode">
                                    <option v-for="customerTypeCode in CustomerTypeCodes" :value="customerTypeCode.value"
                                        :key="customerTypeCode.value">{{
                                            customerTypeCode.label }}</option>
                                </select>
                            </div>
                            <div class="uk-margin">
                                <select class="uk-select" placeholder="Status cliente" v-model="Search.CustomerStatus">
                                    <option v-for="customerStatusCode in CustomerStatusCodes" :value="customerStatusCode.value"
                                        :key="customerStatusCode.value">{{
                                            customerStatusCode.label }}</option>
                                </select>
                            </div>
                            <br class="uk-visible@s" />
                        </div>

                        <div class="uk-width-1-2@m">
                            <div class="uk-margin">
                                <span class="uk-text-bolder">Fatture:</span>
                            </div>
                            <div class="uk-margin">
                                <input class="uk-input" placeholder="Sezionale" v-model="Search.SectionalVendorCode">
                            </div>
                            <div class="uk-margin">
                                <select @change="changePaymentStatus" class="uk-select" placeholder="Stato pagamento"
                                    v-model="Search.PaymentStatus">
                                    <option v-for="payment in PaymentStatus" :value="payment.value" :key="payment.value">{{
                                        payment.label }}</option>
                                </select>
                            </div>
                            <div class="uk-margin">
                                <datepicker :enable-time-picker="false" v-model="Search.InvoiceExpirationDateFrom"
                                    placeholder="Scadenza fattura (da)" format="dd/MM/yyyy" cancel-text="Annulla"
                                    select-text="OK" />
                            </div>
                            <div class="uk-margin">
                                <datepicker :enable-time-picker="false" v-model="Search.InvoiceExpirationDateTo"
                                    placeholder="Scadenza fattura (a)" format="dd/MM/yyyy" cancel-text="Annulla"
                                    select-text="OK" />
                            </div>
                            <div class="uk-margin">
                                <datepicker :enable-time-picker="false" v-model="Search.InvoiceDateFrom"
                                    placeholder="Data fattura (da)" format="dd/MM/yyyy" cancel-text="Annulla"
                                    select-text="OK" />
                            </div>
                            <div class="uk-margin">
                                <datepicker :enable-time-picker="false" v-model="Search.InvoiceDateTo"
                                    placeholder="Data fattura (a)" format="dd/MM/yyyy" cancel-text="Annulla"
                                    select-text="OK" />
                            </div>
                            <div class="uk-margin uk-grid-small uk-child-width-auto uk-grid">
                                <label v-for="(item) in PaymentMethods" :key="item.value">
                                    <input class="uk-checkbox" :value="item.value" name="searchPaymentMethods" type="checkbox"
                                        v-model="Search.PaymentTypeCode" /><span class="uk-margin-small-left">{{ item.label
                                        }}</span><br></label>
                            </div>
                        </div>
                    </div>
                    <hr class="uk-hr">
                    <div class="uk-margin-top">
                        <p class="uk-text-bolder">Opzioni di raggruppamento:</p>
                        <br>
                        <label><input class="uk-radio" type="radio" name="GroupingOptionsRadio" value="Invoice"
                                v-model="Search.GroupingOptions"> Raggruppa per fattura <span class="uk-text-small">(verrà inviata
                                una notifica per ogni fattura, lo stesso utente potrà ricevere più notifiche)</span></label>
                        <br>
                        <label><input class="uk-radio" type="radio" name="GroupingOptionsRadio" value="SupplyPoint"
                                v-model="Search.GroupingOptions"> Raggruppa per punto di fornitura <span class="uk-text-small">(verrà inviata
                                una notifica per ogni punto di fornitura, lo stesso utente potrà ricevere più notifiche)</span></label>
                        <br>
                        <label><input class="uk-radio" type="radio" name="GroupingOptionsRadio" value="Customer"
                                v-model="Search.GroupingOptions"> Raggruppa per cliente <span class="uk-text-small">(verrà
                                inviata una notifica per ogni cliente, lo stesso utente potrà ricevere più
                                notifiche)</span></label>
                        <br>
                        <label><input class="uk-radio" type="radio" name="GroupingOptionsRadio" value="User"
                                v-model="Search.GroupingOptions"> Raggruppa per utente registrato <span
                                class="uk-text-small">(verrà inviata una sola notifica per ogni utente
                                registrato)</span></label>

                    </div>
                    <div class="uk-margin-top">
                        <button :disabled="Search.Submitting" class="uk-button uk-button-primary uk-margin-right"
                            @click.prevent="search()">
                            <span v-if="!Search.Submitting">
                                <span v-if="Search.GroupingOptions == 'Invoice'">Cerca
                                    clienti e fatture</span>
                                    <span v-if="Search.GroupingOptions == 'SupplyPoint'">Cerca
                                    clienti e punti di fornitura</span>
                                <span v-if="Search.GroupingOptions == 'Customer'">Cerca
                                    clienti</span>
                                <span v-if="Search.GroupingOptions == 'User'">Cerca utenti</span>
                            </span><span v-if="Search.Submitting" uk-spinner="ratio: 0.7"></span>
                        </button>
                        <button class="uk-button uk-button-default uk-margin-right" @click.prevent="clearSearch()">
                            Cancella filtri
                        </button>
                        <div class="uk-align-right">
                            <button class="uk-button uk-button-primary" uk-toggle="target: #modal-notification-message"
                                :disabled="Search.Submitting || Items.List.length == 0">Imposta messaggio</button>
                            <button class="uk-button uk-button-primary uk-margin-small-left"
                                @click.prevent="sendNotification(false, null)"
                                :disabled="Search.Submitting || Items.List.length == 0 || !NotificationMessageIsReady">Invia
                                notifiche</button>
                        </div>

                    </div>
                </div>

                <div class="uk-margin-top" v-if="Items.List.length == 0 && Items.Loaded">
                    Nessun risultato trovato.
                </div>
                <div v-if="Items.List.length > 0" class="uk-margin-top">

                    <div v-if="Items.List.length > 0" class="uk-margin-top uk-margin-bottom">
                        <span class="uk-margin-right">{{ Items.List[0]["Total"] }} risultati.</span>
                        <span class="uk-margin-right">Pagina: {{ Items.CurrentPage }} di {{ totalPages }}</span>
                        <button class="uk-button uk-button-default" @click.prevent="itemsList(1)"
                            v-if="Items.CurrentPage > 1"><span uk-icon="icon: chevron-double-left;"></span></button>
                        <button class="uk-button uk-button-default" @click.prevent="itemsList(Items.CurrentPage - 1)"
                            v-if="Items.CurrentPage > 1"><span uk-icon="icon: chevron-left;"></span></button>
                        <button class="uk-button uk-button-default" @click.prevent="itemsList(Items.CurrentPage + 1)"
                            v-if="Items.CurrentPage < totalPages"><span uk-icon="icon: chevron-right;"></span></button>
                        <button class="uk-button uk-button-default" @click.prevent="itemsList(totalPages)"
                            v-if="Items.CurrentPage < totalPages"><span
                                uk-icon="icon: chevron-double-right;"></span></button>



                    </div>
                    <div v-for="(item, index) in Items.List" :key="item['invoiceId']"
                        class="uk-margin-top uk-padding-small uk-card  uk-card-default">
                        <div class="uk-card-body">
                            <div class="uk-grid">
                                <div class="uk-width-1-3@m">
                                    <div class="uk-text">Nome utente: {{ item['Surname'] }} </div>
                                    <div class="uk-text">Cognome utente: {{ item['Name'] }} </div>
                                    <div class="uk-text">Email utente: {{ item['Email'] }} </div>
                                    <div v-if="Results.GroupingOptions == 'Invoice' || Results.GroupingOptions == 'SupplyPoint' || Results.GroupingOptions == 'Customer'"
                                        class="uk-text">Cliente: {{ item['CustomerName'] }} </div>
                                    <div v-if="Results.GroupingOptions == 'Invoice' || Results.GroupingOptions == 'SupplyPoint' || Results.GroupingOptions == 'Customer'"
                                        class="uk-text">Cod. cliente: {{ item['CustomerCode'] }} </div>
                                    <div v-if="Results.GroupingOptions == 'Invoice' || Results.GroupingOptions == 'SupplyPoint' || Results.GroupingOptions == 'Customer'"
                                        class="uk-text">Fornitura cliente: {{ item['CustomerSupplyTypeCode'] }} </div>
                                    <div v-if="Results.GroupingOptions == 'Invoice' || Results.GroupingOptions == 'SupplyPoint'"
                                        class="uk-text">Punto di fornitura: {{ item['SupplyPoint'] }} </div>

                                </div>
                                <div class="uk-width-1-3@m">
                                    <div v-if="Results.GroupingOptions == 'Invoice'" class="uk-text">Numero fattura: {{
                                        item['InvoiceNumber'] }} ({{
        item['SupplyTypeCode'] }})</div>
                                    <div class="uk-text" v-if="Results.GroupingOptions == 'Invoice'">Mod. pag.: {{
                                        item['MasterContractPaymentTypeCode'] }}
                                    </div>
                                    <div class="uk-text" v-if="Results.GroupingOptions == 'Invoice'">Stato pag.:
                                        <span v-if="item['PaymentStatus'] == ''">Non impostato</span>
                                        <span v-if="item['PaymentStatus'] == 'PAID'">Pagato</span>
                                        <span v-if="item['PaymentStatus'] == 'NOTPAID'">Non pagato</span>
                                        <span v-if="item['PaymentStatus'] == 'PENDING'">Pending</span>
                                        <span v-if="item['PaymentStatus'] == 'COMPENS'">Compensazione</span>
                                        <span v-if="item['PaymentStatus'] == 'PARTIAL'">Pagamento parziale</span>
                                    </div>

                                </div>
                                <div class="uk-width-1-3@m">
                                    <div v-if="Results.GroupingOptions == 'Invoice'" class="uk-text">Totale fattura: {{
                                        item['TotalValue'] }} </div>
                                    <div v-if="Results.GroupingOptions == 'Invoice'" class="uk-text">Data fattura: {{
                                        item['InvoiceDate'] }} </div>
                                    <div v-if="Results.GroupingOptions == 'Invoice'" class="uk-text">Scadenza fattura: {{
                                        item['ExpirationDate'] }} </div>
                                </div>
                            </div>
                        </div>
                        <div class="uk-card-footer">
                            <a class="uk-align-right@m uk-margin-remove-bottom"
                                @click.prevent="sendNotification(true, index)"
                                :class="{ 'a-disabled': Search.Submitting || Items.List.length == 0 || !NotificationMessageIsReady }">Invia
                                notifica</a>

                        </div>

                    </div>
                </div>
                <div v-if="Items.List.length > 0" class="uk-margin-top uk-margin-bottom">
                    <span class="uk-margin-right">Pagina: {{ Items.CurrentPage }} di {{ totalPages }}</span>
                    <button class="uk-button uk-button-default" @click.prevent="itemsList(1)"
                        v-if="Items.CurrentPage > 1"><span uk-icon="icon: chevron-double-left;"></span></button>
                    <button class="uk-button uk-button-default" @click.prevent="itemsList(Items.CurrentPage - 1)"
                        v-if="Items.CurrentPage > 1"><span uk-icon="icon: chevron-left;"></span></button>
                    <button class="uk-button uk-button-default" @click.prevent="itemsList(Items.CurrentPage + 1)"
                        v-if="Items.CurrentPage < totalPages"><span uk-icon="icon: chevron-right;"></span></button>
                    <button class="uk-button uk-button-default" @click.prevent="itemsList(totalPages)"
                        v-if="Items.CurrentPage < totalPages"><span uk-icon="icon: chevron-double-right;"></span></button>

                </div>
            </section>
        </div>
    </section>

    <section>
        <!--modals-->
        <div id="modal-notification-message" uk-modal>
            <div class="uk-modal-dialog uk-modal-body uk-width-auto">
                <h4 class="">Impostazioni messaggio</h4>
                <div class="uk-padding-small">
                    <span class="uk-badge uk-margin-right" @click="typeInNotificationMessageTextarea('[NOME_UTENTE]')">Nome
                        utente</span>
                    <span @click="typeInNotificationMessageTextarea('[NOME_CLIENTE]')"
                        :class="{ 'badge-disabled': (Results.GroupingOptions == 'User') }"
                        class="uk-badge uk-margin-right">Cliente /
                        Ragione sociale</span>
                    <span @click="typeInNotificationMessageTextarea('[CODICE_CLIENTE]')"
                        :class="{ 'badge-disabled': (Results.GroupingOptions == 'User') }"
                        class="uk-badge uk-margin-right">Codice
                        cliente</span>
                    <span @click="typeInNotificationMessageTextarea('[TIPO_FORNITURA]')"
                        title="Possibili diciture: 'energia elettrica + gas', 'energia elettrica','gas'"
                        :class="{ 'badge-disabled': (Results.GroupingOptions == 'User') }"
                        class="uk-badge uk-margin-right">Tipo fornitura</span>
                    <span @click="typeInNotificationMessageTextarea('[PUNTO_FORNITURA]')"
                        title="Numero POD o PDR"
                        :class="{ 'badge-disabled': (Results.GroupingOptions == 'User') }"
                        class="uk-badge uk-margin-right">Punto fornitura</span>
                    <span @click.prevent="typeInNotificationMessageTextarea('[NUMERO_FATTURA]')"
                        :class="{ 'badge-disabled': (Results.GroupingOptions == 'User' || Results.GroupingOptions == 'SupplyPoint' || Results.GroupingOptions == 'Customer') }"
                        class="uk-badge uk-margin-right">Numero
                        fattura</span>
                    <span @click.prevent="typeInNotificationMessageTextarea('[DATA_FATTURA]')"
                        :class="{ 'badge-disabled': (Results.GroupingOptions == 'User' || Results.GroupingOptions == 'SupplyPoint' || Results.GroupingOptions == 'Customer') }"
                        class="uk-badge uk-margin-right">Data
                        fattura</span>
                    <span @click.prevent="typeInNotificationMessageTextarea('[SCADENZA_FATTURA]')"
                        :class="{ 'badge-disabled': (Results.GroupingOptions == 'User' || Results.GroupingOptions == 'SupplyPoint' || Results.GroupingOptions == 'Customer') }"
                        class="uk-badge uk-margin-right">Scadenza
                        fattura</span>
                    <span @click.prevent="typeInNotificationMessageTextarea('[IMPORTO_FATTURA]')"
                        title="Esempio formato importo: 28,97 €"
                        :class="{ 'badge-disabled': (Results.GroupingOptions == 'User' || Results.GroupingOptions == 'SupplyPoint' || Results.GroupingOptions == 'Customer') }"
                        class="uk-badge uk-margin-right">Importo
                        fattura</span>
                    <span @click.prevent="typeInNotificationMessageTextarea('[MOD_PAGAMENTO]')"
                        title="Possibili diciture: bollettino postale, RID, bonifico"
                        :class="{ 'badge-disabled': (Results.GroupingOptions == 'User' || Results.GroupingOptions == 'SupplyPoint' || Results.GroupingOptions == 'Customer') }"
                        class="uk-badge uk-margin-right">Modalità
                        pagamento</span>

                </div>
                <div>
                    <input type="text" class="uk-input uk-margin-bottom uk-form-width-large" v-model="NotificationTitle"
                        placeholder="Titolo (solo per invio tramite email)">
                </div>
                <textarea ref="notificationMessageTextarea" class="uk-textarea" rows=8 id="modal-message-textarea"
                    v-model="NotificationMessage"
                    placeholder="Inserisci il testo della notifica. Utilizza i placeholder per inserire contenuti dinamici."></textarea>
                    <div class="js-upload" uk-form-custom>
                        <a v-if="NotificationMessageAttachmentSelected" 
                        @click.prevent = "deleteNotificationMessageAttachment"
                        class="" type="button"><i uk-icon="trash"></i>Rimuovi allegato</a>
            
            <input v-if="!NotificationMessageAttachmentSelected" type="file" @change="handleFileUpload($event)">
            <button v-if="!NotificationMessageAttachmentSelected" class="uk-button uk-button-primary" type="button" tabindex="-1">Allega file...</button>
            <span class="uk-margin-left" v-if="NotificationMessageAttachment != null">{{ NotificationMessageAttachment["name"] }} 
                            </span>
        </div>
                    <div class="uk-margin-top">
                    Modalità di invio:<br>
                    <div uk-grid>
                        <div class="uk-width-1-2@m">
                            <label><input type="checkbox" class="uk-checkbox" value="Push" v-model="NotificationSendMethod">
                                Notifica push</label><br>
                            <label><input type="checkbox" class="uk-checkbox" value="SMS_AWS"
                                    v-model="NotificationSendMethod">
                                SMS (via AWS)</label><br>
                            <label><input type="checkbox" class="uk-checkbox" value="PlainTextEmail_AWS"
                                    @change.prevent="removeSendMethod('TemplateEmail_AWS')"
                                    v-model="NotificationSendMethod"> Email plain text (via AWS)</label><br>
                            <label><input type="checkbox" class="uk-checkbox" value="TemplateEmail_AWS"
                                    @change.prevent="removeSendMethod('PlainTextEmail_AWS')"
                                    v-model="NotificationSendMethod"> Email con template (via AWS)</label>
                        </div>
                        <div class="uk-width-1-2@m">
                            <label><input type="checkbox" class="uk-checkbox" value="PlainTextEmail_BREVO"
                                    @change.prevent="removeSendMethod('TemplateEmail_BREVO')"
                                    v-model="NotificationSendMethod"> Email plain text (via Brevo)</label><br>
                            <label><input type="checkbox" class="uk-checkbox" value="TemplateEmail_BREVO"
                                    @change.prevent="removeSendMethod('PlainTextEmail_BREVO')"
                                    v-model="NotificationSendMethod"> Email con template (via Brevo)</label><br>
                        </div>
                    </div>



                </div>
                <p class="uk-text-right">
                    <button class="uk-button uk-button-default uk-modal-close" type="button">Annulla</button>
                    <button class="uk-button uk-button-primary uk-modal-close" type="button"
                        :disabled="NotificationMessage == '' || NotificationSendMethod.length == 0"
                        @click.prevent="notificationSetMessageReady">Fatto</button>
                </p>
            </div>
        </div>
    </section>
</template>
  
<style scoped>
.bg-box {
    border: solid 1px #cccccc;
}

#modal-notification-message .uk-badge {
    cursor: pointer;
}

#modal-notification-message .uk-badge.badge-disabled {
    background-color: #cccccc;
    pointer-events: none;
    cursor: default;
}

.a-disabled {
    color: #cccccc;
    pointer-events: none;
    cursor: default;
}
</style>
  
<script lang="ts">
const axios = require("axios").default;
import commonFunctions from "../../functions/common.js"; // eslint-disable-line no-unused-vars
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import UIkit from 'uikit'

export default {
    name: "NotificationsList",
    components: { Datepicker },
    computed: {
        token() {
            return this.$store.state.token;
        },
        email() {
            return this.$store.state.email;
        },
        isAdmin() {
            return this.$store.state.admin;
        },
        grants() {
            return this.$store.state.grants;
        },
        notifications() {
            return this.getGrants("notifications")
        },
        totalPages() {
            var tot = Math.floor(this.Items.Total / 25);
            if (tot * 25 < this.Items.Total) tot += 1
            return tot
        }
    },

    data() {
        return {

            APIHost: process.env.VUE_APP_APIHOST,
            Items: {
                List: [],
                CurrentPage: 0,
                Loaded: false,
                Total: 0
            },
            PaymentMethods: [
                { value: 'BLP', label: 'Bollettino postale' },
                { value: 'RID', label: 'RID' },
                { value: 'BON', label: 'Bonifico' }
            ],
            Search: {
                Email: "",
                CustomerCode: "",
                CustomerTypeCode: 0,
                CustomerStatus: 0,
                InvoiceExpirationDateFrom: "",
                InvoiceExpirationDateTo: "",
                InvoiceDateFrom: "",
                InvoiceDateTo: "",
                SectionalVendorCode: "",
                PaymentStatus: "",
                PaymentTypeCode: ["BLP","RID","BON"],
                SupplyTypeCode: "",
                GroupingOptions: "Invoice",
                Submitting: false
            },
            Results: {
                Email: "",
                CustomerCode: "",
                CustomerTypeCode: 0,
                CustomerStatus: 0,
                InvoiceExpirationDateFrom: "",
                InvoiceExpirationDateTo: "",
                InvoiceDateFrom: "",
                InvoiceDateTo: "",
                SectionalVendorCode: "",
                PaymentStatus: "",
                PaymentTypeCode: ["BLP","RID","BON"],
                SupplyTypeCode: "",
                GroupingOptions: ""
            },
            PaymentStatus: [
                { value: '', label: 'Tutti gli stati di pagamento' },
                { value: 'PAID', label: 'Pagato' },
                { value: 'PENDING', label: 'Pending' },
                { value: 'NOTPAID', label: 'Non pagato' },
                { value: 'COMPENS', label: 'Compensazione' },
                { value: 'PARTIAL', label: 'Pag. parziale' },
            ],
            PaymentStatusSelect: [
                { value: '', label: 'Seleziona...' },
                { value: 'PAID', label: 'Pagato' },
                { value: 'PENDING', label: 'Pending' },
                { value: 'NOTPAID', label: 'Non pagato' },
                { value: 'COMPENS', label: 'Compensazione' },
                { value: 'PARTIAL', label: 'Pag. parziale' },
            ],
            CustomerTypeCodes: [
                { value: 0, label: 'Attivi o prospect' },
                { value: 1, label: 'Solo attivi' },
                { value: 2, label: 'Solo prospect' },
            ],
            CustomerStatusCodes: [
                { value: 0, label: 'In fornitura o cessati' },
                { value: 1, label: 'Solo in fornitura' },
                { value: 2, label: 'Solo cessati' },
            ],
            NotificationMessage: "",
            NotificationTitle: "",
            NotificationSendMethod: [],
            NotificationMessageIsReady: false,
            NotificationMessageAttachment: null,
            NotificationMessageAttachmentSelected: false,

        };
    },
    methods: {
        getGrants(k) {
            var r = {
                canManage: false,
                label: ""
            }
            for (var x = 0; x < this.grants.length; x++) {
                if (this.grants[x].ModuleKeyID == k) {
                    r.canManage = this.grants[x].CanManage;
                    r.label = this.grants[x].ModuleLabel;
                }
            }
            return r;
        },
        errorNotification(msg) {
            UIkit.notification({// eslint-disable-line no-undef
                message: msg == null ? "Si è verificato un errore." : msg,
                status: "danger",
                pos: "top-center",
                timeout: 4000,
            });
        },
        successNotification(msg) {
            UIkit.notification({// eslint-disable-line no-undef
                message: msg == null ? "Operazione eseguita con successo." : msg,
                status: "success",
                pos: "top-center",
                timeout: 4000,
            });
        },
        search() {
            this.Search.Submitting = true
            this.itemsList(1);
        },
        clearSearch() {
            this.Search.CustomerCode = ""
            this.Search.CustomerTypeCode = 0
            this.Search.CustomerStatus = 0
            this.Search.InvoiceExpirationDateFrom = ""
            this.Search.InvoiceExpirationDateTo = ""
            this.Search.InvoiceDateFrom = ""
            this.Search.InvoiceDateTo = ""
            this.Search.SectionalVendorCode = ""
            this.Search.PaymentStatus = ""
            this.Search.PaymentTypeCode = ""
            this.Search.SupplyTypeCode = ""
            this.Search.GroupingOptions = ""

        },
        changePaymentStatus() {
            if (this.Search.PaymentStatus != '') {
                //
            }
        },
        removeSendMethod(key) {
            for (let x = 0; x < this.NotificationSendMethod.length; x++) {
                if (this.NotificationSendMethod[x] == key) {
                    this.NotificationSendMethod.splice(x, 1);
                }
            }
        },
        typeInNotificationMessageTextarea(newText) {
            let textarea = this.$refs.notificationMessageTextarea
            let start = textarea.selectionStart
            let end = textarea.selectionEnd
            let text = textarea.value
            let before = text.substring(0, start)
            let after = text.substring(end, text.length)
            this.NotificationMessage = (before + newText + after)
            this.$nextTick(() => {
                textarea.focus()
            });
        },
        handleFileUpload(event) {
            if (event.target.files[0].size > (5*1024*1024)){
                this.errorNotification("La dimensione dell'allegato non può superare i 5 MB")
            }else{
                this.NotificationMessageAttachment = event.target.files[0];
                this.NotificationMessageAttachmentSelected = true;
            }
        },
        deleteNotificationMessageAttachment(){
            this.NotificationMessageAttachment = null;
            this.NotificationMessageAttachmentSelected = false;
        },
        notificationSetMessageReady() {
            this.NotificationMessageIsReady = true
        },
        showNotificationMessageModal() {
            UIkit.modal("#modal-notification-message").show()
        },
        itemsList(pageNumber) {
            let paymentTypeCode =this.Search.PaymentTypeCode.join(',');
            if (paymentTypeCode == 'BLP,RID,BON'){
                paymentTypeCode = ''
            }
            axios({
                method: "get",
                url: this.APIHost + "/api/v1/notifications",
                params: {
                    UserEmail: this.Search.Email,
                    CustomerCode: this.Search.CustomerCode,
                    CustomerTypeCode: this.Search.CustomerTypeCode,
                    CustomerStatus: this.Search.CustomerStatus,
                    InvoiceExpirationDateFrom: this.Search.InvoiceExpirationDateFrom,
                    InvoiceExpirationDateTo: this.Search.InvoiceExpirationDateTo,
                    InvoiceDateFrom: this.Search.InvoiceDateFrom,
                    InvoiceDateTo: this.Search.InvoiceDateTo,
                    SectionalVendorCode: this.Search.SectionalVendorCode,
                    PaymentStatus: this.Search.PaymentStatus,
                    PaymentTypeCode: paymentTypeCode,
                    SupplyTypeCode: this.Search.SupplyTypeCode,
                    GroupingOptions: this.Search.GroupingOptions,
                    PageNumber: pageNumber,
                },
                headers: {
                    Token: this.token
                }
            })
                .then(
                    function (response) {// eslint-disable-line no-unused-vars
                        this.Items.List = [];
                        var x;
                        for (x = 0; x < response.data.length; x++) {
                            this.Items.List.push(response.data[x]);
                        }
                        this.Items.CurrentPage = pageNumber;
                        this.Items.Total = this.Items.List.length > 0 ? parseInt(this.Items.List[0]["Total"]) : 0;
                        this.Items.Loaded = true;
                        this.Search.Submitting = false
                        this.Results = Object.assign({}, this.Search)
                        window.scrollTo({ top: 300, left: 0, behavior: 'smooth' })
                    }.bind(this)
                )
                .catch(function (error) {// eslint-disable-line no-unused-vars
                    this.errorNotification();
                    this.Search.Submitting = false
                }.bind(this));
        },
        sendNotification(test, index) { // eslint-disable-line no-unused-vars

            axios({
                method: "post",
                url: this.APIHost + "/api/v1/notifications",
                params: {
                    SendMethod: JSON.stringify(this.NotificationSendMethod),
                    Test: test,
                    UserEmail: this.Results.Email,
                    CustomerCode: this.Results.CustomerCode,
                    CustomerTypeCode: this.Results.CustomerTypeCode,
                    CustomerStatus: this.Results.CustomerStatus,
                    InvoiceExpirationDateFrom: this.Results.InvoiceExpirationDateFrom,
                    InvoiceExpirationDateTo: this.Results.InvoiceExpirationDateTo,
                    InvoiceDateFrom: this.Results.InvoiceDateFrom,
                    InvoiceDateTo: this.Results.InvoiceDateTo,
                    SectionalVendorCode: this.Results.SectionalVendorCode,
                    PaymentStatus: this.Results.PaymentStatus,
                    PaymentTypeCode: this.Results.PaymentTypeCode.join(','),
                    SupplyTypeCode: this.Results.SupplyTypeCode,
                    GroupingOptions: this.Results.GroupingOptions

                },
                headers: {
                    Token: this.token,
                    'Content-Type': 'application/octet-stream',
                    'FileName': this.NotificationMessageAttachmentSelected ? this.NotificationMessageAttachment.name : "",
                    'TestRow' : test ? encodeURIComponent(JSON.stringify(this.Items.List[index]))  : "",
                    'Title': encodeURIComponent(this.NotificationTitle),
                    'Message': encodeURIComponent(this.NotificationMessage)
                },
                data: this.NotificationMessageAttachmentSelected ? this.NotificationMessageAttachment : null

            })
                .then(
                    function (response) {// eslint-disable-line no-unused-vars
                        if (test){
                            this.successNotification()
                        }else{
                            this.successNotification("Procedura di invio notifiche avviata. Al termine della procedura verrà inviata una email di riepilogo.")
                        }
                        
                    }.bind(this)
                )
                .catch(function (error) {// eslint-disable-line no-unused-vars
                    console.log(error)
                    this.errorNotification();

                }.bind(this));
        },


    },
    mounted() {

    },
    updated() {

    }
};
</script>
  
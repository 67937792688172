<template>
  <section
    v-if="
      (subsection == '' || subsection == 'changePassword1')
    "
  >
    <div class="uk-grid">
      <div class="uk-width-large uk-align-center">
        <form v-on:submit.prevent="gotoChangePassword2()">
          <div class="uk-card uk-card-default uk-card-body">
            <div class="uk-card-title">
              <h3>Reset password</h3>
            </div>
            <div class="uk-form">
              <div class="uk-form-row uk-margin-top">
                <input
                  class="uk-input"
                  placeholder="Email"
                  v-model="loginEmail"
                  v-on:keyup="changePassword1Validate()"
                />
              </div>
              <div class="uk-form-row uk-margin-top">
                <button
                  class="uk-button uk-button-primary"
                  type="submit"
                  v-bind:disabled="!changePassword1Validate()"
                >
                  Continua
                </button>
                <p>Inserisci il tuo indirizzo email per continuare.</p>
                <div v-if="!isLoggedIn">
                <hr />
                <router-link to="/login">Torna al login</router-link>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </section>

  <section
    v-if="subsection == 'changePassword2'"
  >
    <div class="uk-grid">
      <div class="uk-width-large uk-align-center">
        <form v-on:submit.prevent="verifyOTP()">
          <div class="uk-card uk-card-default uk-card-body">
            <div class="uk-card-title">
              <h3>Codice di verifica</h3>
            </div>
            <div class="uk-form">
              <div class="uk-form-row uk-margin-top">
                <input
                  class="uk-input"
                  placeholder="Codice di verifica"
                  v-model="loginOTP"
                  v-on:keyup="changePassword2Validate()"
                />
              </div>
              <div class="uk-form-row uk-margin-top">
                <button
                  class="uk-button uk-button-primary"
                  type="submit"
                  v-bind:disabled="!changePassword2Validate()"
                >
                  Verifica codice
                </button>
                <p>
                  Un messaggio con un codice di verifica è stato inviato al tuo
                  indirizzo email. <br />
                  Inserisci il codice per continuare.
                  <br /><br />
                  <a href="javascript:;" v-on:click="gotoChangePassword1()"
                    >Non hai ricevuto il codice di verifica?</a
                  >
                </p>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </section>

  <section
    v-if="subsection == 'changePassword3'"
  >
    <div class="uk-grid">
      <div class="uk-width-large uk-align-center">
        <form v-on:submit.prevent="changePassword()">
          <div class="uk-card uk-card-default uk-card-body">
            <div class="uk-card-title">
              <h3>Reset password</h3>
            </div>
            <div class="uk-form">
              <div class="uk-form-row uk-margin-top">
                <input
                  class="uk-input"
                  placeholder="Nuova password"
                  type="password"
                  v-model="newPassword"
                  v-on:keyup="changePassword3Validate()"
                />
              </div>
              <div class="uk-form-row uk-margin-top">
                <button
                  class="uk-button uk-button-primary"
                  type="submit"
                  v-bind:disabled="!changePassword3Validate()"
                >
                  Continua
                </button>
                <p>
                  Inserisci la nuova password per finalizzare la procedura di
                  ripristino.<br />
                  La password deve avere una lunghezza da 8 a 20 caratteri,
                  contenere numeri e lettere, sia maiuscole che minuscole, ed almeno un carattere speciale.
                </p>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </section>

  <section
    v-if="subsection == 'changePassword4'"
  >
    <div class="uk-grid">
      <div class="uk-width-large uk-align-center">
        <div class="uk-card uk-card-default uk-card-body">
          <div class="uk-card-title">
            <h3>Password modificata</h3>
          </div>
          <div class="uk-card-body">
            <p>
              La password è stata modificata con successo.
              <br />
              <br />
              <router-link to="/login">Torna al login</router-link>
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import commonFunctions from "../../functions/common.js"; // eslint-disable-line no-unused-vars
import UIkit from 'uikit'
const axios = require("axios").default;
export default {
  name: "ChangePassword",
  data() {
    return {
      loginEmail: "",
      loginPassword: "",
      loginOTP: "",
      newPassword: "",
      APIHost: process.env.VUE_APP_APIHOST
    };
  },
  computed: {
    isLoggedIn() {
      return this.$store.state.isLoggedIn;
    },
    section() {
      return this.$store.state.section;
    },
    subsection() {
      return this.$store.state.subsection;
    },
  },
  mounted() {
    this.loginEmail = "",
    this.loginPassword = "",
    this.loginOTP = "",
    this.newPassword = ""
    this.gotoChangePassword1()
  },
  methods: {
    changePassword1Validate() {
      if (
        commonFunctions.isNullOrWhiteSpace(this.loginEmail) ||
        !commonFunctions.isEmail(this.loginEmail)
      ) {
        return false;
      } else {
        return true;
      }
    },
    changePassword2Validate() {
      if (this.loginOTP.length != 6) {
        return false;
      } else {
        return true;
      }
    },
    changePassword3Validate() {
      if (
        commonFunctions.isNullOrWhiteSpace(this.newPassword) ||
        !commonFunctions.isValidPassword(this.newPassword)
      ) {
        return false;
      } else {
        return true;
      }
    },
    gotoChangePassword1() {
      var payload = {
        section: "changePassword",
        subsection: "changePassword1",
      };
      this.$store.commit("changeSection", payload);
    },
    gotoChangePassword2() {
      axios({
        method: "get",
        url: this.APIHost + "/api/v1/users-recover-password",
        params: {
          Email: this.loginEmail,
        },
      })
        .then(
          function (response) { // eslint-disable-line no-unused-vars
            // handle success
            var payload = {
              section: "changePassword",
              subsection: "changePassword2",
            };
            this.$store.commit("changeSection", payload);
          }.bind(this)
        )
        .catch(function (error) {
          // handle error
          console.log(error);
          UIkit.notification({            // eslint-disable-line no-undef
            message:
              "Si è verificato un errore.",
            status: "danger",
            pos: "top-center",
            timeout: 4000,
          });
        })
        .then(function () {
          // always executed
        });
    },
    verifyOTP() {
      axios({
        method: "post",
        url: this.APIHost + "/api/v1/users-verify-otp",
        data: {
          Email: this.loginEmail,
          OTP: this.loginOTP,
        },
      })
        .then(
          function (response) {// eslint-disable-line no-unused-vars
            // handle success
            var payload = {
              section: "changePassword",
              subsection: "changePassword3",
            };
            this.$store.commit("changeSection", payload);
          }.bind(this)
        )
        .catch(function (error) {
          // handle error
          console.log(error);
          UIkit.notification({            // eslint-disable-line no-undef
            message:
              "Si è verificato un errore. <br />Verificare dati inseriti.",
            status: "danger",
            pos: "top-center",
            timeout: 4000,
          });
        })
        .then(function () {
          // always executed
        });
    },
    changePassword() {
      axios({
        method: "post",
        url: this.APIHost + "/api/v1/users-reset-password",
        data: {
          Email: this.loginEmail,
          OTP: this.loginOTP,
          Password: this.newPassword,
        },
      })
        .then(
          function (response) {// eslint-disable-line no-unused-vars
            // handle success

            UIkit.notification({// eslint-disable-line no-undef
              message: "La password è stata modificata con successo.",
              status: "success",
              pos: "top-center",
              timeout: 4000,
            });
            this.$router.push({ path: '/login' });
          }.bind(this)
        )
        .catch(function (error) {
          // handle error
          console.log(error);
          UIkit.notification({            // eslint-disable-line no-undef
            message:
              "Si è verificato un errore. <br />Non è stato possibile modificare la password.",
            status: "danger",
            pos: "top-center",
            timeout: 4000,
          });
        })
        .then(function () {
          // always executed
        });
    },
  },
};
</script>
<template>
  <div class="uk-align-right uk-margin-remove-bottom">
    
    <div>
      <button
        class="uk-button uk-button-text"
        type="button"
      >
        <span uk-icon="user"></span>
      </button>
      <div uk-dropdown="mode: click; animation: uk-animation-slide-top-small; duration: 1000; pos: bottom-right">
        <ul class="uk-nav uk-dropdown-nav">
          <li v-if="isLoggedIn">
            <router-link to="/">Dashboard</router-link>
          </li>
          <li v-if="!isLoggedIn">
            <router-link to="/login">Login</router-link>
          </li>
          <li>
            <router-link to="/change-password">Reset password</router-link>
          </li>
          <li v-if="isLoggedIn">
            <router-link to="/change-email">Cambia email</router-link>
          </li>
          <li v-if="isLoggedIn" class="uk-nav-divider"></li>
          <li v-if="isLoggedIn"><a
              href=""
              v-on:click.prevent="logout()"
            >Logout</a></li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
const axios = require("axios").default;
import UIkit from 'uikit'
export default {
  name: "LoginLink",
  data() {
    return {
      APIHost: process.env.VUE_APP_APIHOST
    };
  },
  computed: {
    isLoggedIn() {
      return this.$store.state.isLoggedIn;
    },
  },
  methods: {
    logout() {
      this.$store.commit("logout");
      axios({
        method: "get",
        url: this.APIHost + "/api/v1/delete-token"
      }).then(
          function (response) {// eslint-disable-line no-unused-vars
            this.$router.push({ path: '/login' });
          }.bind(this)
        )
        .catch(function (error) {
          // handle error
          console.log(error);
          UIkit.notification({// eslint-disable-line no-undef
            message: "Si è verificato un errore.",
            status: "danger",
            pos: "top-center",
            timeout: 4000,
          });
        }
        );
    },
  },
};
</script>



import { createWebHistory, createRouter } from "vue-router";
import Home from "@/Views/Home.vue";
import Dashboard from "@/components/Body/Dashboard.vue";
import Login from "@/components/Body/Login.vue";
import ChangeEmail from "@/components/Body/ChangeEmail.vue";
import ChangePassword from "@/components/Body/ChangePassword.vue";
import UserActivation from "@/Views/UserActivation.vue";
import ChangeEmailVerification from "@/Views/ChangeEmailVerification.vue";
import ManageUsersList from "@/components/Body/ManageUsers_List.vue";
import ManageUsersGrants from "@/components/Body/ManageUsers_User_Grants.vue";
import ManageUsersNewUser from "@/components/Body/ManageUsers_User_New.vue";
import ApiUsersList from "@/components/Body/ApiUsers.vue";
import SelfReadingsMenu from "@/components/Body/SelfReadings_Menu.vue";
import SelfReadingsInsert from "@/components/Body/SelfReadings_Insert.vue";
import SelfReadingsExport from "@/components/Body/SelfReadings_Export.vue";
import SelfReadingsTimeWindows from "@/components/Body/SelfReadings_TimeWindows.vue";
import SelfReadingsSettings from "@/components/Body/SelfReadings_Settings.vue";
import BillbooksInsert from "@/components/Body/Billbooks_Insert.vue";
import BillbooksList from "@/components/Body/Billbooks_List.vue";
import NexiReportList from "@/components/Body/NexiReport_List.vue";
import NotificationsList from "@/components/Body/Notifications_List.vue";
import SynopticSheetUpload from "@/components/Body/SynopticSheet_Upload.vue";
import DistributorsPDRSheetUpload from "@/components/Body/DistributorsPDRSheet_Upload.vue";
import SellerContractsSheetUpload1 from "@/components/Body/SellerContractsSheet_Upload_Step1.vue";
import SellerContractsSheetUpload2 from "@/components/Body/SellerContractsSheet_Upload_Step2.vue";
import SynopticSheetGet from "@/components/Body/SynopticSheet_Get.vue";
import ChatAgent from "@/components/Body/ChatAgent.vue";
import BillbooksTransform_Insert from "@/components/Body/BillbooksTransform_Insert.vue";
import Products from "@/components/Body/Products.vue";
import CustomNotificationsList from "@/components/Body/CustomNotifications_List.vue";
import PhoneExchangeSettings from "@/components/Body/PhoneExchange_Settings.vue";
import PhoneExchangeExportCustomerSatisfaction from "@/components/Body/PhoneExchange_ExportCustomerSatisfaction.vue";
import SMSGatewayList from "@/components/Body/SMSGateway_List.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    children: [
      {
        path: '',
        component: Dashboard
      }
    ]
  },
  {
    path: "/login",
    name: "Login",
    component: Home,
    children: [
      {
        component: Login,
        path: '',
      }
    ]

  },
  {
    path: "/change-email",
    name: "ChangeEmail",
    component: Home,
    children: [
      {
        component: ChangeEmail,
        path: '',
      }
    ]
  },
  {
    path: "/change-password",
    name: "ChangePassword",
    component: Home,
    children: [
      {
        component: ChangePassword,
        path: '',
      }
    ]
  },  
  {
    path: "/synoptic-sheet/upload",
    name: "SynopticSheetUpload",
    component: Home,
    children: [
      {
        component: SynopticSheetUpload,
        path: '',
      }
    ]
  },
  {
    path: "/synoptic-sheet/get",
    name: "SynopticSheetGet",
    component: Home,
    children: [
      {
        component: SynopticSheetGet,
        path: '',
      }
    ]
  },
  {
    path: "/products",
    name: "Products",
    component: Home,
    children: [
      {
        component: Products,
        path: '',
      }
    ]
  }, 
  {
    path: "/distributors-pdr-sheet/upload",
    name: "DistributorsPDRSheetUpload",
    component: Home,
    children: [
      {
        component: DistributorsPDRSheetUpload,
        path: '',
      }
    ]
  },  
  {
    path: "/seller-contracts-sheet/upload/1",
    name: "SellerContractsSheetUpload1",
    component: Home,
    children: [
      {
        component: SellerContractsSheetUpload1,
        path: '',
      }
    ]
  },
  {
    path: "/seller-contracts-sheet/upload/2",
    name: "SellerContractsSheetUpload2",
    component: Home,
    children: [
      {
        component: SellerContractsSheetUpload2,
        path: '',
      }
    ]
  }, 
  {
    path: "/chat/enter",
    name: "ChatAgent",
    component: Home,
    children: [
      {
        component: ChatAgent,
        path: '',
      }
    ]
  }, 
  {
    path: "/app-users",
    name: "ApiUsersList",
    component: Home,
    children: [
      {
        component: ApiUsersList,
        path: '',
      }
    ]
  },
  {
    path: "/self-readings",
    name: "SelfReadingsMenu",
    component: Home,
    children: [
      {
        component: SelfReadingsMenu,
        path: '',
      }
    ]
  },
  {
    path: "/self-readings-insert",
    name: "SelfReadingsInsert",
    component: Home,
    children: [
      {
        component: SelfReadingsInsert,
        path: '',
      }
    ]
  },
  {
    path: "/self-readings-export",
    name: "SelfReadingsExport",
    component: Home,
    children: [
      {
        component: SelfReadingsExport,
        path: '',
      }
    ]
  },
  {
    path: "/self-readings-time-windows",
    name: "SelfReadingsTimeWindows",
    component: Home,
    children: [
      {
        component: SelfReadingsTimeWindows,
        path: '',
      }
    ]
  },
  {
    path: "/self-readings-settings",
    name: "SelfReadingsSettings",
    component: Home,
    children: [
      {
        component: SelfReadingsSettings,
        path: '',
      }
    ]
  },
  {
    path: "/billbooks-insert",
    name: "BillbooksInsert",
    component: Home,
    children: [
      {
        component: BillbooksInsert,
        path: '',
      }
    ]
  },
  {
    path: "/billbooks-transform",
    name: "BillbooksTransformInsert",
    component: Home,
    children: [
      {
        component: BillbooksTransform_Insert,
        path: '',
      }
    ]
  },
  {
    path: "/billbooks-list",
    name: "BillbooksList",
    component: Home,
    children: [
      {
        component: BillbooksList,
        path: '',
      }
    ]
  },
  {
    path: "/nexi-report-list",
    name: "NexiReportList",
    component: Home,
    children: [
      {
        component: NexiReportList,
        path: '',
      }
    ]
  },  
  {
    path: "/notifications-list",
    name: "NotificationsList",
    component: Home,
    children: [
      {
        component: NotificationsList,
        path: '',
      }
    ]
  },
  {
    path: "/bo-users/list",
    name: "ManageUsersList",
    component: Home,
    children: [
      {
        component: ManageUsersList,
        path: '',
      }
    ]
  },
  {
    path: "/bo-users/new-user",
    name: "ManageUsersNewUser",
    component: Home,
    children: [
      {
        component: ManageUsersNewUser,
        path: '',
      }
    ]
  },
  {
    path: "/bo-users/grants/:userid",
    meta: { transition: 'slide-left' },
    name: "ManageUsersGrants",
    component: Home,
    children: [
      {
        component: ManageUsersGrants,
        path: '',
      }
    ]
  },
  {
    path: "/custom-notifications-list",
    name: "CustomNotificationsList",
    component: Home,
    children: [
      {
        component: CustomNotificationsList,
        path: '',
      }
    ]
  },
  // {
  //   path: "/manage-users",
  //   name: "ManageUsers",
  //   component: Home,
  //   meta: {
  //     title: 'Luce e Gas Italia | Backoffice'      
  //   }
  // },
  {
    path: "/user-activation/:token",
    name: "UserActivation",
    component: UserActivation,
  },
  {
    path: "/change-email-verification/:token",
    name: "ChangeEmailVerification",
    component: ChangeEmailVerification,
  },
  {
    path: "/phone-exchange/apikey",
    name: "PhoneExchangeSettings",
    component: Home,
    children: [
      {
        path: '',
        component: PhoneExchangeSettings
      }
    ]
  },
  {
    path: "/phone-exchange/export-customer-satisfaction",
    name: "PhoneExchangeExportCustomerSatisfaction",
    component: Home,
    children: [
      {
        path: '',
        component: PhoneExchangeExportCustomerSatisfaction
      }
    ]
  },
  {
    path: "/sms-gateway/list",
    name: "SMSGatewayList",
    component: Home,
    children: [
      {
        path: '',
        component: SMSGatewayList
      }
    ]
  },
  
  
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});



// ... 

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else if (previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta');

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key]);
    });

    // We use this to track which meta tags we create so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '');

    return tag;
  })
    // Add the meta tags to the document head.
    .forEach(tag => document.head.appendChild(tag));

  next();
});



export default router;
<template>
  <div class="uk-width-1-1 uk-grid">
    <div class="uk-width-1-2">
      <img src="../../assets/images/luce-e-gas-official-backoffice.png"/>     
    </div>
    <div class="uk-width-1-2 uk-inline">
      <HeaderMenu />
      <LoginLink />
    </div>
  </div>
</template>


<script>
import LoginLink from "./LoginLink.vue";
import HeaderMenu from "./HeaderMenu.vue";
export default {
  name: "HeaderSection",
  components: { LoginLink, HeaderMenu },
  computed: {
    isLoggedIn() {
      return this.$store.state.isLoggedIn;
    },
  }
};
</script>

<style scoped>
.tagline {
  margin: 0;
  margin-left: 11px;
}
</style>



<template>
  <section>
    <div>
      <section>
        <p class="uk-text-lead">Lista operatori backoffice</p>
        <div uk-margin v-if="isAdmin || manageUsers.CanManage">
          <router-link class="uk-button uk-button-default" to="/bo-users/new-user"><span uk-icon="plus-circle"
              class="uk-margin-right"></span>Aggiungi operatore</router-link>
          <a class="uk-button uk-button-default uk-margin-left@s" @click="usersListRefresh()"><span uk-icon="refresh"
              class="uk-margin-right"></span>Aggiorna</a>
        </div>
        <p v-if="Users.List.length == 0 && Users.Loaded">
          Nessun operatore presente.
        </p>
        <table class="uk-table uk-table-striped uk-table-middle" v-if="Users.List.length > 0">
          <thead class="uk-visible@s">
            <tr>
              <th>Nome operatore<br>Cognome</th>
              <th>Tipo operatore</th>
              <th>Email</th>
              <th>Ultimo login</th>
              <th>Status</th>
              <th v-if="isAdmin || manageUsers.CanManage"></th>
              <th v-if="isAdmin || manageUsers.CanManage"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(user) in Users.List" :key="user.Email">
              <td class="uk-visible@s">{{ user.Name }}<br>{{ user.Surname }}</td>
              <td class="uk-visible@s"><span v-if="user.Admin">Admin</span><span v-if="!user.Admin">Operatore
                  con permessi</span></td>
              <td class="uk-visible@s">{{ user.Email }}</td>
              <td class="uk-visible@s">{{ user.LastLogin }}</td>
              <td class="uk-visible@s">
                <span v-if="user.Active">Attivo</span>
                <span v-if="!user.Active">Non attivo</span>
              </td>
              <td class="uk-visible@s" v-if="isAdmin || manageUsers.CanManage">
                <button v-if="user.Active" class="uk-button uk-button-default"
                  @click="userActivate(user.Email, 0)">Disattiva</button>
                <button v-if="!user.Active" class="uk-button uk-button-default"
                  @click="userActivate(user.Email, 1)">Attiva</button>
              </td>
              <td class="uk-visible@s">
                <button v-if="!user.Admin && user.Active && (isAdmin || manageUsers.CanManage)"
                  class="uk-button uk-button-default" @click="showGrants(user)">Permessi</button>
              </td>
              <td class="uk-hidden@s uk-width-1-1">
                <b>{{ user.Name }} {{ user.Surname }}</b>
                <br />
                <div>
                  Tipologia: <span v-if="user.Admin">Admin</span><span v-if="!user.Admin">Operatore con permessi</span>
                </div>
                <div>
                  Email: {{ user.Email }}
                </div>
                <div>Ultimo login: {{ user.LastLogin }}</div>
                <div>
                  Status:
                  <span v-if="user.Active">Attivo</span>
                  <span v-if="!user.Active">Non attivo</span>
                </div>
                <div v-if="isAdmin || manageUsers.CanManage" class="uk-margin-top">
                  <button v-if="user.Active" class="uk-button uk-button-default"
                    @click="userActivate(user.Email, 0)">Disattiva</button>
                  <button v-if="!user.Active" class="uk-button uk-button-default"
                    @click="userActivate(user.Email, 1)">Attiva</button>
                </div>
                <div class="uk-margin-top">
                  <button v-if="!user.Admin && user.Active && (isAdmin || manageUsers.CanManage)"
                    class="uk-button uk-button-default" @click="showGrants(user)">Permessi</button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <ul class="uk-pagination">
          <li v-for="n in Users.Pages" :key="n"><a v-if="n != Users.CurrentPage" @click.prevent="usersList(n)">{{ n }}</a>
            <label class="uk-active uk-text-bold" v-if="n == Users.CurrentPage">{{ n }}</label>
          </li>
        </ul>
      </section>



    </div>
  </section>
</template>

<style scoped></style>

<script>
const axios = require("axios").default;
import commonFunctions from "../../functions/common.js"; // eslint-disable-line no-unused-vars
import UIkit from 'uikit'
export default {
  name: "ManageUsers_List",
  computed: {
    token() {
      return this.$store.state.token;
    },
    isAdmin() {
      return this.$store.state.admin;
    },
    grants() {
      return this.$store.state.grants;
    },
    manageUsers() {
      return this.getGrants("manageusers")
    }
  },

  data() {
    return {
      APIHost: process.env.VUE_APP_APIHOST,
      ResultsPerPage: 20,
      Users: {
        List: [],
        Pages: 0,
        CurrentPage: 0,
        Loaded: false
      }
    };
  },
  methods: {
    getGrants(k) {
      var r = {
        canManage: false,
        label: ""
      }
      for (var x = 0; x < this.grants.length; x++) {
        if (this.grants[x].ModuleKeyID == k) {
          r.canManage = this.grants[x].CanManage;
          r.label = this.grants[x].ModuleLabel;
        }
      }
      return r;
    },
    showGrants(user) {
      this.$store.commit("setUserDialogInfo", {
        name: user.Name,
        surname: user.Surname,
        email: user.Email
      });
      this.$router.push({ path: '/bo-users/grants/' + user.ID });
    },
    usersListRefresh() {
      this.usersList(this.Users.CurrentPage);
    },
    usersList(pageNumber) {
      axios({
        method: "get",
        url: this.APIHost + "/api/v1/users-list",
        params: {
          PageNumber: pageNumber,
        },
        headers: {
          Token: this.token
        }
      })
        .then(
          function (response) {// eslint-disable-line no-unused-vars
            this.Users.List = [];
            var x;
            for (x = 0; x < response.data.length; x++) {
              this.Users.List.push(response.data[x]);
            }
            this.Users.CurrentPage = pageNumber;
            var pgs = 1;
            if (this.Users.List.length > 0) {
              pgs = Math.trunc(this.Users.List[0].Total / this.ResultsPerPage);
              if (this.Users.List[0].Total % this.ResultsPerPage > 0) { pgs += 1; }
              this.Users.Pages = pgs;
            }
            this.Users.Loaded = true;
          }.bind(this)
        )
        .catch(function (error) {
          // handle error
          console.log(error);
          UIkit.notification({// eslint-disable-line no-undef
            message: "Si è verificato un errore.",
            status: "danger",
            pos: "top-center",
            timeout: 4000,
          });
        });
    },
    userActivate(userEmail, status) {

      axios({
        method: "patch",
        url: this.APIHost + "/api/v1/user-activate",
        data: {
          UserEmail: userEmail,
          Status: status
        },
        headers: {
          Token: this.token
        }
      })
        .then(
          function (response) {// eslint-disable-line no-unused-vars
            this.usersList(this.Users.CurrentPage);
          }.bind(this)
        )
        .catch(function (error) {
          // handle error
          console.log(error);
          UIkit.notification({// eslint-disable-line no-undef
            message: "Si è verificato un errore.",
            status: "danger",
            pos: "top-center",
            timeout: 4000,
          });
        });
    },

  },
  mounted() {
    this.usersList(1);
  },
  updated() {

  }
};
</script>

<template>
  <section v-if="(isAdmin || manageUsers.CanManage)">
    <h4>Inserimento nuovo operatore</h4>
    <div class="uk-card uk-margin-top">
      <div>
        <div class="uk-margin">
          <input class="uk-input uk-width-1-2" type="text" placeholder="Email" v-model="NewUser.Email"
            v-on:keyup="userCreateValidate()">
        </div>
        <div class="uk-margin">
          <input class="uk-input uk-width-1-2" type="text" placeholder="Nome" v-model="NewUser.Name"
            v-on:keyup="userCreateValidate()">
        </div>
        <div class="uk-margin">
          <input class="uk-input uk-width-1-2" type="text" placeholder="Cognome" v-model="NewUser.Surname"
            v-on:keyup="userCreateValidate()">
        </div>
        <div class="uk-margin">
          <div id="v-model-radiobutton">
            <input type="radio" class="uk-radio" value="true" id="NewUserAdmin1" v-model="NewUser.Admin" />
            <label for="NewUserAdmin1">Admin</label>

            <input type="radio" class="uk-radio uk-margin-left" value="false" id="NewUserAdmin0"
              v-model="NewUser.Admin" />
            <label for="NewUserAdmin0">Operatore con permessi</label>

          </div>
        </div>
        <div class="uk-margin">
          <button class="uk-button uk-button-primary" @click.prevent="userCreate()"
            v-bind:disabled="!userCreateValidate()">Invia messaggio di attivazione</button>
        </div>
      </div>
    </div>
  </section>

</template>

<style scoped>

</style>

<script>
const axios = require("axios").default;
import commonFunctions from "../../functions/common.js"; // eslint-disable-line no-unused-vars
import UIkit from 'uikit'
export default {
  name: "ManageUsers_New",
  computed: {
    token() {
      return this.$store.state.token;
    },
    isAdmin() {
      return this.$store.state.admin;
    },
    grants() {
      return this.$store.state.grants;
    },
    manageUsers() {
      return this.getGrants("manageusers")
    }
  },

  data() {
    return {
      APIHost: process.env.VUE_APP_APIHOST,
      ModuleGrants: {
        Email: "",
        Name: "",
        Surname: "",
        List: [],
      },
      NewUser: {
        Email: "",
        Name: "",
        Surname: "",
        Admin: false
      }
    };
  },
  methods: {
    getGrants(k) {
            var r = {
                canManage: false,
                label: ""
            }
            for (var x = 0; x < this.grants.length; x++) {
                if (this.grants[x].ModuleKeyID == k) {
                    r.canManage = this.grants[x].CanManage;
                    r.label = this.grants[x].ModuleLabel;
                }
            }
            return r;
        },
    userCreateValidate() {
      if (
        commonFunctions.isNullOrWhiteSpace(this.NewUser.Email) ||
        !commonFunctions.isEmail(this.NewUser.Email) ||
        commonFunctions.isNullOrWhiteSpace(this.NewUser.Name) ||
        commonFunctions.isNullOrWhiteSpace(this.NewUser.Surname)
      ) {
        return false;
      } else {
        return true;
      }
    },
    userCreate() {
      axios({
        method: "post",
        url: this.APIHost + "/api/v1/user-insert",
        data: {
          UserEmail: this.NewUser.Email,
          Name: this.NewUser.Name,
          Surname: this.NewUser.Surname,
          Admin: this.NewUser.Admin,
          Language: "IT"
        },
        headers: {
          Token: this.token
        }
      })
        .then(
          function (response) {// eslint-disable-line no-unused-vars
            if (response.data && response.data.result && response.data.result == "ok") {
              UIkit.notification({// eslint-disable-line no-undef
                message: "Utente aggiunto correttamente.",
                status: "success",
                pos: "top-center",
                timeout: 4000,
              });
            }
          }.bind(this)
        )
        .catch(function (error) {
          // handle error
          console.log(error);
          UIkit.notification({// eslint-disable-line no-undef
            message: "Si è verificato un errore.",
            status: "danger",
            pos: "top-center",
            timeout: 4000,
          });
        });

    },

  },
  mounted() {

  },
  updated() {

  }
};
</script>

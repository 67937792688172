<template>
    <section v-if="isAdmin || billbooks.canManage">
        <div>
            <section>
                <form class="uk-card  uk-card-body uk-card-default" @submit.prevent="search()">
                    <div class="uk-grid uk-width-1-1">
                        <div>

                            <div class="uk-margin">
                                <datepicker :enable-time-picker="false" v-model="Search.PaymentDateFrom"
                                    placeholder="Data pagamento (da)" format="dd/MM/yyyy" cancel-text="Annulla"
                                    select-text="OK" />
                            </div>
                            <div class="uk-margin">
                                <datepicker :enable-time-picker="false" v-model="Search.PaymentDateTo"
                                    placeholder="Data pagamento (a)" format="dd/MM/yyyy" cancel-text="Annulla"
                                    select-text="OK" />
                            </div>
                            <div class="uk-margin">
                                <input class="uk-input" placeholder="Customer code" v-model="Search.CustomerCode">
                            </div>
                        </div>


                    </div>
                    <div class="uk-column-1-2@m uk-margin-top">
                        <button :disabled="Search.Submitting" class="uk-button uk-button-primary uk-margin-right"
                            >
                            <span v-if="!Search.Submitting">Cerca fatture</span><span v-if="Search.Submitting"
                                uk-spinner="ratio: 0.7"></span>
                        </button>
                        <button class="uk-button uk-button-default" @click="clearSearch()">
                            Cancella filtri
                        </button>
                        <button class="uk-align-right@m uk-button uk-button-primary"
                            :disabled="Invoices.List.length == 0 || !Invoices.Loaded"
                            @click.prevent="invoicesExport">Esporta
                            risultati</button>
                        
                    </div>
                </form>

                <div class="uk-margin-top" v-if="Invoices.List.length == 0 && Invoices.Loaded">
                    Nessuna fattura presente.
                </div>
                <div v-if="Invoices.List.length > 0" class="uk-margin-top">

                    <div v-if="Invoices.List.length > 0" class="uk-margin-top uk-margin-bottom">
                        <span class="uk-margin-right">{{ Invoices.List[0]["Total"] }} fatture presenti.</span>
                        <span class="uk-margin-right">Pagina: {{ Invoices.CurrentPage }} di {{ totalPages }}</span>
                        <button class="uk-button uk-button-default" @click.prevent="invoicesList(1)"
                            v-if="Invoices.CurrentPage > 1"><span uk-icon="icon: chevron-double-left;"></span></button>
                        <button class="uk-button uk-button-default" @click.prevent="invoicesList(Invoices.CurrentPage - 1)"
                            v-if="Invoices.CurrentPage > 1"><span uk-icon="icon: chevron-left;"></span></button>
                        <button class="uk-button uk-button-default" @click.prevent="invoicesList(Invoices.CurrentPage + 1)"
                            v-if="Invoices.CurrentPage < totalPages"><span uk-icon="icon: chevron-right;"></span></button>
                        <button class="uk-button uk-button-default" @click.prevent="invoicesList(totalPages)"
                            v-if="Invoices.CurrentPage < totalPages"><span
                                uk-icon="icon: chevron-double-right;"></span></button>



                    </div>
                    <div v-for="(invoice) in Invoices.List" :key="invoice['invoiceId']"
                        class="uk-margin-top uk-padding-small uk-card  uk-card-default">
                        <div class="uk-card-body">
                            <div class="uk-grid">
                                <div class="uk-width-1-2@m">
                                    <div class="uk-text">Cliente: {{ invoice['CustomerName'] }} </div>
                                    <div class="uk-text">Cod. cliente: {{ invoice['CustomerCode'] }} </div>
                                    <div class="uk-text">Data pagamento: {{
                                        invoice['LatestPaymentOperation'] }} </div>

                                </div>
                                <div class="uk-width-1-2@m">
                                    <div class="uk-text">Numero fattura: {{ invoice['InvoiceNumber'] }} ({{
                                        invoice['SupplyTypeCode'] }})</div>
                                    <div class="uk-text">Importo fattura: {{ invoice['TotalValue'] }}</div>
                                    <div class="uk-text">Stato pag.:
                                        <span v-if="invoice['PaymentStatus'] == ''">Non impostato</span>
                                        <span v-if="invoice['PaymentStatus'] == 'PAID'">Pagato</span>
                                        <span v-if="invoice['PaymentStatus'] == 'NOTPAID'">Non pagato</span>
                                        <span v-if="invoice['PaymentStatus'] == 'PENDING'">Pending</span>
                                        <span v-if="invoice['PaymentStatus'] == 'COMPENS'">Compensazione</span>
                                        <span v-if="invoice['PaymentStatus'] == 'PARTIAL'">Pagamento parziale</span>
                                    </div>                                    
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div v-if="Invoices.List.length > 0" class="uk-margin-top uk-margin-bottom">
                    <span class="uk-margin-right">Pagina: {{ Invoices.CurrentPage }} di {{ totalPages }}</span>
                    <button class="uk-button uk-button-default" @click.prevent="invoicesList(1)"
                        v-if="Invoices.CurrentPage > 1"><span uk-icon="icon: chevron-double-left;"></span></button>
                    <button class="uk-button uk-button-default" @click.prevent="invoicesList(Invoices.CurrentPage - 1)"
                        v-if="Invoices.CurrentPage > 1"><span uk-icon="icon: chevron-left;"></span></button>
                    <button class="uk-button uk-button-default" @click.prevent="invoicesList(Invoices.CurrentPage + 1)"
                        v-if="Invoices.CurrentPage < totalPages"><span uk-icon="icon: chevron-right;"></span></button>
                    <button class="uk-button uk-button-default" @click.prevent="invoicesList(totalPages)"
                        v-if="Invoices.CurrentPage < totalPages"><span
                            uk-icon="icon: chevron-double-right;"></span></button>

                </div>
            </section>
        </div>
    </section>
    
</template>
  
<style scoped>
.bg-box {
    border: solid 1px #cccccc;
}

.public-annotations-list {
    max-height: 300px;
    overflow-y: scroll;
}
</style>
  
<script lang="ts">
const axios = require("axios").default;
import commonFunctions from "../../functions/common.js"; // eslint-disable-line no-unused-vars
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import UIkit from 'uikit'

export default {
    name: "BillbooksList",
    components: { Datepicker },
    computed: {
        token() {
            return this.$store.state.token;
        },
        email() {
            return this.$store.state.email;
        },
        isAdmin() {
            return this.$store.state.admin;
        },
        grants() {
            return this.$store.state.grants;
        },
        billbooks() {
            return this.getGrants("billbooks")
        },
        totalPages() {
            var tot = Math.floor(this.Invoices.Total / 25);
            if (tot * 25 < this.Invoices.Total) tot += 1
            return tot
        }
    },

    data() {
        return {

            APIHost: process.env.VUE_APP_APIHOST,
            Invoices: {
                List: [],
                CurrentPage: 0,
                Loaded: false,
                Total: 0
            },
            Search: {
                CustomerCode: "",
                PaymentDateFrom: "",
                PaymentDateTo: "",
                Submitting: false
            },
            PaymentStatus: [
                { value: '', label: 'Tutti gli stati di pagamento' },
                { value: 'PAID', label: 'Pagato' },
                { value: 'PENDING', label: 'Pending' },
                { value: 'NOTPAID', label: 'Non pagato' },
                { value: 'COMPENS', label: 'Compensazione' },
                { value: 'PARTIAL', label: 'Pag. parziale' },
            ],
            PaymentStatusSelect: [
                { value: '', label: 'Seleziona...' },
                { value: 'PAID', label: 'Pagato' },
                { value: 'PENDING', label: 'Pending' },
                { value: 'NOTPAID', label: 'Non pagato' },
                { value: 'COMPENS', label: 'Compensazione' },
                { value: 'PARTIAL', label: 'Pag. parziale' },
            ]            
        };
    },
    methods: {
        getGrants(k) {
            var r = {
                canManage: false,
                label: ""
            }
            for (var x = 0; x < this.grants.length; x++) {
                if (this.grants[x].ModuleKeyID == k) {
                    r.canManage = this.grants[x].CanManage;
                    r.label = this.grants[x].ModuleLabel;
                }
            }
            return r;
        },
        errorNotification(msg) {
            UIkit.notification({// eslint-disable-line no-undef
                message: msg == null ? "Si è verificato un errore." : msg,
                status: "danger",
                pos: "top-center",
                timeout: 4000,
            });
        },
        successNotification(msg) {
            UIkit.notification({// eslint-disable-line no-undef
                message: msg == null ? "Operazione eseguita con successo." : msg,
                status: "success",
                pos: "top-center",
                timeout: 4000,
            });
        },
        search() {
            this.Search.Submitting = true
            this.invoicesList(1);
        },
        clearSearch() {
            this.Search.CustomerName = ""
            this.Search.PaymentDateFrom = ""
            this.Search.PaymentDateTo = ""
        },
        
        invoicesList(pageNumber) {
            axios({
                method: "get",
                url: this.APIHost + "/api/v1/nexi-report-list",
                params: {
                    PageNumber: pageNumber,
                    CustomerCode: this.Search.CustomerCode,
                    PaymentDateFrom: this.Search.PaymentDateFrom,
                    PaymentDateTo: this.Search.PaymentDateTo,
                },
                headers: {
                    Token: this.token
                }
            })
                .then(
                    function (response) {// eslint-disable-line no-unused-vars
                        this.Invoices.List = [];
                        var x;
                        for (x = 0; x < response.data.length; x++) {
                            this.Invoices.List.push(response.data[x]);
                        }
                        this.Invoices.CurrentPage = pageNumber;
                        this.Invoices.Total = this.Invoices.List.length > 0 ? parseInt(this.Invoices.List[0]["Total"]) : 0;
                        this.Invoices.Loaded = true;
                        this.Search.Submitting = false
                        window.scrollTo({ top: 300, left: 0, behavior: 'smooth' })
                    }.bind(this)
                )
                .catch(function (error) {// eslint-disable-line no-unused-vars
                    this.errorNotification();
                    this.Search.Submitting = false
                }.bind(this));
        },
        invoicesExport() {
            axios({
                method: "get",
                url: this.APIHost + "/api/v1/nexi-report-export",
                params: {
                    Email: this.email,
                    CustomerCode: this.Search.CustomerCode,
                    PaymentDateFrom: this.Search.PaymentDateFrom,
                    PaymentDateTo: this.Search.PaymentDateTo,
                },
                headers: {
                    Token: this.token
                }
            })
                .then(
                    function (response) {// eslint-disable-line no-unused-vars
                        if (response.data == "started") {
                            this.successNotification("Procedura di export avviata. Attendere notifica via email.")
                        }
                        if (response.data == "busy") {
                            this.errorNotification("E' già in corso un altro processo di export. Attendere la notifica di completamento prima di procedere.");
                        }
                        if (response.data == "error") {
                            this.errorNotification()
                        }
                    }.bind(this)
                )
                .catch(function (error) {// eslint-disable-line no-unused-vars
                    this.errorNotification();
                }.bind(this));
        },
    },
    mounted() {
        
    },
    updated() {

    }
};
</script>
  
<template>
    <div>
        
        <div class="uk-card uk-card-body uk-card-default uk-width-1-1">
            <h4>Centralino - Export customer satisfaction</h4>
            <table class="uk-table uk-width-1-2@s">
                <tr>
                    <td>
                        Data inizio:
                    </td>
                    <td>
                        <datepicker v-model="dateFrom" :enable-time-picker="false"
                            format="dd/MM/yyyy" cancel-text="Annulla" select-text="OK" />
                    </td>
                </tr>
                <tr>
                    <td>
                        Data fine:
                    </td>
                    <td>
                        <datepicker v-model="dateTo" :enable-time-picker="false"
                            format="dd/MM/yyyy" cancel-text="Annulla" select-text="OK" />
                    </td>
                </tr>
                <tr>
                    <td>Indirizzo ricezione export:</td>
                    <td colspan="2">
                        <input class="uk-input uk-form-width-medium" v-model="exportEmail" placeholder="Email"
                            :class="{ 'uk-form-danger': !formValidation.exportEmail, 'uk-form-success': formValidation.exportEmail }"
                            @keyup="setFormValidationAttributes" />
                    </td>
                </tr>
            </table>
            <div class="uk-margin-top">
                <button class="uk-button uk-button-primary" :disabled="!validateForm"
                @click.prevent="submit"
                >Avvia esportazione</button>
            </div>
        </div>

    </div>
</template>
<style scoped></style>
<script>
const axios = require("axios").default; // eslint-disable-line no-unused-vars
import commonFunctions from "../../functions/common.js"; // eslint-disable-line no-unused-vars
import UIkit from 'uikit' // eslint-disable-line no-unused-vars
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
export default {
    name: "PhoneExchange_ExportCustomerSatisfaction",
    components: {
        Datepicker
    },
    computed: {
        token() {
        return this.$store.state.token;
        },
        email() {
            return this.$store.state.email
        }
    },
    data() {
        return {
            dateFrom: null,
            dateTo: null,
            exportEmail: "",
            formValidation: {
                exportEmail: false
            },
        }

    },
    methods: {
        errorNotification(msg) {
            UIkit.notification({// eslint-disable-line no-undef
                message: msg == null ? "Si è verificato un errore." : msg,
                status: "danger",
                pos: "top-center",
                timeout: 4000,
            });
        },
        successNotification(msg) {
            UIkit.notification({// eslint-disable-line no-undef
                message: msg == null ? "Operazione eseguita con successo." : msg,
                status: "success",
                pos: "top-center",
                timeout: 4000,
            });
        },
        setFormValidationAttributes() {
            this.formValidation.exportEmail = commonFunctions.isEmail(this.exportEmail)
        },
        validateForm() {
            return commonFunctions.isEmail(this.exportEmail)
        },
        submit() {

            axios({
                method: "get",
                url: process.env.VUE_APP_APIHOST + "/api/v1/phone-exchange/export-customer-satisfaction",
                params: {
                    dateFrom: this.dateFrom,
                    dateTo: this.dateTo,
                    exportEmail: this.exportEmail
                },
                headers: {
                    Token: this.token
                }
            })
                .then(
                    function (response) {// eslint-disable-line no-unused-vars
                        if (response.data){
                            this.successNotification("Export in fase di elaborazione. Il file verrà inviato all'indirizzo " + this.exportEmail)
                        }
                    }.bind(this)
                )
                .catch(function (error) {// eslint-disable-line no-unused-vars
                    this.errorNotification();
                }.bind(this));

        }
    },
    mounted() {
        var d1 = new Date()        
        var d2 = new Date()
        d1.setMonth(d1.getMonth() - 1);
        d1.setDate(1)
        
        this.dateFrom = d1.toLocaleDateString("en-US")
        this.dateTo = d2.toLocaleDateString("en-US")
        this.exportEmail = this.email
        this.setFormValidationAttributes()
    },
    updated() {

    }
}
</script>

<template>
    <section v-if="isAdmin || notifications.canManage">
        <div>
            <section>
                <p class="uk-text-lead">Gestione notifiche custom</p>
                <div class="uk-margin-top">
                    <div class="js-upload" uk-form-custom v-if="!NotificationListAttachmentSelected">
                        <div>
                            <input type="file" @change="handleFileListUpload($event)">
                            <button class="uk-button uk-button-primary" type="button" tabindex="-1">Carica lista destinatari (formato .XLSX)...</button>
                        </div>

                    </div>
                    <div v-if="!NotificationListAttachmentSelected">
                        <a href="/attachments/CustomNotificationsTemplateBook.xlsx" target="_blank">Scarica modello
                            di esempio</a>
                    </div>

                    <div class="uk-text" v-if="NotificationListAttachment != null">
                        File caricato: {{ NotificationListAttachment["name"] }}
                    </div>

                    <div class="uk-align-right" v-if="NotificationListAttachmentSelected">
                        <button class="uk-button uk-button-primary" uk-toggle="target: #modal-notification-message"
                            :disabled="Items.List.length == 0">Imposta messaggio</button>
                        <button class="uk-button uk-button-primary uk-margin-small-left"
                            @click.prevent="sendNotification(true)"
                            :disabled="Items.List.length == 0 || !NotificationMessageIsReady">Invia
                            test</button>
                        <button class="uk-button uk-button-primary uk-margin-small-left"
                            @click.prevent="sendNotification(false)"
                            :disabled="Items.List.length == 0 || !NotificationMessageIsReady">Invia
                            notifiche</button>
                    </div>
                </div>
                <div class="uk-margin-top" v-if="Items.List.length == 0 && Items.Loaded">
                    Nessun risultato trovato.
                </div>
                <div v-if="Items.Total > 0" class="uk-margin-top">
                    <div class="uk-margin-top uk-margin-bottom">
                        <span class="uk-margin-right">{{ Items.Total }} risultati.<br>Pagina: {{ Items.CurrentPage }} di
                            {{ totalPages }}</span>
                        <button class="uk-button uk-button-default" @click.prevent="itemsList(1)"
                            v-if="Items.CurrentPage > 1"><span uk-icon="icon: chevron-double-left;"></span></button>
                        <button class="uk-button uk-button-default" @click.prevent="itemsList(Items.CurrentPage - 1)"
                            v-if="Items.CurrentPage > 1"><span uk-icon="icon: chevron-left;"></span></button>
                        <button class="uk-button uk-button-default" @click.prevent="itemsList(Items.CurrentPage + 1)"
                            v-if="Items.CurrentPage < totalPages"><span uk-icon="icon: chevron-right;"></span></button>
                        <button class="uk-button uk-button-default" @click.prevent="itemsList(totalPages)"
                            v-if="Items.CurrentPage < totalPages"><span
                                uk-icon="icon: chevron-double-right;"></span></button>
                    </div>
                    <table class="uk-table uk-table-striped">
                        <thead>
                            <th>Nome cliente
                                <br>
                                Email
                            </th>
                            <th>Numero modulo</th>
                            <th>Tipo fornitura
                                <br>
                                POD/PDR
                            </th>
                            <th class="uk-visible@m">Custom 1</th>
                            <th class="uk-visible@m">Custom 2</th>
                            <th class="uk-visible@m">Custom 3</th>
                            <th class="uk-visible@m">Custom 4</th>
                        </thead>
                        <tbody>
                            <tr v-for="(item) in Items.List" :key="item['row']">
                                <td>
                                    {{ item['clientName'] }}
                                    <br>
                                    {{ item['email'] }}
                                </td>
                                <td>{{ item['moduleNumber'] }}</td>
                                <td>
                                    {{ item['supplyType'] }}
                                    <br>
                                    {{ item['podPdr'] }}
                                </td>
                                <td class="uk-visible@m">{{ item['custom1'] }}</td>
                                <td class="uk-visible@m">{{ item['custom2'] }}</td>
                                <td class="uk-visible@m">{{ item['custom3'] }}</td>
                                <td class="uk-visible@m">{{ item['custom4'] }}</td>
                            </tr>
                        </tbody>
                    </table>
                    <div v-if="Items.List.length > 0" class="uk-margin-top uk-margin-bottom">
                        <span class="uk-margin-right">Pagina: {{ Items.CurrentPage }} di {{ totalPages }}</span>
                        <button class="uk-button uk-button-default" @click.prevent="itemsList(1)"
                            v-if="Items.CurrentPage > 1"><span uk-icon="icon: chevron-double-left;"></span></button>
                        <button class="uk-button uk-button-default" @click.prevent="itemsList(Items.CurrentPage - 1)"
                            v-if="Items.CurrentPage > 1"><span uk-icon="icon: chevron-left;"></span></button>
                        <button class="uk-button uk-button-default" @click.prevent="itemsList(Items.CurrentPage + 1)"
                            v-if="Items.CurrentPage < totalPages"><span uk-icon="icon: chevron-right;"></span></button>
                        <button class="uk-button uk-button-default" @click.prevent="itemsList(totalPages)"
                            v-if="Items.CurrentPage < totalPages"><span
                                uk-icon="icon: chevron-double-right;"></span></button>
                    </div>
                </div>
            </section>
        </div>
    </section>

    <section>
        <!--modals-->
        <div id="modal-notification-message" uk-modal>
            <div class="uk-modal-dialog uk-modal-body uk-width-auto">
                <h4 class="">Impostazioni messaggio</h4>
                <div class="uk-padding-small">
                    <span class="uk-badge uk-margin-right"
                        @click="typeInNotificationMessageTextarea('[NOME_CLIENTE]')">Nome
                        cliente</span>
                    <span class="uk-badge uk-margin-right"
                        @click="typeInNotificationMessageTextarea('[NUMERO_MODULO]')">Numero
                        modulo</span>
                    <span class="uk-badge uk-margin-right"
                        @click="typeInNotificationMessageTextarea('[TIPO_FORNITURA]')">Tipo
                        fornitura</span>
                    <span class="uk-badge uk-margin-right"
                        @click="typeInNotificationMessageTextarea('[POD_PDR]')">Pod/pdr
                    </span>
                    <span class="uk-badge uk-margin-right"
                        @click="typeInNotificationMessageTextarea('[CUSTOM_1]')">Custom
                        1</span>
                    <span class="uk-badge uk-margin-right"
                        @click="typeInNotificationMessageTextarea('[CUSTOM_2]')">Custom
                        2</span>
                    <span class="uk-badge uk-margin-right"
                        @click="typeInNotificationMessageTextarea('[CUSTOM_3]')">Custom
                        3</span>
                    <span class="uk-badge uk-margin-right"
                        @click="typeInNotificationMessageTextarea('[CUSTOM_4]')">Custom
                        4</span>

                </div>
                <div>
                    <input type="text" class="uk-input uk-margin-bottom uk-form-width-large" v-model="NotificationTitle"
                        placeholder="Titolo">
                </div>
                <textarea ref="notificationMessageTextarea" class="uk-textarea" rows=8 id="modal-message-textarea"
                    v-model="NotificationMessage"
                    placeholder="Inserisci il corpo del messaggio. Utilizza i placeholder per inserire contenuti dinamici."></textarea>
                <div class="js-upload" uk-form-custom>
                    <a v-if="NotificationMessageAttachmentSelected" @click.prevent="deleteNotificationMessageAttachment"
                        class="" type="button"><i uk-icon="trash"></i>Rimuovi allegato</a>

                    <input v-if="!NotificationMessageAttachmentSelected" type="file" @change="handleFileUpload($event)">
                    <button v-if="!NotificationMessageAttachmentSelected" class="uk-button uk-button-primary"
                        type="button" tabindex="-1">Allega file...</button>
                    <span class="uk-margin-left" v-if="NotificationMessageAttachment != null">{{
        NotificationMessageAttachment["name"] }}
                    </span>
                </div>
                <div class="uk-margin-top">
                    Modalità di invio:<br>
                    <div uk-grid>
                        <div class="uk-width-1-2@m">
                            <label><input type="checkbox" class="uk-checkbox" value="PlainTextEmail_AWS"
                                    @change.prevent="removeSendMethod('TemplateEmail_AWS')"
                                    v-model="NotificationSendMethod"> Email plain text (via AWS)</label><br>
                            <label><input type="checkbox" class="uk-checkbox" value="TemplateEmail_AWS"
                                    @change.prevent="removeSendMethod('PlainTextEmail_AWS')"
                                    v-model="NotificationSendMethod"> Email con template (via AWS)</label>
                        </div>
                        <div class="uk-width-1-2@m">
                            <label><input type="checkbox" class="uk-checkbox" value="PlainTextEmail_BREVO"
                                    @change.prevent="removeSendMethod('TemplateEmail_BREVO')"
                                    v-model="NotificationSendMethod"> Email plain text (via Brevo)</label><br>
                            <label><input type="checkbox" class="uk-checkbox" value="TemplateEmail_BREVO"
                                    @change.prevent="removeSendMethod('PlainTextEmail_BREVO')"
                                    v-model="NotificationSendMethod"> Email con template (via Brevo)</label><br>
                        </div>
                    </div>



                </div>
                <p class="uk-text-right">
                    <button class="uk-button uk-button-default uk-modal-close" type="button">Annulla</button>
                    <button class="uk-button uk-button-primary uk-modal-close" type="button"
                        :disabled="NotificationMessage == '' || NotificationSendMethod.length == 0"
                        @click.prevent="notificationSetMessageReady">Fatto</button>
                </p>
            </div>
        </div>
    </section>
</template>

<style scoped>
.bg-box {
    border: solid 1px #cccccc;
}

#modal-notification-message .uk-badge {
    cursor: pointer;
}

#modal-notification-message .uk-badge.badge-disabled {
    background-color: #cccccc;
    pointer-events: none;
    cursor: default;
}

.a-disabled {
    color: #cccccc;
    pointer-events: none;
    cursor: default;
}
</style>

<script lang="ts">
const axios = require("axios").default;
import commonFunctions from "../../functions/common.js"; // eslint-disable-line no-unused-vars
import UIkit from 'uikit'

export default {
    name: "CustomNotificationsList",
    computed: {
        token() {
            return this.$store.state.token;
        },
        email() {
            return this.$store.state.email;
        },
        isAdmin() {
            return this.$store.state.admin;
        },
        grants() {
            return this.$store.state.grants;
        },
        notifications() {
            return this.getGrants("notifications")
        },
        totalPages() {
            var tot = Math.floor(this.Items.Total / 25);
            if (tot * 25 < this.Items.Total) tot += 1
            return tot
        }
    },

    data() {
        return {

            APIHost: process.env.VUE_APP_APIHOST,
            Items: {
                List: [],
                CurrentPage: 0,
                Loaded: false,
                Total: 0
            },
            NotificationMessage: "",
            NotificationTitle: "",
            NotificationSendMethod: [],
            NotificationMessageIsReady: false,
            NotificationMessageAttachment: null,
            NotificationMessageAttachmentSelected: false,
            RequestId: 0,
            NotificationListAttachment: null,
            NotificationListAttachmentSelected: false

        };
    },
    methods: {
        getGrants(k) {
            var r = {
                canManage: false,
                label: ""
            }
            for (var x = 0; x < this.grants.length; x++) {
                if (this.grants[x].ModuleKeyID == k) {
                    r.canManage = this.grants[x].CanManage;
                    r.label = this.grants[x].ModuleLabel;
                }
            }
            return r;
        },
        errorNotification(msg) {
            UIkit.notification({// eslint-disable-line no-undef
                message: msg == null ? "Si è verificato un errore." : msg,
                status: "danger",
                pos: "top-center",
                timeout: 4000,
            });
        },
        successNotification(msg) {
            UIkit.notification({// eslint-disable-line no-undef
                message: msg == null ? "Operazione eseguita con successo." : msg,
                status: "success",
                pos: "top-center",
                timeout: 4000,
            });
        },
        removeSendMethod(key) {
            for (let x = 0; x < this.NotificationSendMethod.length; x++) {
                if (this.NotificationSendMethod[x] == key) {
                    this.NotificationSendMethod.splice(x, 1);
                }
            }
        },
        typeInNotificationMessageTextarea(newText) {
            let textarea = this.$refs.notificationMessageTextarea
            let start = textarea.selectionStart
            let end = textarea.selectionEnd
            let text = textarea.value
            let before = text.substring(0, start)
            let after = text.substring(end, text.length)
            this.NotificationMessage = (before + newText + after)
            this.$nextTick(() => {
                textarea.focus()
            });
        },
        handleFileUpload(event) {
            if (event.target.files[0].size > (5 * 1024 * 1024)) {
                this.errorNotification("La dimensione dell'allegato non può superare i 5 MB")
            } else {
                this.NotificationMessageAttachment = event.target.files[0];
                this.NotificationMessageAttachmentSelected = true;
            }
        },
        handleFileListUpload(event) {
            if (event.target.files[0].name.toLowerCase().indexOf(".xlsx") == -1) {
                this.NotificationListAttachment = null;
                this.NotificationListAttachmentSelected = false;
                this.errorNotification("Il file deve essere nel formato .xlsx")
                return false;
            }

            this.NotificationListAttachment = event.target.files[0];
            this.NotificationListAttachmentSelected = true;
            this.itemsList()

        },
        deleteNotificationMessageAttachment() {
            this.NotificationMessageAttachment = null;
            this.NotificationMessageAttachmentSelected = false;
        },
        notificationSetMessageReady() {
            this.NotificationMessageIsReady = true
        },
        showNotificationMessageModal() {
            UIkit.modal("#modal-notification-message").show()
        },
        itemsList(pageNumber) {

            axios({
                method: pageNumber == null ? "post" : "get",
                url: this.APIHost + "/api/v1/custom-notifications/list",
                data: pageNumber == null ? this.NotificationListAttachment : null,
                params: {
                    Email: this.email,
                    RequestId: this.RequestId,
                    PageNumber: pageNumber
                },
                headers:
                    pageNumber == null ? {
                        'Token': this.token,
                        'Content-Type': 'application/octet-stream'
                    }
                        :
                        {
                            'Token': this.token
                        }
            })
                .then(
                    function (response) {// eslint-disable-line no-unused-vars
                        this.Items.List = [];
                        console.log(response.data)
                        for (let x = 0; x < response.data.length; x++) {
                            this.Items.List.push(response.data[x]);
                        }
                        this.Items.CurrentPage = pageNumber == null ? 1 : pageNumber;
                        this.Items.Total = this.Items.List.length > 0 ? parseInt(this.Items.List[0]["total"]) : 0;
                        this.Items.Loaded = true;
                        window.scrollTo({ top: 300, left: 0, behavior: 'smooth' })
                    }.bind(this)
                )
                .catch(function (error) {// eslint-disable-line no-unused-vars
                    this.errorNotification();
                }.bind(this));
        },
        sendNotification(test) {
            const dialogMsg = test ? 'Confermando, verrà inviato un messaggio al tuo indirizzo email utilizzando i dati della prima riga della lista. Procedere?' :
                'Confermando, verrà inviato il messaggio a tutti i destinatari della lista. Procedere?';
            UIkit.modal.confirm(dialogMsg).then(function () {
                //confirmed
                console.log('confirmed', test)
                this.sendNotificationFn(test)
            }.bind(this), function () {
                console.log('Rejected.')
            });

        },
        sendNotificationFn(test) { // eslint-disable-line no-unused-vars
            axios({
                method: "post",
                url: this.APIHost + "/api/v1/custom-notifications/send",
                params: {
                    SendMethod: JSON.stringify(this.NotificationSendMethod),
                    Test: test,
                    Email: this.email,
                    RequestId: this.RequestId,

                },
                headers: {
                    Token: this.token,
                    'Content-Type': 'application/octet-stream',
                    'FileName': this.NotificationMessageAttachmentSelected ? this.NotificationMessageAttachment.name : "",
                    'Title': encodeURIComponent(this.NotificationTitle),
                    'Message': encodeURIComponent(this.NotificationMessage)
                },
                data: this.NotificationMessageAttachmentSelected ? this.NotificationMessageAttachment : null

            })
                .then(
                    function (response) {// eslint-disable-line no-unused-vars
                        if (test) {
                            this.successNotification()
                        } else {
                            this.successNotification("Procedura di invio notifiche avviata. Al termine della procedura verrà inviata una email di riepilogo.")
                        }

                    }.bind(this)
                )
                .catch(function (error) {// eslint-disable-line no-unused-vars
                    console.log(error)
                    this.errorNotification();

                }.bind(this));
        },


    },
    mounted() {
        this.RequestId = Date.now()
    }
};
</script>
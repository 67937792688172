import { VueSignalR } from '@dreamonkey/vue-signalr';
import { HubConnectionBuilder } from '@microsoft/signalr';

import { createApp } from 'vue'
import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import { VueReCaptcha } from 'vue-recaptcha-v3'
import 'uikit/dist/css/uikit.css'
import UIkit from 'uikit'
import Icons from 'uikit/dist/js/uikit-icons'
UIkit.use(Icons)

import App from './App.vue'

import LoadScript from './functions/loadscript.js'
import router from './router'



const store = createStore({
    plugins: [createPersistedState({
        storage: window.sessionStorage,
    })],
    state() {
        return {
            isLoggedIn: false,
            section: "dashboard",
            subsection: "",
            triggerEvent: "",
            token: "",
            newCampaign: null
        }
    },
    mutations: {
        setLoggedIn(state) {
            state.isLoggedIn = true
        },
        changeSection(state, payload) {
            if (payload.triggerEvent != null){
                state.triggerEvent =  payload.triggerEvent;
            }
            state.section = payload.section
            state.subsection = payload.subsection != null ? payload.subsection : ""
        },
        setUserDialogInfo(state, data) {
            state.userDialogInfoName = data.name
            state.userDialogInfoSurname = data.surname
            state.userDialogInfoEmail = data.email
        },
        resetTriggeredEvent(state){
            state.triggerEvent = "";
        },
        changeToken(state, token) {
            state.token = token
        },
        changeLoginData(state, loginData) {
            state.userId = loginData.userId;
            state.email = loginData.email;
            state.name = loginData.name;
            state.surname = loginData.surname;
            state.previousLogin = loginData.previousLogin;
            state.admin = loginData.admin;
            state.grants = loginData.grants;
        },
        logout(state) {
            state.token = "",
            state.section = "login",
            state.subsection = "",
            state.isLoggedIn = false,
            state.name = "";
            state.surname = "";
            state.previousLogin = "";
            state.admin = false;
            state.grants = [];
            sessionStorage.clear();
        },        
    }
})


const app = createApp(App) // eslint-disable-line no-unused-vars

const connection = new HubConnectionBuilder()
  .withUrl(process.env.VUE_APP_SIGNALR_URL)
  .withAutomaticReconnect()
  .build();
app.use(VueSignalR, { connection })
app.use(VueReCaptcha, { siteKey: process.env.VUE_APP_RECAPTCHA_SITEKEY })
app.use(LoadScript)
app.use(store)
app.use(router)
app.mount('#app')










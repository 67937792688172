<template>
    <div class="uk-text-meta uk-margin-bottom">
        <router-link to="/self-readings">Gestione autoletture</router-link><span uk-icon="chevron-right"></span><span>Inserimento autoletture</span>
    </div>
    <div>
        <h4>Inserimento autoletture</h4>
    </div>
    <form class="uk-card uk-card-body uk-card-default" @submit.prevent="getSupplyPoints">
        <div>
            <input class="uk-input uk-form-width-medium" placeholder="Codice cliente" type="number"
                v-model="customerCode" />
        </div>
        <div class="uk-margin-top">
            <button class="uk-button uk-button-primary" type="submit"
                :disabled="customerCode == ''">Cerca
                cliente</button>
        </div>
    </form>

    <div class="uk-margin-top">
        <div v-if="clients.length == 0 && searchDone">
            Nessun cliente trovato.
        </div>
        <p v-if="clients.length > 0" class="uk-text-lead">
            {{ clients[0]["customerCode"] }}
            <br />
            {{ clients[0]["customerName"] }}
        </p>
        <table class="uk-table uk-table-divider uk-width-1-1" v-if="clients.length > 0">
            <thead>
                <tr>
                    <th>
                        Punto di fornitura
                    </th>
                    <th colspan="4">

                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="client in clients" :key="client['supplyPoint']">
                    <td v-if="client['expand'] != true">
                        <a @click.prevent="expandClient(client)">{{ client['supplyPoint'] }}</a>
                    </td>
                    <td v-if="client['expand'] != true">
                        {{ client['supplyTypeCode'] }}
                    </td>
                    <td v-if="client['expand'] != true">
                        {{ client['address'] }}
                    </td>
                    <td v-if="client['expand'] != true">
                        <span class="uk-text-small">Ultima autolettura: {{ client['readingDate'] }}</span>
                        <span v-if="client['readingDate'] != '--'">
                            <br />
                            <span v-if="client['supplyTypeCode'] == 'EE'">
                                F1: {{ client['selfReading_EEF1'] }}<br />
                                F2: {{ client['selfReading_EEF2'] }}<br />
                                F3: {{ client['selfReading_EEF3'] }}<br />
                            </span>
                            <span v-if="client['supplyTypeCode'] == 'GAS'">
                                SMC: {{ client['selfReading_GAS'] }}
                            </span>
                        </span>
                    </td>
                    <td v-if="client['expand'] == true" colspan="2">
                        {{ client['supplyTypeCode'] }}
                        <br />
                        {{ client['supplyPoint'] }}
                    </td>
                    <td v-if="client['expand'] == true" colspan="2">
                        <input v-if="client['supplyTypeCode'] == 'EE'" class="uk-input uk-form-width-small"
                            @keypress="isNumber($event)" v-model="client['selfReading_EEF1_Insert']" placeholder="F1" />
                        <input v-if="client['supplyTypeCode'] == 'EE'" class="uk-input uk-form-width-small"
                            @keypress="isNumber($event)" v-model="client['selfReading_EEF2_Insert']" placeholder="F2" />
                        <input v-if="client['supplyTypeCode'] == 'EE'" class="uk-input uk-form-width-small"
                            @keypress="isNumber($event)" v-model="client['selfReading_EEF3_Insert']" placeholder="F3" />
                        <input v-if="client['supplyTypeCode'] == 'GAS'" class="uk-input uk-form-width-small"
                            @keypress="isNumber($event)" v-model="client['selfReading_GAS_Insert']" placeholder="SMC" />
                            <div class="uk-input uk-form-width-medium">
                                <datepicker :enable-time-picker="false" v-model="client['selfReading_ReadingDate_Insert']"
                                    placeholder="Data autolettura" format="dd/MM/yyyy" cancel-text="Annulla"
                                    select-text="OK" />
                            </div>
                            
                        <button class="uk-button uk-button-primary uk-form-width-small"
                            @click.prevent="insertSelfReading(client)">Inserisci</button>
                        <button class="uk-button uk-button-default uk-form-width-small"
                            @click.prevent="collapseClient(client)">Annulla</button>
                    </td>
                </tr>
            </tbody>

        </table>
    </div>
</template>
<style scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}
</style>
<script>
const axios = require("axios").default; // eslint-disable-line no-unused-vars
import Datepicker from '@vuepic/vue-datepicker';
import commonFunctions from "../../functions/common.js"; // eslint-disable-line no-unused-vars
import UIkit from 'uikit' // eslint-disable-line no-unused-vars

export default {
    name: "SelfReadings_Insert",
    components: { Datepicker },
    computed: {
        token() {
            return this.$store.state.token;
        }
    },
    data() {
        return {
            clients: [],
            customerCode: "",
            searchDone: false
        }

    },
    methods: {
        errorNotification(msg) {
            UIkit.notification({// eslint-disable-line no-undef
                message: msg == null ? "Si è verificato un errore." : msg,
                status: "danger",
                pos: "top-center",
                timeout: 4000,
            });
        },
        successNotification(msg) {
            UIkit.notification({// eslint-disable-line no-undef
                message: msg == null ? "Operazione eseguita con successo." : msg,
                status: "success",
                pos: "top-center",
                timeout: 4000,
            });
        },

        getGrants(k) {
            var r = {
                canManage: false,
                label: ""
            }
            for (var x = 0; x < this.grants.length; x++) {
                if (this.grants[x].ModuleKeyID == k) {
                    r.canManage = this.grants[x].CanManage;
                    r.label = this.grants[x].ModuleLabel;
                }
            }
            return r;
        },
        isNumber(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
        expandClient(client) {
            client.expand = true;
        },
        collapseClient(client) {
            client.expand = false;
        },
        getSupplyPoints() {
            axios({
                method: "get",
                url: process.env.VUE_APP_APIHOST + "/api/v1/supply-points",
                params: {
                    customerCode: this.customerCode
                },
                headers: {
                    Token: this.token
                }
            })
                .then(
                    function (response) {// eslint-disable-line no-unused-vars
                        if (response.data) {
                            this.clients = response.data
                            const d = new Date()
                            this.clients.forEach(element => {
                                element['selfReading_ReadingDate_Insert'] = d
                            });
                        }
                    }.bind(this)
                )
                .catch(function (error) {// eslint-disable-line no-unused-vars
                    this.errorNotification();
                }.bind(this))
                .finally(
                    function () {
                        this.searchDone = true
                    }.bind(this)
                )
                ;
        },
        insertSelfReading(client) {
            //console.log(client)

            axios({
                method: "post",
                url: process.env.VUE_APP_APIHOST + "/api/v1/self-reading-insert",
                headers: {
                    Token: this.token
                },
                data: {
                    CustomerCode: client.customerCode,
                    SupplyPoint: client.supplyPoint,
                    SupplyTypeCode: client.supplyTypeCode,
                    SelfReading_GAS: client.supplyTypeCode == "GAS" ? parseFloat(client.selfReading_GAS_Insert) : 0,
                    SelfReading_EEF1: client.supplyTypeCode == "EE" ? parseFloat(client.selfReading_EEF1_Insert) : 0,
                    SelfReading_EEF2: client.supplyTypeCode == "EE" ? parseFloat(client.selfReading_EEF2_Insert) : 0,
                    SelfReading_EEF3: client.supplyTypeCode == "EE" ? parseFloat(client.selfReading_EEF3_Insert) : 0,
                    SelfReading_ReadingDate: client['selfReading_ReadingDate_Insert']
                }
            })
                .then(
                    function (response) {// eslint-disable-line no-unused-vars
                        console.log(response.data)
                        if (response.data && response.data == true) {
                            this.successNotification("Autolettura inserita correttamente")
                            this.getSupplyPoints()
                        }else{
                            this.errorNotification()
                        }
                    }.bind(this)
                )
                .catch(function (error) {// eslint-disable-line no-unused-vars
                    this.errorNotification();
                }.bind(this));


        }
    },
    mounted() {

    },
    updated() {

    }
}
</script>
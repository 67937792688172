<template>
  <div class="uk-width-1-1 uk-background-muted uk-padding-small">
    <div class="uk-container">
      <header-section />
    </div>
  </div>
  <div
    class="uk-container uk-margin-large-top"
    uk-height-viewport="expand: true"
  >
    <section>
    </section>
  </div>
  <footer-section />
</template>
<script>
import HeaderSection from "../components/Header/HeaderSection.vue";
import FooterSection from "../components/Footer/FooterSection.vue";
import UIkit from 'uikit' // eslint-disable-line no-unused-vars

const axios = require("axios").default; // eslint-disable-line no-unused-vars
export default {
    name: 
        "UserActivation",
    data() {
      return {
      userFirstActivationPassword: "",
      userFirstActivationPassword2: "",
      APIHost: process.env.VUE_APP_APIHOST
      }
    },
    computed: {
      token() {
        return this.$route.params.token
      }
    },
    components: {
        HeaderSection,
        FooterSection
    },
    methods: {
     
      changeEmail() {
        axios({
        method: "patch",
        url: this.APIHost + "/api/v1/user-change-email",
        data: {
          EmailVerificationToken: this.token,
        },
      })
        .then(
          function (response) {
            if (response.data && response.data.result && response.data.result == "ok") {
            //   this.$store.commit("logout");
            //   axios({
            //     method: "get",
            //     url: this.APIHost + "/api/v1/delete-token"
            //     }).then(
            //     function () {
            //         //this.$router.push({ path: '/' });
            //         UIkit.notification({// eslint-disable-line no-undef
            //         message:
            //         "Email modificata con successo. Effettuare nuovamente il login per continuare.",
            //         status: "success",
            //         pos: "top-center",
            //         timeout: 7000,
            //     });
            //   }.bind(this));
              
              UIkit.notification({// eslint-disable-line no-undef
                    message:
                    "Email modificata con successo.",
                    status: "success",
                    pos: "top-center",
                    timeout: 4000,
                });

            }else{
              UIkit.notification({// eslint-disable-line no-undef
                message:
                  "Si è verificato un errore. Procedura non completata.",
                status: "danger",
                pos: "top-center",
                timeout: 7000,
              });
            }
            
          }.bind(this)
        )
        .catch(function (error) {// eslint-disable-line no-unused-vars
          // handle error
          //console.log(error);
          UIkit.notification({// eslint-disable-line no-undef
            message:
              "Si è verificato un errore. Procedura non completata.",
            status: "danger",
            pos: "top-center",
            timeout: 7000,
          });
        })
        .then(function () {
          // always executed
        });
      }
    },
    mounted(){
      this.changeEmail();
    }
  
}
</script>

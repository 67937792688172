<template>
  <section>
    <div>Buongiorno {{loginData.name}} {{loginData.surname}}, <br>
      il tuo ultimo accesso risale al {{loginData.previousLogin}}
    </div>
  </section>
</template>

<style scoped>
</style>

<script>


export default {
  name: "Dashboard",
  computed: {
    token() {
      return this.$store.state.token;
    },
    loginData() {
      return {
        name: this.$store.state.name,
        surname: this.$store.state.surname,
        previousLogin: this.$store.state.previousLogin,
      }
    }
  },

  data() {
    return {
      APIHost: process.env.VUE_APP_APIHOST,
      
    };
  },
  methods: {
    
  },
  updated() {
    console.log("updated dashboard")
    
  },
};
</script>

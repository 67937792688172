<template>
  <div class="uk-text-meta uk-margin-bottom">
    <router-link to="/self-readings">Gestione autoletture</router-link><span uk-icon="chevron-right"></span><span>Altre
      impostazioni</span>
  </div>
  <div>
    <h4>
      Messaggistica area clienti
    </h4>

    <div>
      <span class="uk-text-meta">Informazioni sulle finestre temporali:</span><br>
      <textarea class="uk-textarea uk-width-1-1 uk-margin-bottom" v-model="SelfReadingsTimeWindowsInfoMessage" rows="3" />
    </div>
    <div>
      <span class="uk-text-meta">Alert finestra EE non disponibile:</span><br>
      <textarea class="uk-textarea uk-width-1-1 uk-margin-bottom" v-model="SelfReadingsTimeWindowsEEAlert" rows="2" />
    </div>
    <div>
      <span class="uk-text-meta">Alert finestra GAS non disponibile:</span><br>
      <textarea class="uk-textarea uk-width-1-1 uk-margin-bottom" v-model="SelfReadingsTimeWindowsGASAlert" rows="2" />
    </div>
    <div>
      <button class="uk-button uk-button-primary"
        @click.prevent="saveValues('SelfReadingsTimeWindowsInfoMessage,SelfReadingsTimeWindowsEEAlert,SelfReadingsTimeWindowsGASAlert')">Salva
        modifiche</button>
    </div>

  </div>
</template>

<script type="typescript">
const axios = require("axios").default; // eslint-disable-line no-unused-vars
import UIkit from 'uikit' // eslint-disable-line no-unused-vars
export default {
  name: "SelfReadings_Settings",
  computed: {
    isLoggedIn() {
      return this.$store.state.isLoggedIn;
    },
    token() {
      return this.$store.state.token;
    },
    isAdmin() {
      return this.$store.state.admin;
    },
    grants() {
      return this.$store.state.grants;
    },
    selfReadingsAdmin() {
      return this.getGrants("selfreadingsadmin")
    }
  },
  data() {
    return {
      SelfReadingsTimeWindowsInfoMessage: "",
      SelfReadingsTimeWindowsEEAlert: "",
      SelfReadingsTimeWindowsGASAlert: ""
    }
  },
  methods: {
    errorNotification(msg) {
      UIkit.notification({// eslint-disable-line no-undef
        message: msg == null ? "Si è verificato un errore." : msg,
        status: "danger",
        pos: "top-center",
        timeout: 4000,
      });
    },
    successNotification(msg) {
      UIkit.notification({// eslint-disable-line no-undef
        message: msg == null ? "Operazione eseguita con successo." : msg,
        status: "success",
        pos: "top-center",
        timeout: 4000,
      });
    },
    getGrants(k) {
      var r = {
        canManage: false,
        label: ""
      }
      for (var x = 0; x < this.grants.length; x++) {
        if (this.grants[x].ModuleKeyID == k) {
          r.canManage = this.grants[x].CanManage;
          r.label = this.grants[x].ModuleLabel;
        }
      }
      return r;
    },
    loadValues() {
      this.getDictionary("SelfReadingsTimeWindowsInfoMessage")
      this.getDictionary("SelfReadingsTimeWindowsEEAlert")
      this.getDictionary("SelfReadingsTimeWindowsGASAlert")
    },
    saveValues(keys) {
      const arr = keys.split(",");
      console.log(arr)
      arr.forEach(function (s) {
        console.log(s)
        this.setDictionary(s)
      }.bind(this));
    },
    getDictionary(key) {// eslint-disable-line no-unused-vars
      axios({
        method: "get",
        url: process.env.VUE_APP_APIHOST + "/api/v1/dictionary",
        headers: {
          Token: this.token
        },
        params: { key: key }
      })
        .then(
          function (response) {// eslint-disable-line no-unused-vars
            if (response.data) {
              this[key] = response.data
            }
          }.bind(this)
        )
        .catch(function (error) {// eslint-disable-line no-unused-vars
          this.errorNotification();
        }.bind(this));
    },
    setDictionary(key) {// eslint-disable-line no-unused-vars
      axios({
        method: "patch",
        url: process.env.VUE_APP_APIHOST + "/api/v1/dictionary",
        headers: {
          Token: this.token
        },
        params: {
          key: key,
          value: this[key]
        }
      })
        .then(
          function (response) {// eslint-disable-line no-unused-vars

          }.bind(this)
        )
        .catch(function (error) {// eslint-disable-line no-unused-vars
          this.errorNotification();
        }.bind(this));
    }
  },
  mounted() {
    this.loadValues()
  },
};
</script>
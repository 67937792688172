<template> 
  <div uk-sticky class="uk-width-1-1 uk-background-muted uk-padding-small">
    <div class="uk-container">
      <header-section />
    </div>
  </div>
  <div
    class="uk-container uk-margin-large-top"
    uk-height-viewport="expand: true"
  >
    <router-view></router-view>
  </div>
  <footer-section />
</template>
<script>
import HeaderSection from "../components/Header/HeaderSection.vue";
import FooterSection from "../components/Footer/FooterSection.vue";
import {useRoute} from 'vue-router'
import {computed} from 'vue'
import commonFunctions from "../functions/common.js"; // eslint-disable-line no-unused-vars
const axios = require("axios").default;// eslint-disable-line no-unused-vars
export default {
  name: "App",
  components: {
    HeaderSection,
    FooterSection
  },
  data() {
    return {
      APIHost: process.env.VUE_APP_APIHOST,
    };
  },
  computed: {
    isLoggedIn() {
      return this.$store.state.isLoggedIn;
    },
  },
  mounted() {
    //this.setCookie(); 
    const route=useRoute();
    const path = computed(() =>route.path)
    if (this.$store.state.isLoggedIn != true && path.value != "/login" && path.value != "/change-password"){
      console.log("redirect to login")
      this.$router.push({ path: '/login' });
    }    
  },
  beforeUpdate() {
    const route=useRoute();
    const path = computed(() =>route.path)
    if (this.$store.state.isLoggedIn == true && path.value == "/login"){
      console.log("redirect to dashboard")
      this.$router.push({ path: '/' });
    }
    
  },
  methods: {
 
  },
};
</script>


<style scoped>
.header {
  background-color: #eee;
}
</style>

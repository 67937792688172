<template>
  <div>
    <h4>
      Apikey centralino
    </h4>

    <div class="uk-margin-bottom">
      <label>Apikey: </label><span class="uk-text-bold uk-margin-left">{{ ApiKey }}</span>

    </div>
    <div>
      <button class="uk-button uk-button-primary uk-margin-top" @click.prevent="setApiKey">Genera nuova APIKEY</button>
    </div>

  </div>
</template>

<script type="typescript">
const axios = require("axios").default; // eslint-disable-line no-unused-vars
import UIkit from 'uikit' // eslint-disable-line no-unused-vars
export default {
  name: "PhoneExchange_Settings",
  computed: {
    isLoggedIn() {
      return this.$store.state.isLoggedIn;
    },
    token() {
      return this.$store.state.token;
    }
  },
  data() {
    return {
      ApiKey: ""
    }
  },
  methods: {
    errorNotification(msg) {
      UIkit.notification({// eslint-disable-line no-undef
        message: msg == null ? "Si è verificato un errore." : msg,
        status: "danger",
        pos: "top-center",
        timeout: 4000,
      });
    },
    successNotification(msg) {
      UIkit.notification({// eslint-disable-line no-undef
        message: msg == null ? "Operazione eseguita con successo." : msg,
        status: "success",
        pos: "top-center",
        timeout: 4000,
      });
    },
    getApiKey() {// eslint-disable-line no-unused-vars
      axios({
        method: "get",
        url: process.env.VUE_APP_APIHOST + "/api/v1/phone-exchange-apikey",
        headers: {
          Token: this.token
        }
      })
        .then(
          function (response) {// eslint-disable-line no-unused-vars
            if (response.data) {
              this.ApiKey = response.data
            }
          }.bind(this)
        )
        .catch(function (error) {// eslint-disable-line no-unused-vars
          this.errorNotification();
        }.bind(this));
    },
    setApiKey() {// eslint-disable-line no-unused-vars
      axios({
        method: "post",
        url: process.env.VUE_APP_APIHOST + "/api/v1/phone-exchange-apikey",
        headers: {
          Token: this.token
        }
      })
        .then(
          function (response) {// eslint-disable-line no-unused-vars
            this.ApiKey = response.data
          }.bind(this)
        )
        .catch(function (error) {// eslint-disable-line no-unused-vars
          this.errorNotification();
        }.bind(this));
    }
  },
  mounted() {
    this.getApiKey()
  },
};
</script>
<template>
    <div>
        <h4>Ricerca riga sinottico</h4>
        <form class="uk-card  uk-card-body uk-card-default" @submit.prevent="getRow()">
            <div class="uk-width-1-1">

                <div class="uk-margin">
                    <input class="uk-input" placeholder="Codice adesione" v-model="Search.ValueC">
                </div>
                <div class="uk-margin">
                    <input class="uk-input" placeholder="Partita iva" v-model="Search.ValueS">
                </div>
                <div class="uk-margin">
                    <input class="uk-input" placeholder="Codice fiscale" v-model="Search.ValueU">
                </div>
                <div class="uk-margin">
                    <input class="uk-input" placeholder="Pod / Pdr" v-model="Search.ValueAR">
                </div>
                <button type="submit" class="uk-button uk-button-primary" :disabled="formIsNotValid">Cerca riga</button>
            </div>
        </form>
        <div class="uk-width-1-1 uk-margin-top" v-if="Result != null && Result.length == 0">Nessun risultato trovato</div>
        <div class="uk-margin-top" v-if="Result != null && Result.length > 0">
            <button :disabled="Updating" class="uk-button uk-button-secondary" @click.prevent="updateRow()"><span
                    v-if="!Updating">Salva modifiche</span><span v-if="Updating" uk-spinner="ratio: 0.7"></span></button>
        </div>
        <div class="uk-width-1-1 uk-margin-top" v-if="synopticSheetGet">
            <table class="uk-table uk-table-striped uk-width-1-1" v-if="Result != null && Result.length == 1">
                <tbody>
                    <tr v-for="item in Result[0]['columns']" :key="item['n']">
                        <td class="uk-width-1-4 uk-text-small uk-text-break">
                            <span style="vertical-align: middle;">{{ item['n'] }}</span>
                        </td>
                        <td class="uk-width-3-4 uk-width-expand">

                            <input class="uk-input uk-width-1-1" v-model="item['v']" :disabled="!synopticSheetWrite" />
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="uk-margin-top uk-margin-bottom" v-if="Result != null && Result.length > 0">
            <button :disabled="Updating" class="uk-button uk-button-secondary" @click.prevent="updateRow()"><span
                    v-if="!Updating">Salva modifiche</span><span v-if="Updating" uk-spinner="ratio: 0.7"></span></button>
        </div>
    </div>
</template>
<style scoped></style>
<script>
const axios = require("axios").default; // eslint-disable-line no-unused-vars
import commonFunctions from "../../functions/common.js"; // eslint-disable-line no-unused-vars
import UIkit from 'uikit' // eslint-disable-line no-unused-vars

export default {
    name: "SynopticSheet_Get",
    computed: {
        isAdmin() {
            return this.$store.state.admin;
        },
        grants() {
            return this.$store.state.grants;
        },
        canEdit() {
            return this.isAdmin || this.getGrants("synoptic-sheet-update")
        },
        synopticSheetGet() {
            return this.isAdmin || this.getGrants("synoptic-sheet-get").canManage
        },
        synopticSheetWrite() {
            return this.isAdmin || this.getGrants("synoptic-sheet-write").canManage
        },
        token() {
            return this.$store.state.token;
        },
        email() {
            return this.$store.state.email
        },
        formIsNotValid() {
            return (this.Search.ValueC == "" && this.Search.ValueS == "" && this.Search.ValueU == "" && this.Search.ValueAR == "")
        }
    },
    data() {
        return {
            Search: {
                ValueC: "",
                ValueS: "",
                ValueU: "",
                ValueAR: ""
            },
            Result: {},
            Updating: false
        }

    },
    methods: {
        getGrants(k) {
            var r = {
                canManage: false,
                label: ""
            }
            for (var x = 0; x < this.grants.length; x++) {
                if (this.grants[x].ModuleKeyID == k) {
                    r.canManage = this.grants[x].CanManage;
                    r.label = this.grants[x].ModuleLabel;
                }
            }
            return r;
        },
        errorNotification(msg) {
            UIkit.notification({// eslint-disable-line no-undef
                message: msg == null ? "Si è verificato un errore." : msg,
                status: "danger",
                pos: "top-center",
                timeout: 4000,
            });
        },
        successNotification(msg) {
            UIkit.notification({// eslint-disable-line no-undef
                message: msg == null ? "Operazione eseguita con successo." : msg,
                status: "success",
                pos: "top-center",
                timeout: 4000,
            });
        },
        getRow() {
            if (this.formIsNotValid || !this.synopticSheetGet) {
                return false
            }
            axios({
                method: "get",
                url: process.env.VUE_APP_APIHOST + "/api/v1/synoptic-sheet",
                headers: {
                    Token: this.token
                },
                params: {
                    valueC: this.Search.ValueC,
                    valueS: this.Search.ValueS,
                    valueU: this.Search.ValueU,
                    valueAR: this.Search.ValueAR,
                }
            })
                .then(
                    function (response) {// eslint-disable-line no-unused-vars
                        if (response.data) {
                            this.Result = response.data
                        }
                    }.bind(this)
                )
                .catch(function (error) {// eslint-disable-line no-unused-vars
                    this.errorNotification();
                }.bind(this));
        },
        updateRow() {
            if (!this.synopticSheetWrite) {
                return false
            }
            this.Updating = true
            axios({
                method: "patch",
                url: process.env.VUE_APP_APIHOST + "/api/v1/synoptic-sheet",
                headers: {
                    Token: this.token
                },
                data: this.Result
            })
                .then(
                    function (response) {// eslint-disable-line no-unused-vars
                        if (response.data) {
                            this.successNotification()
                        }
                        this.Updating = false
                    }.bind(this)
                )
                .catch(function (error) {// eslint-disable-line no-unused-vars
                    this.errorNotification();
                    this.Updating = false
                }.bind(this));
        },

    },
    mounted() {

    },
    updated() {

    }
}
</script>
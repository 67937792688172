<template>
  <section v-if="isAdmin || apiUsers.canManage || apiUsersAgent.canManage">
    <div>
      <section>
        <p class="uk-text-lead">Lista utenti App / Area Clienti web</p>
        <form class="uk-card  uk-card-body uk-card-default" @submit.prevent="search()">
          <div class="uk-grid uk-width-1-1">
            <div class="uk-width-1-3@m">
              <div class="uk-margin">
                <input class="uk-input" placeholder="Nome" v-model="Search.Name">
              </div>
              <div class="uk-margin">
                <input class="uk-input" placeholder="Customer code" v-model="Search.CustomerCode">
              </div>
              <div class="uk-margin">
                <input class="uk-input uk-margin-bottom" placeholder="Partita iva / Codice fiscale"
                  v-model="Search.VatFiscalCode">
              </div>
            </div>
            <div class=" uk-width-1-3@m">
              <div class="uk-margin">
                <input class="uk-input" placeholder="Email" v-model="Search.Email">
              </div>
              <div class="uk-margin">
                <input class="uk-input" placeholder="Telefono" v-model="Search.PhoneNumber">
              </div>
              <div class="uk-margin uk-grid">
                <div><label>Email verificata</label></div>
                <div><select v-model="Search.EmailConfirmed" class="uk-select">
                    <option value="-1">Tutte</option>
                    <option value="0">Non verificata</option>
                    <option value="1">Verificata</option>
                  </select></div>
              </div>
              <div class="uk-margin uk-grid">
                <div><label>Telefono verificato</label></div>
                <div><select v-model="Search.PhoneNumberConfirmed" class="uk-select  uk-margin-bottom">
                    <option value="-1">Tutti</option>
                    <option value="0">Non verificato</option>
                    <option value="1">Verificato</option>
                  </select></div>
              </div>
            </div>
            <div class=" uk-width-1-3@m">
              <div class="uk-margin" v-if="isAdmin || apiUsers.CanManage || apiUsersAdmin.CanManage">
                <select v-model="Search.CustomersConnected" class="uk-select">
                  <option value="-1">Tutti gli utenti</option>
                  <option value="1">Utenti con clienti collegati</option>
                  <option value="0">Utenti senza clienti collegati</option>
                </select>
              </div>
              <div class="uk-margin">
                <datepicker :enable-time-picker="false" v-model="Search.RegistrationDateFrom"
                  placeholder="Data registrazione (da)" format="dd/MM/yyyy" cancel-text="Annulla" select-text="OK" />
              </div>
              <div class="uk-margin">
                <datepicker :enable-time-picker="false" v-model="Search.RegistrationDateTo"
                  placeholder="Data registrazione (a)" format="dd/MM/yyyy" cancel-text="Annulla" select-text="OK" />
              </div>
            </div>
          </div>
          <div class="uk-column-1-2@m">
            <button :disabled="Search.Submitting" class="uk-button uk-button-primary uk-margin-top uk-margin-right"
              type="submit">
              <span v-if="!Search.Submitting">Cerca utenti</span><span v-if="Search.Submitting"
                uk-spinner="ratio: 0.7"></span>
            </button>
            <button class="uk-button uk-button-default uk-margin-top" @click="clearSearch()">
              Cancella filtri
            </button>
            <button v-if="isAdmin || apiUsers.CanManage || apiUsersAdmin.CanManage"
              class="uk-align-right@m uk-button uk-button-primary uk-margin-top"
              :disabled="Users.List.length == 0 || !Users.Loaded" @click.prevent="usersExport">Esporta risultati</button>
          </div>
        </form>

        <div class="uk-margin-top" v-if="Users.List.length == 0 && Users.Loaded">
          Nessun utente presente.
        </div>
        <div v-if="Users.List.length > 0" class="uk-margin-top">
          <div v-if="Users.List.length > 0" class="uk-margin-top uk-margin-bottom">
            <span class="uk-margin-right">Pagina: {{ Users.CurrentPage }} di {{ Users.Pages }}</span>
            <button class="uk-button uk-button-default" @click.prevent="usersList(1)" v-if="Users.CurrentPage > 1"><span
                uk-icon="icon: chevron-double-left;"></span></button>
            <button class="uk-button uk-button-default" @click.prevent="usersList(Users.CurrentPage - 1)"
              v-if="Users.CurrentPage > 1"><span uk-icon="icon: chevron-left;"></span></button>
            <button class="uk-button uk-button-default" @click.prevent="usersList(Users.CurrentPage + 1)"
              v-if="Users.CurrentPage < Users.Pages"><span uk-icon="icon: chevron-right;"></span></button>
            <button class="uk-button uk-button-default" @click.prevent="usersList(Users.Pages)"
              v-if="Users.CurrentPage < Users.Pages"><span uk-icon="icon: chevron-double-right;"></span></button>

          </div>
          <div v-for="(user) in Users.List" :key="user['id']"
            class="uk-margin-top uk-padding-small uk-margin-small-left uk-card uk-card-default ">
            <div class="uk-card-body uk-grid uk-child-width-1-3@s">
              <div>
                <h3>{{ user['name'] }} {{ user['surname'] }} </h3>
                <br />
                <span
                  v-if="user['lockoutEnd'] == null || new Date().getTime() > new Date(user['lockoutEnd']).getTime()">Lockout:
                  no</span>
                <div v-if="user['lockoutEnd'] != null && new Date().getTime() < new Date(user['lockoutEnd']).getTime()">
                  Lockout: sì, scade tra {{ Math.round((new Date(user['lockoutEnd']).getTime() - new Date().getTime()) /
                    60000) }} minuti
                  <br>
                  <button @click="userUnlock(user['id'])"
                    class="uk-button uk-button-primary uk-button-small@s">Sblocca</button>
                </div>
                <hr class="uk-hidden@m" />
              </div>

              <div>

                <div class="uk-margin-top-small uk-hidden@s">&nbsp;</div>
                <div v-if="user['emailConfirmed']">
                  {{ user['email'] }}
                  <br />
                  Email verificata
                </div>
                <div v-if="!user['emailConfirmed']">

                  {{ user['email'] }}
                  <div>
                    <div>
                      <form class="uk-form-horizontal bg-box uk-padding-small">
                        <span>Email non verificata</span>
                        <br>
                        <button
                          class="uk-button uk-button-primary uk-button-small uk-margin-small-top uk-margin-small-bottom"
                          @click.prevent="userValidateEmail(user['id'])">Invia OTP</button>
                        <br>
                        <input class="uk-input uk-form-width-small" placeholder="OTP" v-model="user['emailOtp']" /><button
                          class="uk-button uk-button-primary"
                          @click.prevent="userCompleteEmailValidation(user['id'], user['emailOtp'])">Verifica
                          OTP</button>

                      </form>

                    </div>
                  </div>

                </div>
                <hr class="uk-hidden@s uk-margin-top" />
              </div>
              <div>
                <div class="uk-margin-top-small uk-hidden@s">&nbsp;</div>
                <div v-if="user['phoneNumberConfirmed']">
                  {{ user['phoneNumber'] }}
                  <br />
                  Telefono verificato
                </div>
                <div v-if="!user['phoneNumberConfirmed']">

                  {{ user['phoneNumber'] }}
                  <div>
                    <div>
                      <form class="uk-form-horizontal bg-box uk-padding-small">
                        <span>Telefono non verificato</span>
                        <br>
                        <button
                          class="uk-button uk-button-primary uk-button-small uk-margin-small-top uk-margin-small-bottom"
                          @click.prevent="userValidatePhoneNumber(user['id'])">Invia OTP</button>
                        <br>
                        <input class="uk-input uk-form-width-small" placeholder="OTP" v-model="user['smsOtp']" /><button
                          class="uk-button uk-button-primary"
                          @click.prevent="userCompletePhoneNumberValidation(user['id'], user['smsOtp'])">Verifica
                          OTP</button>
                      </form>

                    </div>
                  </div>

                </div>
              </div>


              <div class="uk-width-expand uk-margin-top uk-margin-bottom">
                <div v-if="user['customers'] == null || user['customers']['length'] == 0">Nessun cliente associato <a
                    href="" @click.prevent="userContractConnectOpenModal(user['id'])">Aggiungi contratto</a></div>
                <div v-if="user['customers'] != null && user['customers']['length'] > 0">
                  <div>
                    <a href="" @click.prevent="userContractConnectOpenModal(user['id'])">Aggiungi contratto</a>
                  </div>
                  <ul uk-accordion>
                    <li class="uk-closed">
                      <a class="uk-accordion-title" href="javascript:;">Clienti ({{ user['customers']['length']
                      }})</a>
                      <div class="uk-accordion-content">
                        <table class="uk-table uk-table-striped uk-table-middle">
                          <thead>
                            <tr>
                              <th>Nome cliente</th>
                              <th>Codice cliente</th>
                              <th>P.Iva/ C.F.</th>
                              <th></th>
                              <th v-if="isAdmin || apiUsers.canManage"></th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(customer) in user['customers']" :key="customer['vendorCustomerId']">
                              <td>{{ customer['customerName'] }}</td>
                              <td>{{ customer['customerCode'] }}</td>
                              <td>{{ customer['vatFiscalCode'] }}</td>
                              <td><a href="javascript:;"
                                  @click.prevent="userContractDisconnect(user, customer)">Disconnetti</a></td>
                              <td v-if="isAdmin || apiUsers.canManage"><a href="javascript:;"
                                  @click.prevent="userContractGetChats('customerCode', customer['customerCode'])">Chat
                                  intercorse</a></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </li>
                  </ul>


                </div>
              </div>
            </div>
            <div class="uk-card-footer">

              <a class="uk-align-right@m uk-margin-remove-bottom" @click.prevent="userSSO(user['id'])"
                target="_blank">Area clienti</a>

              <a href="#modal-user-delete" uk-toggle v-if="isAdmin || apiUsersAdmin.CanManage"
                @click.prevent="setUserDeletionId(user['id'])"
                class="uk-align-right@m uk-margin-remove-bottom uk-margin-left@m uk-margin-top@s">Elimina utente</a>

              <a class="uk-align-right@m uk-margin-remove-bottom uk-margin-left@m uk-margin-top@s"
                v-if="isAdmin || apiUsers.canManage" href="javascript:;"
                @click.prevent="userContractGetChats('email', user['email'])">Chat intercorse</a>
            </div>
          </div>
        </div>
        <div v-if="Users.List.length > 0" class="uk-margin-top uk-margin-bottom">
          <span class="uk-margin-right">Pagina: {{ Users.CurrentPage }} di {{ Users.Pages }}</span>
          <button class="uk-button uk-button-default" @click.prevent="usersList(1)" v-if="Users.CurrentPage > 1"><span
              uk-icon="icon: chevron-double-left;"></span></button>
          <button class="uk-button uk-button-default" @click.prevent="usersList(Users.CurrentPage - 1)"
            v-if="Users.CurrentPage > 1"><span uk-icon="icon: chevron-left;"></span></button>
          <button class="uk-button uk-button-default" @click.prevent="usersList(Users.CurrentPage + 1)"
            v-if="Users.CurrentPage < Users.Pages"><span uk-icon="icon: chevron-right;"></span></button>
          <button class="uk-button uk-button-default" @click.prevent="usersList(Users.Pages)"
            v-if="Users.CurrentPage < Users.Pages"><span uk-icon="icon: chevron-double-right;"></span></button>

        </div>
      </section>



    </div>
  </section>
  <section>
    <!--modals-->
    <div id="modal-user-delete" uk-modal>
      <div class="uk-modal-dialog">
        <button class="uk-modal-close-default" type="button" uk-close></button>
        <div class="uk-modal-header">
          <h2 class="uk-modal-title">Eliminazione utente</h2>
        </div>
        <div class="uk-modal-body">
          <p>Procedendo con l'eliminazione, verrà eliminato l'utente (le sue credenziali di accesso) e le associazioni ai
            contratti ad esso collegati.
            <br />
            Sicuro di voler procedere?
          </p>
        </div>
        <div class="uk-modal-footer uk-text-right">
          <button class="uk-button uk-button-default uk-modal-close" type="button">Annulla</button>
          <button class="uk-button uk-button-primary" type="button" @click.prevent="userDelete()">Elimina utente</button>
        </div>
      </div>
    </div>

    <div id="modal-user-contract-connect" uk-modal :class="{ 'uk-modal uk-open': UserContractConnectDisplayModal }"
      :style="{ 'display': UserContractConnectDisplayModal ? 'block' : 'none' }">
      <div class="uk-modal-dialog">
        <button class="uk-modal-close-default" type="button" uk-close></button>
        <div class="uk-modal-header">
          <h2 class="uk-modal-title">Aggiungi contratto</h2>
        </div>
        <div class="uk-modal-body">
          <div class="uk-margin">
            <input class="uk-input" placeholder="Codice cliente" :readonly="UserContractConnectOTPSent"
              @change="userContractConnectParamsChanged()" v-model="UserContractConnectCustomerCode">
          </div>
          <div class="uk-margin">
            <input class="uk-input" placeholder="Codice fiscale / Partita iva" :readonly="UserContractConnectOTPSent"
              @change="userContractConnectParamsChanged()" v-model="UserContractConnectVatFiscalCode">
          </div>
          <div class="uk-margin" v-if="UserContractConnectOTPSent == false">
            <button :disabled="!userContractConnectStepOneValidate || UserContractConnectSubmitting"
              class="uk-button uk-button-primary" @click.prevent="userContractConnectStepOne()">
              <span v-if="UserContractConnectSubmitting" uk-spinner="ratio: 0.7"></span>
              <span v-if="!UserContractConnectSubmitting">Invia OTP alla mail contrattuale</span>
            </button>
            <p v-if="userContractConnectStepOneValidate" class="uk-text-meta">L'OTP verrà inviato alla mail contrattuale
              {{ UserContractConnectContractEmail }}</p>
          </div>
          <div class="uk-margin" v-if="UserContractConnectOTPSent == true">
            <p class="uk-margin-bottom">Il codice OTP è stato inviato al cliente. </p>
            <input class="uk-input uk-form-horizontal uk-form-width-small" v-model="UserContractConnectOTP"
              placeholder="Codice OTP">
            <button class="uk-button uk-button-primary uk-form-horizontal" :disabled="UserContractConnectSubmitting"
              @click.prevent="userContractConnectStepTwo()">
              <span v-if="UserContractConnectSubmitting" uk-spinner="ratio: 0.7"></span>
              <span v-if="!UserContractConnectSubmitting">Associa cliente</span>
            </button>
          </div>
        </div>
        <div class="uk-modal-footer uk-text-right">
          <button class="uk-button uk-button-default uk-modal-close" type="button">Chiudi finestra</button>
        </div>
      </div>
    </div>

    <div id="modal-chat-list" class="uk-modal-full" style="background-color: white;" uk-modal="container:false">
      <div class="uk-modal-dialog uk-height-1-1">
        <button class="uk-modal-close-full uk-close-large" type="button" uk-close></button>
        <div class="uk-modal-header">
          <h2 class="uk-modal-title">Lista chat intercorse</h2>
        </div>
        <div class="uk-modal-body chat-list-body">
          <div class="chat-list-container" style="overflow-y:scroll">
            <table class="uk-table uk-table-striped">
              <thead>
                <th>Operatore<br>Stato ticket</th>
                <th>Email<br>Stato registrazione</th>
                <th>Nome utente<br>Codice cliente</th>
                <th></th>
              </thead>
              <tbody>
                <tr v-for="item, index in ChatLog" :key="item['id']">
                  <td>
                    {{ item['agentName'] }}
                    <br>
                    <span v-if="item['ticketIsClosed'] == true">Ticket chiuso</span>
                    <span v-if="item['ticketIsClosed'] != true">Ticket aperto</span>
                    <br>
                    {{ item['chatStartedAtFormatted'] }}
                  </td>
                  <td>
                    {{ item['email'] }}
                    <br>
                    <span v-if="item['userId'] != ''">Utente registrato</span>
                    <span v-if="item['userId'] == ''">Utente prospect o non verificato</span>
                  </td>
                  <td>
                    {{ item['customerName'] }}
                    <br>
                    {{ item['customerCode'] }}
                  </td>
                  <td>
                    <button class="uk-button uk-buttom-primary" @click.prevent="showChatThread(index)">Vedi chat</button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div uk-modal="stack: true, container: false" id="modal-chat-detail">
      <div class="uk-modal-dialog uk-margin-auto-vertical">
        <button class="uk-modal-close-default uk-close-large" type="button" uk-close></button>
        <div class="chatbox uk-container-small uk-padding-small">
          <div class="thread chat-selected-thread uk-width-1-1">
            <div class="uk-clearfix" v-for="item in ChatSelectedThread" :key="item">
              <div :class="item['side'] == 'L' ? 'uk-align-left' : 'uk-align-right uk-margin-right'">
                <div class="uk-text-meta">{{ item['atFormatted'] }}
                </div>
                <div class="log ">{{
                  item['msg'] }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </section>
</template>

<style scoped>
.bg-box {
  border: solid 1px #cccccc;
}

.chatbox {
  background-color: #eee;
}

.chat-list-body {
  height: 85%;
}

.chat-list-container {
  overflow-y: scroll;
  /* Firefox */
  height: -moz-calc(100% - 100px);
  /* WebKit */
  height: -webkit-calc(100% - 100px);
  /* Opera */
  height: -o-calc(100% - 100px);
  /* Standard */
  height: calc(100% - 100px);
}

.chat-selected-thread {
  margin-top: 50px;
}

.log {
  padding: 8px !important;
  color: white;
  max-width: 85%;
}

.uk-align-right>.log {
  background-color: rgb(38, 86, 114) !important;
  border-radius: 5px 5px 0 5px !important;
}

.uk-align-left>.log {
  background-color: rgb(241, 161, 99) !important;
  border-radius: 5px 5px 5px 0 !important;
}

.thread {
  border-bottom: solid 1px #ccc;
  margin-bottom: 10px;
  height: 400px;
  max-height: 400px;
  overflow-y: scroll;
}
</style>

<script lang="ts">
const axios = require("axios").default;
import commonFunctions from "../../functions/common.js"; // eslint-disable-line no-unused-vars
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import UIkit from 'uikit'



export default {
  name: "ApiUsers_List",
  components: { Datepicker },
  computed: {
    token() {
      return this.$store.state.token;
    },
    email() {
      return this.$store.state.email;
    },
    isAdmin() {
      return this.$store.state.admin;
    },
    grants() {
      return this.$store.state.grants;
    },
    apiUsers() {
      return this.getGrants("apiusers")
    },
    apiUsersAdmin() {
      return this.getGrants("apiusersadmin")
    },
    apiUsersAgent() {
      return this.getGrants("apiusersagent")
    },
    userContractConnectStepOneValidate() {
      return this.UserContractConnectCustomerCode != "" &&
        this.UserContractConnectVatFiscalCode != "" &&
        this.UserContractConnectContractEmail != ""
    },
    userContractConnectStepTwoValidate() {
      return this.UserContractConnectOTP != ""
    },


  },

  data() {
    return {

      APIHost: process.env.VUE_APP_APIHOST,
      ReservedAreaHost: process.env.VUE_APP_RESERVED_AREA_HOST,
      Users: {
        List: [],
        Pages: 0,
        CurrentPage: 0,
        Loaded: false
      },
      Search: {
        Name: "",
        CustomerCode: "",
        Email: "",
        PhoneNumber: "",
        VatFiscalCode: "",
        EmailConfirmed: -1,
        PhoneNumberConfirmed: -1,
        CustomersConnected: -1,
        RegistrationDateFrom: '',
        RegistrationDateTo: '',
        Submitting: false
      },
      UserDeletionId: "",
      UserContractConnectUserId: "",
      UserContractConnectVendorCustomerId: 0,
      UserContractConnectCustomerCode: "",
      UserContractConnectVatFiscalCode: "",
      UserContractConnectContractEmail: "",
      UserContractConnectOTP: "",
      UserContractConnectOTPSent: false,
      UserContractConnectSubmitting: false,
      UserContractConnectDisplayModal: false,
      ChatLog: [],
      ChatSelectedThread: []
    };
  },
  methods: {
    getGrants(k) {
      var r = {
        canManage: false,
        label: ""
      }
      for (var x = 0; x < this.grants.length; x++) {
        if (this.grants[x].ModuleKeyID == k) {
          r.canManage = this.grants[x].CanManage;
          r.label = this.grants[x].ModuleLabel;
        }
      }
      return r;
    },
    errorNotification(msg) {
      UIkit.notification({// eslint-disable-line no-undef
        message: msg == null ? "Si è verificato un errore." : msg,
        status: "danger",
        pos: "top-center",
        timeout: 4000,
      });
    },
    successNotification(msg) {
      UIkit.notification({// eslint-disable-line no-undef
        message: msg == null ? "Operazione eseguita con successo." : msg,
        status: "success",
        pos: "top-center",
        timeout: 4000,
      });
    },
    search() {
      this.Search.Submitting = true
      this.usersList(1);
    },
    searchByEmail(email) {
      this.clearSearch();
      this.Search.Email = email;
      this.search();
    },
    clearSearch() {
      this.Search.Name = ""
      this.Search.CustomerCode = ""
      this.Search.Email = ""
      this.Search.PhoneNumber = ""
      this.Search.VatFiscalCode = ""
      this.Search.CustomersConnected = -1
      this.Search.EmailConfirmed = -1
      this.Search.PhoneNumberConfirmed = -1
      this.Search.RegistrationDateFrom = ''
      this.Search.RegistrationDateTo = ''
    },
    usersListRefresh() {
      this.usersList(this.Users.CurrentPage);
    },
    userUnlock(userId) {
      axios({
        method: "patch",
        url: this.APIHost + "/api/v1/api-users/unlock",
        params: {
          UserId: userId
        },
        headers: {
          Token: this.token
        }
      })
        .then(
          function (response) {// eslint-disable-line no-unused-vars
            console.log("userunlock", response.data)
            if (response.data == true) {
              this.successNotification()
              this.usersListRefresh()
            } else {
              this.errorNotification()
            }
          }.bind(this)
        )
        .catch(function (error) {// eslint-disable-line no-unused-vars
          this.errorNotification();
        });
    },
    userContractConnectOpenModal(userId) {
      this.UserContractConnectUserId = userId
      this.UserContractConnectVendorCustomerId = 0
      this.UserContractConnectCustomerCode = ""
      this.UserContractConnectVatFiscalCode = ""
      this.UserContractConnectContractEmail = ""
      this.UserContractConnectOTP = ""
      this.UserContractConnectOTPSent = false
      //UIkit.modal('#modal-user-contract-connect').show();     
      this.UserContractConnectDisplayModal = true
    },

    userContractConnectParamsChanged() {
      this.UserContractConnectVendorCustomerId = 0
      this.UserContractConnectContractEmail = ""
      if (
        this.UserContractConnectCustomerCode != "" &&
        this.UserContractConnectVatFiscalCode != ""
      ) {
        axios({
          method: "get",
          url: this.APIHost + "/api/v1/api-users/connect-customer-contract-email",
          params: {
            CustomerCode: this.UserContractConnectCustomerCode,
            VatFiscalCode: this.UserContractConnectVatFiscalCode
          },
          headers: {
            Token: this.token
          }
        })
          .then(
            function (response) {// eslint-disable-line no-unused-vars
              if (response && response.data) {
                console.log(response.data)
                this.UserContractConnectContractEmail = response.data.email
                this.UserContractConnectVendorCustomerId = response.data.vendorCustomerId
              }
            }.bind(this)
          )
          .catch(function (error) {// eslint-disable-line no-unused-vars

          });
      }

    },
    userContractDisconnect(user, customer) {
      axios({
        method: "patch",
        url: this.APIHost + "/api/v1/api-users/disconnect-customer",
        params: {
          UserId: user["id"],
          VendorCustomerId: customer["vendorCustomerId"],
          CustomerCode: customer["customerCode"],
          VatFiscalCode: customer["vatFiscalCode"]
        },
        headers: {
          Token: this.token
        }
      })
        .then(
          function (response) {// eslint-disable-line no-unused-vars
            //console.log("userContractDisconnect", response.data)
            if (response.data == true) {
              this.successNotification()
              this.usersListRefresh()
            } else {
              this.errorNotification()
            }
          }.bind(this)
        )
        .catch(function (error) {// eslint-disable-line no-unused-vars
          this.errorNotification();
        });
    },
    userContractConnectStepOne() {
      this.UserContractConnectSubmitting = true
      axios({
        method: "patch",
        url: this.APIHost + "/api/v1/api-users/connect-customer",
        params: {
          UserId: this.UserContractConnectUserId,
          VendorCustomerId: this.UserContractConnectVendorCustomerId,
          CustomerCode: this.UserContractConnectCustomerCode,
          VatFiscalCode: this.UserContractConnectVatFiscalCode,
          VerificationEmailAddress: ''
        },
        headers: {
          Token: this.token
        }
      })
        .then(
          function (response) {// eslint-disable-line no-unused-vars
            this.UserContractConnectSubmitting = false
            if (response.data == true) {
              console.log("userContractConnectStepOne", response.data)
              this.UserContractConnectOTPSent = true
            } else {
              this.errorNotification()
            }
          }.bind(this)
        )
        .catch(function (error) {// eslint-disable-line no-unused-vars
          this.UserContractConnectSubmitting = false
          this.errorNotification();
        }.bind(this))
        ;
    },
    userContractConnectStepTwo() {
      this.UserContractConnectSubmitting = true
      axios({
        method: "patch",
        url: this.APIHost + "/api/v1/api-users/customer-verify-email",
        params: {
          UserId: this.UserContractConnectUserId,
          VendorCustomerId: this.UserContractConnectVendorCustomerId,
          CustomerCode: this.UserContractConnectCustomerCode,
          VatFiscalCode: this.UserContractConnectVatFiscalCode,
          OTP: this.UserContractConnectOTP
        },
        headers: {
          Token: this.token
        }
      })
        .then(
          function (response) {// eslint-disable-line no-unused-vars
            this.UserContractConnectSubmitting = false
            if (response.data == true) {
              this.successNotification()
              this.usersListRefresh()
              //UIkit.modal('#modal-user-contract-connect').hide();
              this.UserContractConnectDisplayModal = false
            } else {
              this.errorNotification()
            }
          }.bind(this)
        )
        .catch(function (error) {// eslint-disable-line no-unused-vars
          this.UserContractConnectSubmitting = false
          this.errorNotification();
        }.bind(this))
        ;
    },

    userContractGetChats(paramType, paramValue) {
      axios({
        method: "get",
        url: this.APIHost + "/api/v1/chats",
        params: {
          UserId: paramType == 'userId' ? paramValue : '',
          CustomerCode: paramType == 'customerCode' ? paramValue : '',
          Email: paramType == 'email' ? paramValue : ''
        },
        headers: {
          Token: this.token
        }
      })
        .then(
          function (response) {// eslint-disable-line no-unused-vars
            if (response.data.length == 0) {
              this.successNotification('Nessuna chat intercorsa.')
            }
            else {
              this.ChatLog = response.data
              UIkit.modal('#modal-chat-list').show()
            }
          }.bind(this)
        )
        .catch(function (error) {// eslint-disable-line no-unused-vars
          this.errorNotification();
        });
    },

    showChatThread(index) {
      this.ChatSelectedThread = this.ChatLog[index]['thread']
      UIkit.modal('#modal-chat-detail').show()
    },

    usersList(pageNumber) {
      axios({
        method: "get",
        url: this.APIHost + "/api/v1/api-users",
        params: {
          PageNumber: pageNumber,
          Name: this.Search.Name,
          CustomerCode: this.Search.CustomerCode,
          Email: this.Search.Email,
          Phone: this.Search.PhoneNumber,
          VatFiscalCode: this.Search.VatFiscalCode,
          EmailConfirmed: this.Search.EmailConfirmed,
          PhoneNumberConfirmed: this.Search.PhoneNumberConfirmed,
          CustomersConnected: this.Search.CustomersConnected,
          RegistrationDateFrom: this.Search.RegistrationDateFrom,
          RegistrationDateTo: this.Search.RegistrationDateTo,
        },
        headers: {
          Token: this.token
        }
      })
        .then(
          function (response) {// eslint-disable-line no-unused-vars
            this.Users.List = [];
            var x;
            for (x = 0; x < response.data.length; x++) {
              this.Users.List.push(response.data[x]);
            }
            this.Users.CurrentPage = pageNumber;
            var pgs = 0;
            if (this.Users.List.length > 0) {
              pgs = this.Users.List[0].pages;
              this.Users.Pages = pgs;
            }
            this.Users.Loaded = true;
            this.Search.Submitting = false
            window.scrollTo({ top: 300, left: 0, behavior: 'smooth' })
          }.bind(this)
        )
        .catch(function (error) {// eslint-disable-line no-unused-vars
          this.errorNotification();
          this.Search.Submitting = false
        });
    },
    setUserDeletionId(userId) {
      this.UserDeletionId = userId
    },
    userDelete() {
      UIkit.modal('#modal-user-delete').hide(); // eslint-disable-line no-undef
      axios({
        method: "delete",
        url: this.APIHost + "/api/v1/api-users/delete",
        params: {
          userId: this.UserDeletionId
        },
        headers: {
          Token: this.token
        }
      })
        .then(
          function (response) {// eslint-disable-line no-unused-vars
            if (response.data == true) {
              this.successNotification()
              this.usersListRefresh()
            } else {
              this.errorNotification();
            }
          }.bind(this)
        )
        .catch(function (error) {// eslint-disable-line no-unused-vars
          this.errorNotification();
        });
    },
    userValidateEmail(userId) {
      axios({
        method: "post",
        url: this.APIHost + "/api/v1/api-users/validate-email",
        params: {
          userId: userId
        },
        headers: {
          Token: this.token
        }
      })
        .then(
          function (response) {// eslint-disable-line no-unused-vars
            if (response.data == true) {
              UIkit.notification({// eslint-disable-line no-undef
                message: "Messaggio email inviato all'utente. Completare l'operazione inserendo il codice OTP.",
                status: "success",
                pos: "top-center",
                timeout: 7000,
              });
            } else {
              this.errorNotification();
            }
          }.bind(this)
        )
        .catch(function (error) {// eslint-disable-line no-unused-vars
          this.errorNotification();
        });
    },
    userCompleteEmailValidation(userId, emailOtp) {
      axios({
        method: "patch",
        url: this.APIHost + "/api/v1/api-users/complete-email-validation",
        params: {
          userId: userId,
          otp: emailOtp
        },
        headers: {
          Token: this.token
        }
      })
        .then(
          function (response) {// eslint-disable-line no-unused-vars
            if (response.data == true) {
              this.successNotification()
              this.usersListRefresh()
            } else {
              this.errorNotification("Verifica non completata. Verificare il codice OTP e riprovare.");
            }
          }.bind(this)
        )
        .catch(function (error) {// eslint-disable-line no-unused-vars
          this.errorNotification();
        });
    },
    userValidatePhoneNumber(userId) {
      axios({
        method: "post",
        url: this.APIHost + "/api/v1/api-users/validate-phone-number",
        params: {
          userId: userId
        },
        headers: {
          Token: this.token
        }
      })
        .then(
          function (response) {// eslint-disable-line no-unused-vars
            if (response.data == true) {
              UIkit.notification({// eslint-disable-line no-undef
                message: "Messaggio SMS inviato all'utente. Completare l'operazione inserendo il codice OTP.",
                status: "success",
                pos: "top-center",
                timeout: 7000,
              });
            } else {
              this.errorNotification();
            }
          }.bind(this)
        )
        .catch(function (error) {// eslint-disable-line no-unused-vars
          this.errorNotification();
        });
    },
    userCompletePhoneNumberValidation(userId, smsOtp) {
      axios({
        method: "patch",
        url: this.APIHost + "/api/v1/api-users/complete-phone-number-validation",
        params: {
          userId: userId,
          otp: smsOtp
        },
        headers: {
          Token: this.token
        }
      })
        .then(
          function (response) {// eslint-disable-line no-unused-vars
            if (response.data == true) {
              this.successNotification()
              this.usersListRefresh()
            } else {
              this.errorNotification("Verifica non completata. Verificare il codice OTP e riprovare.");
            }
          }.bind(this)
        )
        .catch(function (error) {// eslint-disable-line no-unused-vars
          this.errorNotification();
        });
    },
    userSSO(userId) {
      axios({
        method: "get",
        url: this.APIHost + "/api/v1/api-users/get-sso-token",
        params: {
          userId: userId
        },
        headers: {
          Token: this.token
        }
      })
        .then(
          function (response) {// eslint-disable-line no-unused-vars
            if (response.data != "") {
              window.open(this.ReservedAreaHost.replace("[SSO]", response.data), "_blank_sso")
            } else {
              this.errorNotification();
            }
          }.bind(this)
        )
        .catch(function (error) {// eslint-disable-line no-unused-vars
          this.errorNotification();
        });
    },

    usersExport() {
      axios({
        method: "get",
        url: this.APIHost + "/api/v1/api-users-export",
        params: {
          Email: this.email,
          Name: this.Search.Name,
          CustomerCode: this.Search.CustomerCode,
          UserEmail: this.Search.Email,
          Phone: this.Search.PhoneNumber,
          VatFiscalCode: this.Search.VatFiscalCode,
          EmailConfirmed: this.Search.EmailConfirmed,
          PhoneNumberConfirmed: this.Search.PhoneNumberConfirmed,
          CustomersConnected: this.Search.CustomersConnected,
          RegistrationDateFrom: this.Search.RegistrationDateFrom,
          RegistrationDateTo: this.Search.RegistrationDateTo,
        },
        headers: {
          Token: this.token
        }
      })
        .then(
          function (response) {// eslint-disable-line no-unused-vars
            if (response.data == "started") {
              this.successNotification("Procedura di export avviata. Attendere notifica via email.")
            }
            if (response.data == "error") {
              this.errorNotification()
            }
          }.bind(this)
        )
        .catch(function (error) {// eslint-disable-line no-unused-vars
          this.errorNotification();
        }.bind(this));
    },


  },
  mounted() {
    //this.usersList(1);
  },
  updated() {

  }
};
</script>

<template>
    <div class="uk-text-meta uk-margin-bottom">
        <router-link to="/self-readings">Gestione autoletture</router-link><span
            uk-icon="chevron-right"></span><span>Finestre temporali autoletture</span>
    </div>
    <div>
        <h4>Finestre temporali autoletture</h4>
        <div class="uk-margin-top">
            Calendario finestre <span class="uk-text-bolder">elettrico</span>:
            <datepicker v-model="timeWindowsEE" multi-dates :clearable="false" :enable-time-picker="false"
                format="dd/MM/yyyy" cancel-text="Annulla" select-text="OK" />
        </div>
        <div class="uk-margin-top">
            Calendario finestre <span class="uk-text-bolder">GAS</span>:
            <datepicker v-model="timeWindowsGAS" multi-dates :clearable="false" :enable-time-picker="false"
                format="dd/MM/yyyy" cancel-text="Annulla" select-text="OK" />
        </div>

        <div class="uk-margin-top">
            <button class="uk-button uk-button-primary" @click.prevent="setTimeWindows">Salva finestre temporali</button>
        </div>
    </div>
</template> 
<script>
//import { ref } from 'vue';
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
const axios = require("axios").default; // eslint-disable-line no-unused-vars
import commonFunctions from "../../functions/common.js"; // eslint-disable-line no-unused-vars
import UIkit from 'uikit' // eslint-disable-line no-unused-vars



export default {
    name: "SelfReadings_TimeWindows",
    components: { Datepicker },
    computed: {
        token() {
            return this.$store.state.token;
        },
        email() {
            return this.$store.state.email
        }
    },
    data() {
        return {
            timeWindowsEE: [],
            timeWindowsGAS: [],
            //[ "2023-07-16T00:00:00.000Z", "2023-07-12T00:00:00.000Z" ],
        }

    },
    methods: {
        errorNotification(msg) {
            UIkit.notification({// eslint-disable-line no-undef
                message: msg == null ? "Si è verificato un errore." : msg,
                status: "danger",
                pos: "top-center",
                timeout: 4000,
            });
        },
        successNotification(msg) {
            UIkit.notification({// eslint-disable-line no-undef
                message: msg == null ? "Operazione eseguita con successo." : msg,
                status: "success",
                pos: "top-center",
                timeout: 4000,
            });
        },
        setFormValidationAttributes() {
            this.formValidation.exportEmail = commonFunctions.isEmail(this.exportEmail)
        },
        getGrants(k) {
            var r = {
                canManage: false,
                label: ""
            }
            for (var x = 0; x < this.grants.length; x++) {
                if (this.grants[x].ModuleKeyID == k) {
                    r.canManage = this.grants[x].CanManage;
                    r.label = this.grants[x].ModuleLabel;
                }
            }
            return r;
        },
        getTimeWindows() {
            axios({
                method: "get",
                url: process.env.VUE_APP_APIHOST + "/api/v1/self-readings-time-windows",
                headers: {
                    Token: this.token
                }
            })
                .then(
                    function (response) {// eslint-disable-line no-unused-vars
                        if (response.data) {
                            console.log(response.data)
                            this.timeWindowsEE = response.data.timeWindowsEE
                            this.timeWindowsGAS = response.data.timeWindowsGAS
                        }
                    }.bind(this)
                )
                .catch(function (error) {// eslint-disable-line no-unused-vars
                    this.errorNotification();
                }.bind(this));

        },
        setTimeWindows() {
            axios({
                method: "patch",
                url: process.env.VUE_APP_APIHOST + "/api/v1/self-readings-time-windows",
                data: {
                    TimeWindowsEE: this.timeWindowsEE,
                    TimeWindowsGAS: this.timeWindowsGAS,
                },
                headers: {
                    Token: this.token
                }
            })
                .then(
                    function (response) {// eslint-disable-line no-unused-vars
                        if (response.data) {
                            this.successNotification("Finestre temporali salvate con successo.")
                            this.getTimeWindows()
                        }
                    }.bind(this)
                )
                .catch(function (error) {// eslint-disable-line no-unused-vars
                    this.errorNotification();
                }.bind(this));

        }
    },
    mounted() {
        this.getTimeWindows()
    },
    updated() {

    }
}
</script> 
<style scoped></style> 




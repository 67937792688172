<template>
    <div>
        <div class="uk-text-lead uk-grid uk-margin-bottom uk-width-1-1">
            <div class="uk-width-1-2">
                Utenti in coda: {{ QueueTotal }}
            </div>
            <div class="uk-width-1-2">
                <div class="uk-align-right">
                    <div v-if="!Started">
                        <!-- <input class="uk-input" v-model="Connect.Name" placeholder="Il tuo nome" /> -->
                        <button class="uk-button uk-button-primary" v-on:click.prevent="startAgent">Imposta come
                            disponibile</button>
                    </div>
                    <div v-if="Started">
                        <button class="uk-button uk-button-primary" v-on:click.prevent="stopAgent">Imposta come non
                            disponibile</button>
                    </div>
                </div>

            </div>
        </div>


        <div v-if="Started">
            <div v-if="!ConnectedWithCounterPart">
                <button class="uk-button uk-button-primary" :disabled="QueueTotal == 0"
                    v-on:click.prevent="startConversation">Chatta con il primo cliente in
                    coda</button>
            </div>
        </div>

        <div v-if="Started && ConnectedWithCounterPart">
            <div class="uk-box-shadow-medium uk-margin uk-padding">
                <div class="uk-grid">
                    <div class="uk-width-1-2">
                        <table class="uk-table uk-table-striped">
                            <thead>
                                <tr>
                                    <th colspan="2">Dati comunicati login chat</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td class="uk-width-auto">Nome: </td>
                                    <td class="uk-width-expand">{{ ClientName }}
                                        <button @click.prevent="userContractGetChats('email', ClientEmail)" class="uk-button uk-button-primary uk-margin-left">
                                            Chat intercorse
                                        </button>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="uk-width-auto">Email: </td>
                                    <td class="uk-width-expand">{{ ClientEmail }}</td>
                                </tr>
                                <tr>
                                    <td class="uk-width-auto">Codice cliente: </td>
                                    <td class="uk-width-expand">{{ ClientCustomerCode }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="uk-width-1-2">
                        <div v-if="!UserDataAvailable">
                            <table class="uk-table uk-table-striped">
                                <thead>
                                    <tr>
                                        <th>Dati rilevati</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td class="uk-width-expand">Nessun dato disponibile</td>
                                    </tr>
                                </tbody>
                            </table>

                        </div>
                        <div v-if="UserDataAvailable">
                            <table class="uk-table uk-table-striped">
                                <thead>
                                    <tr>
                                        <th colspan="2">Dati rilevati</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td class="uk-width-auto">Nome: </td>
                                        <td class="uk-width-expand">{{ UserData["name"] }}</td>
                                    </tr>
                                    <tr>
                                        <td class="uk-width-auto">Cognome: </td>
                                        <td class="uk-width-expand">{{ UserData["surname"] }}</td>
                                    </tr>
                                    <tr>
                                        <td class="uk-width-auto">Clienti: </td>
                                        <td class="uk-width-expand">
                                            <a href="#modal-user-customers" uk-toggle>{{ UserData["customers"].length }}</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="2">
                                            <button class="uk-button uk-button-primary" @click.prevent="userSSO()">Area
                                                clienti</button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>


            </div>

            <div uk-grid class="uk-container">
                <div class="uk-width-3-4">


                    <div class="chatbox uk-container-small uk-padding-small">
                        <div ref="thread" class="thread uk-width-1-1">
                            <div class="uk-clearfix" v-for="item in Logs" :key="item">
                                <div :class="item.side == 'L' ? 'uk-align-left' : 'uk-align-right uk-margin-right'">
                                    <div class="uk-text-meta">{{ item.time.getHours() + ':' + item.time.getMinutes() }}
                                    </div>
                                    <div class="log ">{{
                                        item.message }}
                                    </div>
                                </div>
                            </div>

                        </div>
                        <form @submit.prevent="sendMessage" class="message uk-grid">
                            <div class="uk-width-expand">
                                <input class="uk-input" v-model="Send.Message" placeholder="Messaggio" />
                            </div>
                            <div class="uk-width-auto">
                                <img class="send-button" src="../../assets/images/send-button.png"
                                    v-on:click.prevent="sendMessage" title="Invia messaggio">
                            </div>

                        </form>
                        <div class="uk-margin-top">
                            <div class="uk-button-group">
                                <div class="uk-inline">
                                    <button class="uk-button uk-button-default" type="button"><span
                                            uk-icon="icon:  triangle-down">Termina chat</span></button>
                                    <div uk-dropdown="mode: click; target: !.uk-button-group;">
                                        <ul class="uk-nav uk-dropdown-nav">
                                            <li>
                                                <button class="uk-text-nowrap uk-button uk-margin-bottom"
                                                    v-on:click.prevent="stopConversation(true, true)">Termina e
                                                    invia ticket chiuso</button>
                                            </li>
                                            <li>
                                                <button class="uk-text-nowrap uk-button uk-margin-bottom"
                                                    v-on:click.prevent="stopConversation(true, false)">Termina
                                                    e invia ticket aperto</button>
                                            </li>
                                            <li>
                                                <button class="uk-text-nowrap uk-button uk-margin-bottom"
                                                    v-on:click.prevent="stopConversation(false, false)">Termina senza
                                                    inviare</button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <!-- <button class="uk-button uk-button-secondary"
                                v-on:click.prevent="stopConversation">Termina chat</button> -->

                        </div>
                    </div>
                </div>
                <div class="uk-width-1-4">
                    <div class="uk-text-bolder uk-margin-bottom">Messaggi ricorrenti</div>

                    <div class="uk-margin-bottom">
                        <form @submit.prevent="addRecurringSentence">
                            <input class="uk-input" placeholder="Aggiungi messaggio ricorrente"
                                v-model="NewRecurringSentence">
                        </form>
                    </div>
                    <div v-for="item, index in RecurringSentences" :key="item" class="sentences">
                        <div class="sentence">
                            <table>
                                <tr>
                                    <td class="td-trash">
                                        <div @click.prevent="removeSentence(index)" uk-icon="icon: trash; ratio: 0.8"
                                            class="trash"></div>
                                    </td>
                                    <td>
                                        <button type="button" class="uk-button uk-margin-top-small add-sentence"
                                            @click.prevent="sendSentence(item)">{{ item }}</button>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>



        </div>
        <ul>

        </ul>
    </div>


    <div id="modal-user-customers" uk-modal>
        <div class="uk-modal-dialog uk-modal-body">
            <h2 class="uk-modal-title">Clienti associati all'utenza</h2>
            <div class="customers-list">
                <table class="uk-table uk-table-striped">
                    <thead>
                        <tr>
                            <th>Tipo fornitura</th>
                            <th>Codice cliente</th>
                            <th>
                                Nome cliente
                            </th>
                            <th>Cod.fisc./P.iva</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in UserData['customers']" :key="item['vendorCustomerId']">
                            <td class="uk-width-auto">{{ item["supplyTypeCode"] }}</td>
                            <td class="uk-width-auto">{{ item["customerCode"] }}</td>
                            <td class="uk-width-expand">{{ item["customerName"] }}</td>
                            <td class="uk-width-auto">{{ item["vatFiscalCode"] }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <p class="uk-text-right">
                <button class="uk-button uk-button-default uk-modal-close" type="button">Chiudi</button>
            </p>
        </div>
    </div>

    <div id="modal-chat-list" class="uk-modal-full" style="background-color: white;" uk-modal="container:false">
      <div class="uk-modal-dialog uk-height-1-1">
        <button class="uk-modal-close-full uk-close-large" type="button" uk-close></button>
        <div class="uk-modal-header">
          <h2 class="uk-modal-title">Lista chat intercorse</h2>
        </div>
        <div class="uk-modal-body chat-list-body">
          <div class="chat-list-container" style="overflow-y:scroll">
            <table class="uk-table uk-table-striped">
              <thead>
                <th>Operatore<br>Stato ticket</th>
                <th>Email<br>Stato registrazione</th>
                <th>Nome utente<br>Codice cliente</th>
                <th></th>
              </thead>
              <tbody>
                <tr v-for="item, index in ChatLog" :key="item['id']">
                  <td>
                    {{ item['agentName'] }}
                    <br>
                    <span v-if="item['ticketIsClosed'] == true">Ticket chiuso</span>
                    <span v-if="item['ticketIsClosed'] != true">Ticket aperto</span>
                    <br>
                    {{ item['chatStartedAtFormatted'] }}
                  </td>
                  <td>
                    {{ item['email'] }}
                    <br>
                    <span v-if="item['userId'] != ''">Utente registrato</span>
                    <span v-if="item['userId'] == ''">Utente prospect o non verificato</span>
                  </td>
                  <td>
                    {{ item['customerName'] }}
                    <br>
                    {{ item['customerCode'] }}
                  </td>
                  <td>
                    <button class="uk-button uk-buttom-primary" @click.prevent="showChatThread(index)">Vedi chat</button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div uk-modal="stack: true, container:false" id="modal-chat-detail">
      <div class="uk-modal-dialog uk-margin-auto-vertical">
        <button class="uk-modal-close-default uk-close-large" type="button" uk-close></button>
        <div class="chatbox uk-container-small uk-padding-small">
          <div class="thread chat-selected-thread uk-width-1-1">
            <div class="uk-clearfix" v-for="item in ChatSelectedThread" :key="item">
              <div :class="item['side'] == 'L' ? 'uk-align-left' : 'uk-align-right uk-margin-right'">
                <div class="uk-text-meta">{{ item['atFormatted'] }}
                </div>
                <div class="log ">{{
                  item['msg'] }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<style scoped>
.chatbox {
    background-color: #eee;
}

.log {
    padding: 8px !important;
    color: white;
    max-width: 85%;
}

.chat-list-body {
  height: 85%;
}

.chat-list-container {
  overflow-y: scroll;
  /* Firefox */
  height: -moz-calc(100% - 100px);
  /* WebKit */
  height: -webkit-calc(100% - 100px);
  /* Opera */
  height: -o-calc(100% - 100px);
  /* Standard */
  height: calc(100% - 100px);
}

.chat-selected-thread {
  margin-top: 50px;
}

.uk-align-right>.log {
    background-color: rgb(38, 86, 114) !important;
    border-radius: 5px 5px 0 5px !important;
}

.uk-align-left>.log {
    background-color: rgb(241, 161, 99) !important;
    border-radius: 5px 5px 5px 0 !important;
}

.send-button {
    margin-top: 4px;
    cursor: pointer;
}

.thread {
    border-bottom: solid 1px #ccc;
    margin-bottom: 10px;
    height: 400px;
    max-height: 400px;
    overflow-y: scroll;

}

.add-sentence {
    max-width: 90% !important;
    font-size: 0.8rem !important;
    line-height: 1.2rem !important;
    padding: 5px !important;
    padding-right: 10px !important;
}

.trash {
    margin-top: 8px;
}

.td-trash {
    min-width: 18px;
}

.customers-list {
    max-height: 300px;
    overflow-y: scroll;
}
</style>
    

<script>

import { useSignalR } from '@dreamonkey/vue-signalr';
const axios = require("axios").default; // eslint-disable-line no-unused-vars
import UIkit from 'uikit' // eslint-disable-line no-unused-vars
var signalr;

export default {
    name: "Agent",
    watch: {
        $route(to, from) { // eslint-disable-line no-unused-vars
            this.stopAgent()
        }
    },
    computed: {
        userId() {
            return this.$store.state.userId;
        },
        token() {
            return this.$store.state.token;
        },
    },
    setup() {
        signalr = useSignalR();
    },
    data() {
        return {
            ReservedAreaHost: process.env.VUE_APP_RESERVED_AREA_HOST,
            RecurringSentences: [],
            NewRecurringSentence: "",
            ConnectionId: '',
            Connected: false,
            Send: {
                Message: ''
            },
            Connect: {
                Name: this.$store.state.name,
                WelcomeMessage: "Buongiorno, sono [NAME] di Luce e Gas Italia, come posso esserLe d'aiuto?"
            },
            Logs: [],
            QueueTotal: 0,
            ClientName: "",
            ClientEmail: "",
            ClientCustomerCode: "",
            UserDataAvailable: false,
            UserData: {},
            ConnectedWithCounterPart: false,
            Started: false,
            ConversationStoppedByCounterPart: false,
            ChatLog: [],
            ChatSelectedThread: []
        };
    },

    methods: {
        errorNotification(msg) {
            UIkit.notification({// eslint-disable-line no-undef
                message: msg == null ? "Si è verificato un errore." : msg,
                status: "danger",
                pos: "top-center",
                timeout: 4000,
            });
        },
        successNotification(msg) {
            UIkit.notification({// eslint-disable-line no-undef
                message: msg == null ? "Operazione eseguita con successo." : msg,
                status: "success",
                pos: "top-center",
                timeout: 4000,
            });
        },
        userContractGetChats(paramType, paramValue) {
      axios({
        method: "get",
        url: process.env.VUE_APP_APIHOST + "/api/v1/chats",
        params: {
          UserId: paramType == 'userId' ? paramValue : '',
          CustomerCode: paramType == 'customerCode' ? paramValue : '',
          Email: paramType == 'email' ? paramValue : ''
        },
        headers: {
          Token: this.token
        }
      })
        .then(
          function (response) {// eslint-disable-line no-unused-vars
            if (response.data.length == 0) {
              this.successNotification('Nessuna chat intercorsa.')
            }
            else {
              this.ChatLog = response.data
              UIkit.modal('#modal-chat-list').show()
            }
          }.bind(this)
        )
        .catch(function (error) {// eslint-disable-line no-unused-vars
          this.errorNotification();
        });
    },

    showChatThread(index) {
      this.ChatSelectedThread = this.ChatLog[index]['thread']
      UIkit.modal('#modal-chat-detail').show()
    },
        startConnection() {

            signalr.on('ConnID',
                (connId) => {
                    this.connIDReceived(connId)
                }
            );
            signalr.on('MessageReceived',
                (message) => {
                    this.messageReceived(message)
                }
            );
            signalr.on('MessageSent',
                (message) => {
                    this.messageSent(message)
                }
            );
            signalr.on('QueueTotal',
                (message) => {
                    this.QueueTotal = message
                }
            );
            signalr.on('ConnectedWithCounterPart',
                (message) => {
                    this.ConnectedWithCounterPart = message
                }
            );
            signalr.on('Started',
                (message) => {
                    this.Started = message
                }
            );
            signalr.on('ConversationStoppedByCounterPart',
                () => {
                    this.ConversationStoppedByCounterPart = true
                    this.Logs.push({ side: "L", message: "La connessione con l'utente è terminata.", time: new Date() });
                    this.$nextTick(() => {
                        if (this.$refs != null && this.$refs.thread != null && this.$refs.thread.scrollTop != null && this.$refs.thread.scrollHeight != null) {
                            this.$refs.thread.scrollTop = this.$refs.thread.scrollHeight
                        }
                    });
                }
            );
            signalr.on('ClientName',
                (message) => {
                    this.ClientName = message
                }
            );
            signalr.on('ClientEmail',
                (message) => {
                    this.ClientEmail = message
                    this.getUserData()
                }
            );
            signalr.on('ClientCustomerCode',
                (message) => {
                    this.ClientCustomerCode = message
                    this.getUserData()
                }
            );



        },
        connIDReceived(connId) {
            this.ConnectionId = connId;
            this.Connected = true;
        },
        sendSentence(item) {
            this.Send.Message = item;
            this.sendMessage()
        },
        sendMessage() {
            if (this.Send.Message.trim() == '') {
                return false
            }
            signalr.invoke('SendMessage', {
                MessageType: 'agent_text',
                Message: this.Send.Message
            }
            );
            this.Logs.push({ side: "R", message: this.Send.Message, time: new Date() });
            this.$nextTick(() => {
                if (this.$refs != null && this.$refs.thread != null && this.$refs.thread.scrollTop != null && this.$refs.thread.scrollHeight != null) {
                    this.$refs.thread.scrollTop = this.$refs.thread.scrollHeight
                }
            });
            this.Send.Message = ""
        },
        startAgent() {
            signalr.invoke('StartAgent', {
                Name: this.Connect.Name,
                WelcomeMessage: this.Connect.WelcomeMessage
            });
        },
        stopAgent() {
            this.stopConversation(false, false);
            signalr.invoke('StopAgent');
            
        },
        startConversation() {
            this.ClientName = ""
            this.ClientEmail = ""
            this.ClientCustomerCode = ""
            this.UserDataAvailable = false
            this.UserData = {}
            signalr.invoke('StartConversation');
            this.Logs = [];
        },
        stopConversation(send, ticketIsClosed) {
            signalr.invoke('StopConversation');
            this.ConnectedWithCounterPart = false
            // axios '/api/v1/chat-thread'
            if (!send) {
                return false
            }
            axios({
                method: "post",
                url: process.env.VUE_APP_APIHOST + '/api/v1/chat-thread',
                params: {
                    email: this.ClientEmail,
                    customerName: this.UserDataAvailable ? this.UserData['name'] + ' ' + this.UserData['surname'] : this.ClientName,
                    customerCode: this.ClientCustomerCode,
                    userId: this.UserDataAvailable ?
                        this.UserData['id'] : "",
                    agentName: this.Connect.Name,
                    ticketIsClosed: ticketIsClosed
                },
                data: this.Logs,
                headers: {
                    'Token': this.token
                }
            })
                .then(
                    function (response) {// eslint-disable-line no-unused-vars

                    }.bind(this)
                )
                .catch(function (error) {// eslint-disable-line no-unused-vars
                    this.errorNotification();
                }.bind(this));
        },
        messageReceived(message) {
            this.Logs.push({ side: "L", message: message, time: new Date() });
            this.$nextTick(() => {
                if (this.$refs != null && this.$refs.thread != null && this.$refs.thread.scrollTop != null && this.$refs.thread.scrollHeight != null) {
                    this.$refs.thread.scrollTop = this.$refs.thread.scrollHeight
                }
            });
        },
        messageSent(message) {
            this.Logs.push({ side: "R", message: message, time: new Date() });
            this.$nextTick(() => {
                if (this.$refs != null && this.$refs.thread != null && this.$refs.thread.scrollTop != null && this.$refs.thread.scrollHeight != null) {
                    this.$refs.thread.scrollTop = this.$refs.thread.scrollHeight
                }
            });
        },
        getRecurringSentences() {
            axios({
                method: "get",
                url: process.env.VUE_APP_APIHOST + "/api/v1/chat-recurring-sentences",
                headers: {
                    Token: this.token
                },
                params: { userId: this.userId }
            })
                .then(
                    function (response) {// eslint-disable-line no-unused-vars
                        this.RecurringSentences = response.data
                    }.bind(this)
                )
                .catch(function (error) {// eslint-disable-line no-unused-vars
                    this.errorNotification();
                }.bind(this));
        },
        addRecurringSentence() {
            if (this.NewRecurringSentence.trim() == "") {
                return false
            }
            if (this.RecurringSentences.includes(this.NewRecurringSentence.trim())) {
                this.errorNotification("Questo messaggio esiste già.");
                return false
            }
            this.RecurringSentences.push(this.NewRecurringSentence)
            this.patchRecurringSentences()
        },
        removeSentence(index) {
            this.RecurringSentences.splice(index, 1)
            this.patchRecurringSentences()
        },
        patchRecurringSentences() {
            axios({
                method: "patch",
                url: process.env.VUE_APP_APIHOST + "/api/v1/chat-recurring-sentences",
                headers: {
                    Token: this.token
                },
                params: { userId: this.userId },
                data: {
                    RecurringSentences: this.RecurringSentences
                }
            })
                .then(
                    function (response) {// eslint-disable-line no-unused-vars
                        if (response.data) {
                            this.NewRecurringSentence = ""
                        }
                    }.bind(this)
                )
                .catch(function (error) {// eslint-disable-line no-unused-vars
                    this.errorNotification();
                }.bind(this));
        },
        getUserData() {
            if (this.ClientEmail == "" || this.ClientCustomerCode == "") {
                return false
            }
            axios({
                method: "get",
                url: process.env.VUE_APP_APIHOST + "/api/v1/chat-apiuser",
                headers: {
                    Token: this.token
                },
                params: { email: this.ClientEmail, customerCode: this.ClientCustomerCode }
            })
                .then(
                    function (response) {// eslint-disable-line no-unused-vars
                        if (response.data != false) {
                            this.UserDataAvailable = true
                            this.UserData = response.data
                            console.log("UserData", this.UserData)
                        } else {
                            this.UserDataAvailable = false
                            this.UserData = {}
                        }

                    }.bind(this)
                )
                .catch(function (error) {// eslint-disable-line no-unused-vars

                }.bind(this));
        },
        userSSO() {
            axios({
                method: "get",
                url: process.env.VUE_APP_APIHOST + "/api/v1/chat-apiuser-sso",
                params: { email: this.ClientEmail, customerCode: this.ClientCustomerCode },
                headers: {
                    Token: this.token
                }
            })
                .then(
                    function (response) {// eslint-disable-line no-unused-vars
                        if (response.data != "") {
                            console.log(response.data)
                            window.open(this.ReservedAreaHost.replace("[SSO]", response.data), "_blank_sso")
                        } else {
                            this.errorNotification();
                        }
                    }.bind(this)
                )
                .catch(function (error) {// eslint-disable-line no-unused-vars
                    this.errorNotification();
                });
        },

    },
    mounted() {
        this.startConnection()
        this.getRecurringSentences()
    }
}
</script>


<template>
  <section>
    <div class="uk-grid">
      <div class="uk-width-large uk-align-center">
        <form v-on:submit.prevent="changeEmailVerification()">
          <div class="uk-card uk-card-default uk-card-body">
            <div class="uk-card-title">
              <h3>Modifica Email</h3>
            </div>
            <div class="uk-form">
              <p>Inserisci la tua nuova email e premi il bottone <b>Procedi</b>.
              <br>
              Segui successivamente le istruzioni contenute nel messaggio che riceverai al nuovo indirizzo impostato.
              </p>
              <div class="uk-form-row uk-margin-top">
                <input
                  class="uk-input"
                  placeholder="Nuova Email"
                  v-model="changeEmail"
                  v-on:keyup="changeEmailValidate()"
                />
              </div>
              <div class="uk-form-row uk-margin-top">
                <button
                  class="uk-button uk-button-primary"
                  type="submit"
                  v-bind:disabled="!changeEmailValidate()"
                >
                  Procedi
                </button>
              </div>
            </div>
           
          </div>
        </form>
      </div>
    </div>
  </section>
</template>

<script>
import commonFunctions from "../../functions/common.js"; // eslint-disable-line no-unused-vars
import UIkit from 'uikit'
const axios = require("axios").default;
export default {
  name: "ChangeEmail",
  computed: {
    token() {
      return this.$store.state.token;
    },
    isLoggedIn() {
      return this.$store.state.isLoggedIn;
    },
  },
  data() {
    return {
      changeEmail: "",
      recaptchaToken: "",
      APIHost: process.env.VUE_APP_APIHOST
    };
  },
  methods: {
    changeEmailValidate() {
      if (
        commonFunctions.isNullOrWhiteSpace(this.changeEmail) ||
        !commonFunctions.isEmail(this.changeEmail) 
      ) {
        return false;
      } else {
        return true;
      }
    },
    changeEmailVerification(){
      axios({
        method: "patch",
        url: this.APIHost + "/api/v1/user-change-email-verification",
        data: {
          NewEmail: this.changeEmail,
          Language: "IT"
        },
        headers: {
          Token: this.token
        }
      })
        .then(
          function (response) {// eslint-disable-line no-unused-vars
            if (response.data && response.data.result && response.data.result == "ok") {
              UIkit.notification({// eslint-disable-line no-undef
                message: "Messaggio inviato. Controlla la tua nuova casella di posta e segui le istruzioni per completare la procedura di modifica.",
                status: "success",
                pos: "top-center",
                timeout: 7000,
              });
            }else{
              UIkit.notification({// eslint-disable-line no-undef
                message: "Si è verificato un errore.",
                status: "danger",
                pos: "top-center",
                timeout: 4000,
              });
            }
          }.bind(this)
        )
        .catch(function (error) {// eslint-disable-line no-unused-vars
          // handle error
          UIkit.notification({// eslint-disable-line no-undef
            message: "Si è verificato un errore.",
            status: "danger",
            pos: "top-center",
            timeout: 4000,
          });
        });
    },
    async recaptcha() {
      // (optional) Wait until recaptcha has been loaded.
      await this.$recaptchaLoaded();
      this.recaptchaToken = await this.$recaptcha("login");
    },
    
  },
};
</script>



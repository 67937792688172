<template>
  <div class="uk-inline uk-align-right uk-margin-remove-bottom" v-if="isLoggedIn">
    <button class="uk-button uk-button-text" type="button">
      <span uk-icon="menu"></span>
    </button>
    <div ref="dropdownmenu" 
    uk-dropdown="mode: click; animation: uk-animation-slide-top-small; duration: 1000; pos: bottom-right"
    style="max-height: 480px; overflow-y: scroll;"
    >
      <div class="uk-nav uk-dropdown-nav">
        <div class="uk-padding uk-width-large">
          <ul>
            <li class="uk-nav-header">Dashboard</li>
            <li><router-link  @click.prevent="toggleMenu" to="/">Dashboard</router-link></li>
            <li v-if="isAdmin || manageUsers.canManage" class="uk-nav-divider"></li>

            <li v-if="isAdmin || manageUsers.canManage" class="uk-nav-header">
              <ul uk-accordion>
                <li>
                  <p class="uk-accordion-title">Operatori backoffice</p>
                  <div class="uk-accordion-content">
                    <ul>
                      <li v-if="isAdmin || manageUsers.canManage">
                        <router-link  @click.prevent="toggleMenu" to="/bo-users/new-user">Nuovo operatore</router-link>
                      </li>
                      <li v-if="isAdmin || manageUsers.canManage">
                        <router-link  @click.prevent="toggleMenu" to="/bo-users/list">Lista operatori</router-link>
                      </li>
                      <li class="uk-nav-divider"></li>
                    </ul>
                  </div>
                </li>
              </ul>
            </li>

            <li v-if="isAdmin || apiUsers.canManage || apiUsersAgent.canManage"
              class="uk-nav-header uk-margin-remove-top">Utenti aree clienti</li>
            <li v-if="isAdmin || apiUsers.canManage || apiUsersAgent.canManage">
              <router-link  @click.prevent="toggleMenu" to="/app-users">{{ apiUsers.label }}</router-link>
            </li>

            <li v-if="isAdmin || selfReadings.canManage" class="uk-nav-divider"></li>
            <li v-if="isAdmin || selfReadings.canManage" class="uk-nav-header">Autoletture</li>
            <li v-if="isAdmin || selfReadings.canManage || selfReadingsAdmin.canManage">
              <router-link  @click.prevent="toggleMenu" to="/self-readings">Gestione autoletture</router-link>
            </li>

            <li v-if="isAdmin || billbooks.canManage" class="uk-nav-divider"></li>
            <li v-if="isAdmin || billbooks.canManage" class="uk-nav-header">
              <ul uk-accordion>
                <li>
                  <p class="uk-accordion-title">Fatture e pagamenti</p>
                  <div class="uk-accordion-content">
                    <ul>
                      <li v-if="isAdmin || billbooks.canManage">
                        <router-link  @click.prevent="toggleMenu" to="/billbooks-insert">Carica scadenzario</router-link>
                      </li>
                      <li v-if="isAdmin || billbooks.canManage">
                        <router-link  @click.prevent="toggleMenu" to="/billbooks-list">Vedi stati pagamento</router-link>
                      </li>
                      <li v-if="isAdmin || billbooks.canManage">
                        <router-link  @click.prevent="toggleMenu" to="/billbooks-transform">Trasforma formato scadenzario</router-link>
                      </li>
                      <li v-if="isAdmin || billbooks.canManage">
                        <router-link  @click.prevent="toggleMenu" to="/nexi-report-list">Report pagamenti Nexi</router-link>
                      </li>                      
                    </ul>
                  </div>
                </li>
              </ul>
            </li>

            <li v-if="isAdmin || notifications.canManage" class="uk-nav-divider"></li>
            <li v-if="isAdmin || notifications.canManage" class="uk-nav-header">
              <ul uk-accordion>
                <li>
                  <p class="uk-accordion-title">Notifiche</p>
                  <div class="uk-accordion-content">
                    <ul>
                      <li v-if="isAdmin || notifications.canManage">
                        <router-link  @click.prevent="toggleMenu" to="/notifications-list">Gestione notifiche</router-link>
                      </li>
                      <li v-if="isAdmin || notifications.canManage">
                        <router-link  @click.prevent="toggleMenu" to="/custom-notifications-list">Notifiche custom</router-link>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </li>

            <li v-if="isAdmin || synopticSheetUpload.canManage || synopticSheetGet.canManage" class="uk-nav-divider">
            </li>
            <li v-if="isAdmin || synopticSheetUpload.canManage || synopticSheetGet.canManage" class="uk-nav-header">
              <ul uk-accordion>
                <li>
                  <p class="uk-accordion-title">Sinottico</p>
                  <div class="uk-accordion-content">
                    <ul>
                      <li v-if="isAdmin || synopticSheetGet.canManage">
                        <router-link  @click.prevent="toggleMenu" to="/synoptic-sheet/get">Consulta sinottico</router-link>
                      </li>
                      <li v-if="isAdmin || synopticSheetUpload.canManage">
                        <router-link  @click.prevent="toggleMenu" to="/synoptic-sheet/upload">Carica excel sinottico</router-link>
                      </li>
                      <li v-if="isAdmin || synopticSheetUpload.canManage">
                        <router-link  @click.prevent="toggleMenu" to="/distributors-pdr-sheet/upload">Carica excel distributori PDR</router-link>
                      </li>
                      <li v-if="isAdmin || synopticSheetUpload.canManage">
                        <router-link  @click.prevent="toggleMenu" to="/seller-contracts-sheet/upload/1">Carica excel contratti venditori (step
                          1)</router-link>
                      </li>
                      <li v-if="isAdmin || synopticSheetUpload.canManage">
                        <router-link  @click.prevent="toggleMenu" to="/seller-contracts-sheet/upload/2">Carica excel contratti venditori (step
                          2)</router-link>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </li>


            <li v-if="isAdmin || chatUsers.canManage" class="uk-nav-header uk-margin-remove-top">
              Chat</li>
            <li v-if="isAdmin || chatUsers.canManage">
              <router-link  @click.prevent="toggleMenu" to="/chat/enter">Entra in chat</router-link>
            </li>


            <li v-if="isAdmin || phoneExchangeUsers.canManage" class="uk-nav-header">
              <ul uk-accordion>
                <li>
                  <p class="uk-accordion-title">Centralino</p>
                  <div class="uk-accordion-content">
                    <ul>
                      <li v-if="isAdmin || phoneExchangeUsers.canManage">
                        <router-link  @click.prevent="toggleMenu" to="/phone-exchange/apikey">Gestione apikey</router-link>
                      </li>
                      <li v-if="isAdmin || phoneExchangeUsers.canManage">
                        <router-link  @click.prevent="toggleMenu" to="/phone-exchange/export-customer-satisfaction">Esporta dati customer
                          satisfaction</router-link>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </li>


            <li v-if="isAdmin || smsGateway.canManage" class="uk-nav-header">
              <ul uk-accordion>
                <li>
                  <p class="uk-accordion-title">SMS Gateway</p>
                  <div class="uk-accordion-content">
                    <ul>
                      <li v-if="isAdmin || phoneExchangeUsers.canManage">
                        <router-link  @click.prevent="toggleMenu" to="/sms-gateway/list">Report Invii SMS</router-link>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </li>



          </ul>
        </div>

      </div>
    </div>
  </div>
</template>
<style scoped>
.uk-accordion-title {
  font-size: unset !important;
}

.uk-nav-header .uk-accordion-content {
  text-transform: none !important;
}
</style>

<script type="typescript">
export default {
  name: "HeaderMenu",
  computed: {
    isLoggedIn() {
      return this.$store.state.isLoggedIn;
    },
    isAdmin() {
      return this.$store.state.admin;
    },
    grants() {
      return this.$store.state.grants;
    },
    manageUsers() {
      return this.getGrants("manageusers")
    },
    selfReadings() {
      return this.getGrants("selfreadings")
    },
    selfReadingsAdmin() {
      return this.getGrants("selfreadingsadmin")
    },
    billbooks() {
      return this.getGrants("billbooks")
    },
    synopticSheetUpload() {
      return this.getGrants("synoptic-sheet-upload")
    },
    synopticSheetGet() {
      return this.getGrants("synoptic-sheet-get")
    },
    notifications() {
      return this.getGrants("notifications")
    },
    apiUsers() {
      return this.getGrants("apiusers")
    },
    apiUsersAgent() {
      return this.getGrants("apiusersagent")
    },
    chatUsers() {
      return this.getGrants("chat")
    },
    phoneExchangeUsers() {
      return this.getGrants("phoneexchange")
    },
    smsGateway(){
      return this.getGrants("smsgateway")
    },
  },
  methods: {
    getGrants(k) {
      var r = {
        canManage: false,
        label: ""
      }
      for (var x = 0; x < this.grants.length; x++) {
        if (this.grants[x].ModuleKeyID == k) {
          r.canManage = this.grants[x].CanManage;
          r.label = this.grants[x].ModuleLabel;
        }
      }
      return r;
    },
    toggleMenu(){
       let dropDownMenu = this.$refs.dropdownmenu
       dropDownMenu.setAttribute('uk-toggle', 'true')
    }
  },
  
};
</script>

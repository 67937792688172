<template>

  <section>
    <p class="uk-text-lead">Gestione permessi</p>
    <p class="uk-text-lead">{{ ModuleGrants.Name }} {{ ModuleGrants.Surname }} - {{ ModuleGrants.Email }}</p>
    <p uk-margin>
      <button v-if="isAdmin || manageUsers.CanManage" class="uk-button uk-button-default"
      @click="$router.go(-1)"
        ><span uk-icon="arrow-left" class="uk-margin-right"></span>Torna alla lista
        operatori</button>
    </p>
    <div class="uk-container">
      <table class="uk-table uk-table-striped">
        <thead>
          <tr>
            <th style="width:36%">Modulo</th>
            <th style="width:16%" class="uk-text-center">Attivo
            <br>
            <input class="uk-checkbox" type="checkbox"
                v-model="ModuleGrants.AllCanManage" @change="userUpdateAllGrants()">
          </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="M in ModuleGrants.List" :key="M.ModuleKeyID">
            <td style="width:36%">{{ M.ModuleName }}
              <div v-if="M.ModuleKeyID=='apiusersagent' && M.CanManage">
              <input maxlength="400" class="uk-input" placeholder="Cognome + nome agente" v-model="M.AgentName" @change="userUpdateGrants"/> 
              </div>
            </td>
            <td style="width:16%" class="uk-text-center"><input class="uk-checkbox" type="checkbox"
                v-model="M.CanManage" @change="userUpdateGrants">             
              </td>

          </tr>
        </tbody>
      </table>
    </div>

  </section>

</template>

<style scoped>

</style>

<script>
const axios = require("axios").default;
import commonFunctions from "../../functions/common.js"; // eslint-disable-line no-unused-vars
import UIkit from 'uikit'
export default {
  name: "ManageUsers_User_Grants",
  computed: {
    token() {
      return this.$store.state.token;
    },
    isAdmin() {
      return this.$store.state.admin;
    },
    grants() {
      return this.$store.state.grants;
    },
    
    manageUsers() {
      return this.getGrants("manageusers")
    }
  },

  data() {
    return {
      APIHost: process.env.VUE_APP_APIHOST,
      ModuleGrants: {
        Email: "",
        Name: "",
        Surname: "",
        List: [],
      },
      Users: {
        List: [],
        Pages: 0,
        CurrentPage: 0,
        Loaded: false
      }
    };
  },
  methods: {
    getGrants(k) {
            var r = {
                canManage: false,
                label: ""
            }
            for (var x = 0; x < this.grants.length; x++) {
                if (this.grants[x].ModuleKeyID == k) {
                    r.canManage = this.grants[x].CanManage;
                    r.label = this.grants[x].ModuleLabel;
                }
            }
            return r;
        },

    userShowGrants() {
      console.log("state", this.$store.state)
      this.ModuleGrants.List = [];
      this.ModuleGrants.Email = this.$store.state.userDialogInfoEmail;
      this.ModuleGrants.Name = this.$store.state.userDialogInfoName;
      this.ModuleGrants.Surname = this.$store.state.userDialogInfoSurname;
      this.ModuleGrants.AllCanManage = false;
      
      axios({
        method: "get",
        url: this.APIHost + "/api/v1/users-grants",
        params: {
          id: this.$route.params.userid
        },
        headers: {
          Token: this.token
        }
      })
        .then(
          function (response) {// eslint-disable-line no-unused-vars
            this.ModuleGrants.List = [];
            // this.ModuleGrants.Email = this.Users.List[index].Email;
            // this.ModuleGrants.Name = this.Users.List[index].Name;
            // this.ModuleGrants.Surname = this.Users.List[index].Surname;
            var x;
            for (x = 0; x < response.data.length; x++) {
              this.ModuleGrants.List.push(response.data[x]);
            }
            
            this.setAllCanManageButtons();
          }.bind(this)
        )
        .catch(function (error) {
          // handle error
          console.log(error);
          UIkit.notification({// eslint-disable-line no-undef
            message: "Si è verificato un errore.",
            status: "danger",
            pos: "top-center",
            timeout: 4000,
          });
        });
    },
    setAllCanManageButtons() {
      var x;
      var allCanManage = true;
      for (x = 0; x < this.ModuleGrants.List.length; x++) {
        if (allCanManage && !this.ModuleGrants.List[x].CanManage) { allCanManage = false }
      }
      this.ModuleGrants.AllCanManage = allCanManage;
    },

    userUpdateAllGrants() {
      var x = 0;
      for (x = 0; x < this.ModuleGrants.List.length; x++) {
        this.ModuleGrants.List[x].CanManage = this.ModuleGrants.AllCanManage;
      }
      this.userUpdateGrants();
    },
    userUpdateGrants() {

      axios({
        method: "patch",
        url: this.APIHost + "/api/v1/user-grants-update",
        data: {
          UserEmail: this.ModuleGrants.Email,
          Grants: JSON.stringify(this.ModuleGrants.List)
        },
        headers: {
          Token: this.token
        }
      })
        .then(
          function (response) {// eslint-disable-line no-unused-vars
            this.setAllCanManageButtons();
          }.bind(this)
        )
        .catch(function (error) {
          // handle error
          console.log(error);
          UIkit.notification({// eslint-disable-line no-undef
            message: "Si è verificato un errore.",
            status: "danger",
            pos: "top-center",
            timeout: 4000,
          });
        });
    }
  },
  beforeUpdated() {
    
  },
  created() {
    
  },
  mounted() {
    this.userShowGrants()
  },

};
</script>

<template>
    <div>

        <div class="uk-card uk-card-body uk-card-default uk-width-1-1">
            <h4>Report SMS</h4>
            <table class="uk-table uk-width-1-2@s">
                <tr>
                    <td>
                        Data inizio:
                    </td>
                    <td>
                        <datepicker v-model="dateFrom" :enable-time-picker="false" format="dd/MM/yyyy"
                            cancel-text="Annulla" select-text="OK" />
                    </td>
                </tr>
                <tr>
                    <td>
                        Data fine:
                    </td>
                    <td>
                        <datepicker v-model="dateTo" :enable-time-picker="false" format="dd/MM/yyyy"
                            cancel-text="Annulla" select-text="OK" />
                    </td>
                </tr>
            </table>
            <div class="uk-margin-top">
                <button class="uk-button uk-button-primary" @click.prevent="submit(1)">Filtra</button>
            </div>
        </div>

    </div>


    <section class="uk-margin-top uk-margin-bottom" v-if="Results.List.length > 0">
        <div>
            <p class="uk-text-lead" v-if="Results.List.length > 0">Log richieste</p>

            <table class="uk-table uk-table-striped">
                <thead>
                    <tr>
                        <th>
                            Telefono
                        </th>
                        <th>
                            Link
                        </th>
                        <th>Data</th>
                        <th>
                            Nome
                        </th>
                        <th>Indirizzo IP</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item in Results.List" :key="item.ID">
                        <td>
                            {{ item.PhoneNumber }}
                        </td>
                        <td>
                            {{ item.Link }}                            
                        </td>
                        <td>
                            {{ item.FormattedRequestDate }}
                        </td>
                        <td>
                            {{ item.ClientName }}
                        </td>
                        <td>
                            {{ item.RequestIP }}
                        </td>
                    </tr>
                </tbody>
            </table>
            <div>
                <a title="Prima pagina" v-if="Results.CurrentPage > 1" @click.prevent="submit(1)"
                    uk-icon="chevron-double-left" class="uk-margin-small-right pagination-link"></a>
                <a title="Pagina precedente" v-if="Results.CurrentPage > 2"
                    @click.prevent="submit(Results.CurrentPage - 1)" uk-icon="chevron-left"
                    class="uk-margin-small-right pagination-link"></a>
                <a title="Pagina successiva" v-if="Results.CurrentPage < Results.Pages - 1"
                    @click.prevent="submit(parseInt(Results.CurrentPage) + 1)" uk-icon="chevron-right"
                    class="uk-margin-small-right pagination-link"></a>
                <a title="Ultima pagina" v-if="Results.CurrentPage < Results.Pages"
                    @click.prevent="submit(Results.Pages)" uk-icon="chevron-double-right"
                    class="uk-margin-small-right pagination-link"></a>
                <label class="uk-text">Pagina {{ Results.CurrentPage }} di {{ Results.Pages }}</label>
            </div>
        </div>
    </section>

    <section class="uk-margin-top uk-margin-bottom" v-if="this.Results.List.length == 0">
        Nessuna richiesta presente.
    </section>
</template>
<style scoped></style>
<script>
const axios = require("axios").default; // eslint-disable-line no-unused-vars
import commonFunctions from "../../functions/common.js"; // eslint-disable-line no-unused-vars
import UIkit from 'uikit' // eslint-disable-line no-unused-vars
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
export default {
    name: "SMSGateway_List",
    components: {
        Datepicker
    },
    computed: {
        token() {
            return this.$store.state.token;
        },
        email() {
            return this.$store.state.email
        }
    },
    data() {
        return {

            dateFrom: null,
            dateTo: null,
            ResultsPerPage: 25,
            Results: {
                CurrentPage: 0,
                Pages: 0,
                List: [],
                Loaded: false
            }
        }

    },
    methods: {
        errorNotification(msg) {
            UIkit.notification({// eslint-disable-line no-undef
                message: msg == null ? "Si è verificato un errore." : msg,
                status: "danger",
                pos: "top-center",
                timeout: 4000,
            });
        },
        successNotification(msg) {
            UIkit.notification({// eslint-disable-line no-undef
                message: msg == null ? "Operazione eseguita con successo." : msg,
                status: "success",
                pos: "top-center",
                timeout: 4000,
            });
        },
        submit(pageId) {

            axios({
                method: "get",
                url: process.env.VUE_APP_APIHOST + "/api/v1/sms-gateway-list",
                params: {
                    pageNumber: pageId,
                    dateFrom: this.dateFrom,
                    dateTo: this.dateTo
                },
                headers: {
                    Token: this.token
                }
            })
                .then(
                    function (response) {// eslint-disable-line no-unused-vars
                        this.Results.List = [];
                        var x;
                        for (x = 0; x < response.data.length; x++) {
                            this.Results.List.push(response.data[x]);
                        }
                        this.Results.CurrentPage = pageId;
                        var pgs = 1;
                        if (this.Results.List.length > 0) {
                            pgs = Math.trunc(this.Results.List[0].Total / this.ResultsPerPage);
                            if (this.Results.List[0].Total % this.ResultsPerPage > 0) { pgs += 1; }
                            this.Results.Pages = pgs;
                        }
                    }.bind(this)
                )
                .catch(function (error) {
                    // handle error
                    console.log(error);
                    this.errorNotification()
                }.bind(this));

        }
    },
    mounted() {
        var d1 = new Date()
        var d2 = new Date()
        d1.setMonth(d1.getMonth() - 1);
        d1.setDate(1)

        this.dateFrom = d1.toLocaleDateString("en-US")
        this.dateTo = d2.toLocaleDateString("en-US")
        this.submit(1)
    },
    updated() {

    }
}
</script>

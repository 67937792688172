<template>
  <section>
    <div class="uk-grid">
      <div class="uk-width-large uk-align-center">
        <form v-on:submit.prevent="loginSubmit()">
          <div class="uk-card uk-card-default uk-card-body">
            <div class="uk-card-title">
              <h3>Login</h3>
            </div>
            <div class="uk-form">
              <div class="uk-form-row uk-margin-top">
                <input
                  class="uk-input"
                  placeholder="Email"
                  v-model="loginEmail"
                  v-on:keyup="loginValidate()"
                />
              </div>
              <div class="uk-form-row uk-margin-top">
                <input
                  class="uk-input"
                  placeholder="Password"
                  type="password"
                  v-model="loginPassword"
                  v-on:keyup="loginValidate()"
                />
              </div>
              <!-- <div class="uk-form-row uk-margin-top">
                <input
                  class="uk-input"
                  placeholder="Google Authenticator Code"
                  v-model="loginGoogleAuthCode"
                  v-on:keyup="loginValidate()"
                />
              </div> -->

              <div class="uk-form-row uk-margin-top">
                <button
                  class="uk-button uk-button-primary"
                  type="submit"
                  v-bind:disabled="!loginValidate()"
                >
                  Login
                </button>
              </div>
            </div>
            <hr />
            <router-link to="/change-password">Reset password</router-link>
          </div>
        </form>
      </div>
    </div>
  </section>
</template>

<script>
import commonFunctions from "../../functions/common.js"; // eslint-disable-line no-unused-vars
import UIkit from 'uikit'
const axios = require("axios").default;
export default {
  name: "Login",
  computed: {
    token() {
      return this.$store.state.token;
    },
    isLoggedIn() {
      return this.$store.state.isLoggedIn;
    },
  },
  data() {
    return {
      loginEmail: "",
      loginPassword: "",
      //loginGoogleAuthCode: "",
      recaptchaToken: "",
      APIHost: process.env.VUE_APP_APIHOST
    };
  },
  methods: {
    mounted() {
    console.log("mounted login")
    },
    updated() {
      console.log("updated login")
    },
    loginValidate() {
      if (
        commonFunctions.isNullOrWhiteSpace(this.loginEmail) ||
        commonFunctions.isNullOrWhiteSpace(this.loginPassword) ||
        //commonFunctions.isNullOrWhiteSpace(this.loginGoogleAuthCode) ||
        !commonFunctions.isEmail(this.loginEmail) ||
        !commonFunctions.isValidPassword(this.loginPassword)
      ) {
        return false;
      } else {
        return true;
      }
    },
    async recaptcha() {
      // (optional) Wait until recaptcha has been loaded.
      await this.$recaptchaLoaded();
      this.recaptchaToken = await this.$recaptcha("login");
    },
    async loginSubmit() {
      //console.log("submit");
      await this.recaptcha();
      axios({
        method: "post",
        //withCredentials: true,
        //headers: {"Access-Control-Allow-Origin": this.APIHost,
        //  "Access-Control-Allow-Credentials": "true"},    
        url: this.APIHost + "/api/v1/users-login",
        data: {
          Email: this.loginEmail,
          Password: this.loginPassword,
          Recaptcha: this.recaptchaToken,
          //GoogleCode: this.loginGoogleAuthCode
        },
      })
        .then(
          function (response) {
            // handle success
            //console.log('Status', response.data.Result.Grants)
            if (response.data.Status == 'ok'){
              this.$store.commit("setLoggedIn");
              this.$store.commit("changeToken", response.data.Result.Token);
              this.$store.commit("changeLoginData",{
                userId: response.data.Result.Id,
                email: this.loginEmail,
                name: response.data.Result.Name, 
                surname: response.data.Result.Surname, 
                previousLogin: response.data.Result.PreviousLogin,
                admin: response.data.Result.Admin,
                grants: response.data.Result.Grants
                });
                this.$router.push({ path: '/' });
            }
            if (response.data.Status == 'lockout'){
              UIkit.notification({// eslint-disable-line no-undef
                message:
                  "Raggiunto o superato il numero massimo di tentativi.<br />L'account è bloccato per 15 minuti.",
                status: "danger",
                pos: "top-center",
                timeout: 4000,
              });
            }
          }.bind(this)
        )
        .catch(function (error) {// eslint-disable-line no-unused-vars
          // handle error
          //console.log(error);
          UIkit.notification({// eslint-disable-line no-undef
            message:
              "Autenticazione non effettuata. <br />Verificare le credenziali inserite.",
            status: "danger",
            pos: "top-center",
            timeout: 4000,
          });
        })
        .then(function () {
          // always executed
        });
    },
  },
};
</script>


